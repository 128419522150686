import React, { useEffect, useState, memo } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import useTable from "../../components/useTable";
import { useLocation, useHistory } from "react-router-dom";
import OrderForm from "./OrderForm";
import Popup from "../../components/Popup";
import "../Customers/pages.css"
import {
  DeleteForever,
  Edit,
  Done,
  Search,
  ArrowDownward,
  ArrowUpward,
  Close,
  Delete,
} from "@material-ui/icons";
import {
  Paper,
  Toolbar,
  makeStyles,
  Grid,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import Notification from "../../components/Notification";
import "jspdf-autotable";
import $ from "jquery";
import misc from "../../fnc/misc";
import PageHeader from "../../components/PageHeader";
import { useTabIndex } from "react-tabindex";
import ConfirmDialog from "../../components/ConfirmDialog";

let myheight = window.innerHeight;
let myWidth = window.innerWidth;
const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: "1rem 1rem 1rem 1rem",
    height: `calc(100vh - 10rem)`,
    [theme.breakpoints.down("md")]: {
      height: `calc(100vh - 10rem)`,
    },
    [theme.breakpoints.down("sm")]: {
      height: `calc(100vh - 10rem)`,
    },
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - 14rem)`,
    },
    overflow: "hiddin",
    "& .MuiGrid-item > *": {
      maxWidth: `calc(100% - 1rem) !important`,
      width: `calc(100% - 1rem) !important`,
      marginRight: `1rem !important`,
    },
    "& .table-container": {
      height: `100%`,
      overflow: "auto",
      marginBottom: "1rem",
      paddingLeft: "0rem !important",
      //     "& thead":{
      //         [theme.breakpoints.down(900)]:{
      //         display:"table-header-group",
      //         backgroundColor:theme.palette.primary.main,
      //         width:"100%",
      //         "&  tr" :{
      //             display:"flex",
      //                 alignItems:"center",
      //                 justifyContent:"space-between",
      //                 padding: "0rem .2rem 0rem .2rem",
      //                 backgroundColor:theme.palette.primary.main,
      //             "& [name=name]":{
      //                 width:"25% !important"
      //             },
      //             "& [name=code]":{
      //                 width:"5% !important"
      //             },
      //             "& [name=qty]":{
      //                 width:"5% !important"
      //             },
      //             "& [name=amount]":{
      //                 width:"5% !important"
      //             },
      //           "& th":{
      //             //   width:"1fr",
      //             color: "White",
      //             padding: "0",
      //             backgroundColor:theme.palette.primary.main,
      //             textAlign: "left",
      //             fontSize:"1rem",

      //           }
      //           }
      //         }
      //     },
      //     "& tbody":{

      //         [theme.breakpoints.down(900)]:{
      //             "& tr":{
      //                 display:"flex",
      //                 alignItems:"center",
      //                 justifyContent:"space-between",
      //                 padding: "0rem .2rem 0rem .2rem",
      //                 "& [name=name]":{
      //                     width:"25% !important"
      //                 },
      //                 "& [name=code]":{
      //                     width:"5% !important"
      //                 },
      //                 "& [name=qty]":{
      //                     width:"5% !important"
      //                 },
      //                 "& [name=amount]":{
      //                     width:"5% !important"
      //                 },

      //             },
      //             "& td":{
      //                 display: "inline",
      //                 position: "static",
      //                 padding: "0",
      //                 textAlign: "left",
      //                 borderBottom: 0,
      //                 marginTop : "0rem",
      //                 "&::before":{
      //                     display:"none !important"
      //                 }
      //             },
      //         }

      //     },

      //     "& .code":{
      //         [theme.breakpoints.down('xs')]:{
      //             display:"none"
      //         }
      //     },
    },
    //     "& .MuiGrid-item > *":{

    //         maxWidth: `calc(100% - 1rem) !important`,
    //         width: `calc(100% - 1rem) !important`,
    //         marginRight: `1rem !important`,
    //         marginBottom:`1rem !important`
    //    },
  },

  OrdersFooter: {
    marginTop: "2rem",
    "& > * ": {
      marginBottom: ".5rem",
    },
  },

  topMenu: {
    "&:not(:last-child) ": {
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  topMenuMobile: {
    "& > * ": {
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  orderID: {
    paddingTop: ".9rem",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0rem",
      alignItems: "center",
    },
  },

  mobileAddBtn: {
    [theme.breakpoints.down(599)]: {
      marginBottom: ".5rem !important",
    },
  },
  ActionBtnDiv: {
    [theme.breakpoints.down(900)]: {
      // display:"flex !important",
      // flexDirection:"column"
      "& > *": {
        width: "2rem",
        height: "2rem",
        padding: ".5rem",
      },
      "& svg": {
        fontSize: "1rem",
      },
    },
  },
}));

const Orders = (props) => {
  let headCells = props.addOrderLanguage.Add_Order_Table_Head;
  // Customers List Values
  const classes = useStyles();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [orderList, setOrderList] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [order, setOrder] = useState({
    MilkQTY: 0,
    MilkRate: 0,
    YogQTY: 0,
    YogRate: 0,
    OthersQTY: 0,
    OthersRate: 0,
    details: "",
  });
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [Order_Date, set_Order_Date] = useState(new Date());
  const [Delivery_Time, set_Delivery_Time] = useState(1);
  const [orderInfo, set_OrderInfo] = useState({
    total_Milk_Qty: 0,
    total_Yog_Qty: 0,
    total_Others_Qty: 0,
    total_Milk_Amount: 0,
    total_Yog_Amount: 0,
    total_Others_Amount: 0,
    orderId: null,
    Is_Updated_Order: 0,
  });
  const tabIndex = useTabIndex();
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    rowsPerPage,
    page,
    setPage,
  } = useTable(
    props.OrderList,
    headCells,
    null,
    null,
    "medium",
    null,
    null,
    null,
    () => { },
    null,
    false,
    setLoading
  );

  $(document).ready(function () {
    $("input").attr("autocomplete", "off");
    if (collapse == true) {
      $(".topMenuMobileTotalDiv").addClass("topMenuMobileTotalDivactive");
    } else {
      $(".topMenuMobileTotalDiv").removeClass("topMenuMobileTotalDivactive");
    }
  });
  // general input change function
  const handleInputChange = (e) => {
    setOrder({
      ...order,
      [e.target.name]: e.target.value,
    });
    // }
  };
  const handleDeliveryTimeChange = (e) => {
    let { name, value } = e.target;
    set_Delivery_Time(value);
  };

  // useEffect(()=>{
  //     console.log(orderInfo)
  //     console.log(Order_Date)
  //     console.log(Delivery_Time)
  //     if(orderInfo.orderId != null || undefined){
  //
  //     }
  // },[orderInfo.orderId])
  // customer fucctionality start //
  const [customer, setCustomer] = useState({
    Customer_Name: "",
    Customer_ID: null,
  });
  const [searchCustomerName, setSearchCustomerName] = useState(null);
  const [customer_List, set_Customer_List] = useState([]);

  const getCustomers = () => {
    // console.log("i ran");
    const data = {
      searchCustomerName: searchCustomerName,
    };
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post("/api/getallcustomers", data, { headers })
      .then((res) => {
        set_Customer_List(res.data.result);
        // console.log(res.data.result);
      })
      .catch((err) => {
        // console.log("ERROR: ====", err);
      });
  };

  const changeSearchCustomerState = (e) => {
    const searchCustomerNameValue = e.target.value;
    if (searchCustomerNameValue !== "") {
      setSearchCustomerName(searchCustomerNameValue);
      setCustomer({ ...customer, Customer_Name: searchCustomerNameValue });
    } else {
      setSearchCustomerName(null);
      setCustomer({ Customer_Name: "", Customer_ID: null });
    }
  };

  let setCustomerList = (customer) => {
    setCustomer({
      ...customer,
      Customer_Name: customer.Customer_Name,
      Customer_ID: customer.Customer_ID,
    });
    setOrder({
      ...order,
      MilkQTY:
        Delivery_Time === 1
          ? customer.Customer_Morning_Milk_QTY
          : customer.Customer_Evening_Milk_QTY,
      MilkRate: customer.Customer_Milk_Rate,
      YogQTY:
        Delivery_Time === 1
          ? customer.Customer_Morning_Yog_QTY
          : customer.Customer_Evening_Yog_QTY,
      YogRate: customer.Customer_Yog_Rate,
      OthersQTY:
        Delivery_Time === 1
          ? customer.Customer_Morning_Ghee_QTY
          : customer.Customer_Evening_Ghee_QTY,
      OthersRate: customer.Customer_Ghee_Rate,
    });
    setSearchCustomerName(null);
    $("#input_receivable").focus();
  };
  setCustomerList = setCustomerList.bind(this);
  useEffect(() => {
    getCustomers();
  }, [searchCustomerName]);

  let addAndModifyOrder = (ModifyOrder) => { };
  addAndModifyOrder = addAndModifyOrder.bind(this);
  // add or update order fucctionality end //

  const AddAndGetOrders = () => {
    // console.log("insertandgetorders run");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post(
      "/api/insertandgetorders",
      {
        orderDate: misc.formatDateyearmonthday(Order_Date),
        Delivery_Time: Delivery_Time,
        orderId: orderInfo.orderId,
      },
      { headers }
    )
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          // console.log(res.data);
          set_OrderInfo({
            total_Milk_Qty: res.data.orderinfo.Milk_Qty,
            total_Yog_Qty: res.data.orderinfo.Yog_Qty,
            total_Others_Qty: res.data.orderinfo.Ghee_Qty,
            total_Milk_Amount: res.data.orderinfo.Milk_Amount,
            total_Yog_Amount: res.data.orderinfo.Yog_Amount,
            total_Others_Amount: res.data.orderinfo.Ghee_Amount,
            orderId: res.data.orderId,
            Is_Updated_Order: res.data.Is_Updated_Order,
          });
          setOrderList(res.data.transOrders);
          setOrder({
            MilkQTY: 0,
            MilkRate: 0,
            YogQTY: 0,
            YogRate: 0,
            OthersQTY: 0,
            OthersRate: 0,
            details: "",
          });
        }
      })
      .catch((error) => {
        // setNotify({
        //     isOpen: true,
        //     message: error.response.data.message,
        //     type: error.response.data.iseror ? 'error':'success'
        // })
      });
  };
  useEffect(() => {
    if (location.state) {
      updateAndGetOrders();
    }
  }, [location.state]);
  const updateAndGetOrders = () => {
    // console.log("updateandgetorders run");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post(

      "/api/insertandgetorders",
      {
        orderDate: misc.formatDateyearmonthday(location.state.order.OrderDate),
        Delivery_Time: location.state.order.Trans_Event_ID,
        orderId: location.state.order.Trans_Order_ID,
      },
      { headers }
    )
      .then((res) => {
        if (res.data.iserror === false) {
          // console.log(res.data);
          set_OrderInfo({
            total_Milk_Qty: res.data.orderinfo.Milk_Qty,
            total_Yog_Qty: res.data.orderinfo.Yog_Qty,
            total_Others_Qty: res.data.orderinfo.Ghee_Qty,
            total_Milk_Amount: res.data.orderinfo.Milk_Amount,
            total_Yog_Amount: res.data.orderinfo.Yog_Amount,
            total_Others_Amount: res.data.orderinfo.Ghee_Amount,
            orderId: res.data.orderId,
            Is_Updated_Order: res.data.Is_Updated_Order,
          });
          setOrderList(res.data.transOrders);
          setOrder({
            MilkQTY: 0,
            MilkRate: 0,
            YogQTY: 0,
            YogRate: 0,
            OthersQTY: 0,
            OthersRate: 0,
            details: "",
          });
          set_Order_Date(new Date(location.state.order.OrderDate));
        }
      })
      .catch((error) => {
        // setNotify({
        //     isOpen: true,
        //     message: error.response.data.message,
        //     type: error.response.data.iseror ? 'error':'success'
        // })
      });
  };

  // deleteallorders start
  const deleteAllOrders = () => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post(
      "/api/deleteAllorder",
      { orderId: orderInfo.orderId },
      { headers: headers }
    )
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          setOrderList(null);
          set_OrderInfo({
            total_Milk_Qty: 0,
            total_Yog_Qty: 0,
            total_Others_Qty: 0,
            total_Milk_Amount: 0,
            total_Yog_Amount: 0,
            total_Others_Amount: 0,
            orderId: null,
            Is_Updated_Order: 0,
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };
  // deleteallorders end

  //delete silgle order start
  const deleteOrder = (transOrderId) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post(
      "/api/deleteorder",
      { orderId: orderInfo.orderId, transOrderId: transOrderId },
      { headers: headers }
    ).then((res) => {
      setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? "error" : "success",
      });
      if (res.data.iserror === false) {
        if (res.data.orders === 0) {
          // console.log(order + " " + orderInfo + "" + orderList);
          setOrderList(null);
          set_OrderInfo({
            total_Milk_Qty: 0,
            total_Yog_Qty: 0,
            total_Others_Qty: 0,
            total_Milk_Amount: 0,
            total_Yog_Amount: 0,
            total_Others_Amount: 0,
            orderId: null,
            Is_Updated_Order: 0,
          });
        } else {
          set_OrderInfo({
            ...orderInfo,
            total_Milk_Qty: res.data.orderinfo.Milk_Qty,
            total_Yog_Qty: res.data.orderinfo.Yog_Qty,
            total_Others_Qty: res.data.orderinfo.Ghee_Qty,
            total_Milk_Amount: res.data.orderinfo.Milk_Amount,
            total_Yog_Amount: res.data.orderinfo.Yog_Amount,
            total_Others_Amount: res.data.orderinfo.Ghee_Amount,
          });
          setOrderList(res.data.transOrders);
        }
      }
    });
    //  .catch(err=>{
    //     setNotify({
    //         isOpen: true,
    //         message: err.response.data.message,
    //         type: err.response.data.iserror ? 'error':'success'
    //     })
    //  })
  };
  //delete single order end
  //update orders start
  const updatetransOrder = (transOrder) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post(
      "/api/updateorder",
      { orderId: orderInfo.orderId, transOrder: transOrder },
      { headers: headers }
    )
      .then((res) => {
        // console.log("update run");
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          set_OrderInfo({
            ...orderInfo,
            total_Milk_Qty: res.data.orderinfo.Milk_Qty,
            total_Yog_Qty: res.data.orderinfo.Yog_Qty,
            total_Others_Qty: res.data.orderinfo.Ghee_Qty,
            total_Milk_Amount: res.data.orderinfo.Milk_Amount,
            total_Yog_Amount: res.data.orderinfo.Yog_Amount,
            total_Others_Amount: res.data.orderinfo.Ghee_Amount,
          });
          setOrderList(res.data.transOrders);
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };
  // console.log(orderList)

  const openInPopup = (transOrder) => {
    setRecordForEdit(transOrder);
    setOpenPopup(true);
  };
  const addOrEdit = (transOrder, resetForm) => {
    if (transOrder.Trans_ID) {
      updatetransOrder(transOrder);
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };
  //update orders end
  //add single order start
  const addtransOrder = () => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post(
      "/api/addorder",
      {
        transOrder: order,
        orderId: orderInfo.orderId,
        customerId: customer.Customer_ID,
        Order_Date: Order_Date,
        Delivery_Time: Delivery_Time,
      },
      { headers: headers }
    )
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          set_OrderInfo({
            ...orderInfo,
            total_Milk_Qty: res.data.orderinfo.Milk_Qty,
            total_Yog_Qty: res.data.orderinfo.Yog_Qty,
            total_Others_Qty: res.data.orderinfo.Ghee_Qty,
            total_Milk_Amount: res.data.orderinfo.Milk_Amount,
            total_Yog_Amount: res.data.orderinfo.Yog_Amount,
            total_Others_Amount: res.data.orderinfo.Ghee_Amount,
          });
          setOrderList(res.data.transOrders);
          setOrder({
            MilkQTY: 0,
            MilkRate: 0,
            YogQTY: 0,
            YogRate: 0,
            OthersQTY: 0,
            OthersRate: 0,
            details: "",
          });
          setCustomer({ Customer_Name: "", Customer_ID: null });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };
  //add single order end

  const onClose = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    set_OrderInfo({
      total_Milk_Qty: 0,
      total_Yog_Qty: 0,
      total_Others_Qty: 0,
      total_Milk_Amount: 0,
      total_Yog_Amount: 0,
      total_Others_Amount: 0,
      orderId: null,
      Is_Updated_Order: 0,
    });
    setOrderList(null);
    setOrder({
      MilkQTY: 0,
      MilkRate: 0,
      YogQTY: 0,
      YogRate: 0,
      OthersQTY: 0,
      OthersRate: 0,
      details: "",
    });
    history.push("/vieworders");
  };

  const onDeleteOrder = (Trans_ID) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteOrder(Trans_ID);
  };

  const onDeleteAllOrders = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteAllOrders();
  };
  // console.log(props.addOrderLanguage)
  let styles = {
    // fontWeight: "bold",
    padding: 5,
    textAlign: "left",
    verticalAlign: "middle",
    fontSize: 14,
    color: "black",
    fontWeight: "500"
  }
  return (
    <>
      {/* <PageHeader 
                title="Users"
                subTitle="Admin can Add Delete And Edit Users"
                icon={<NoteAdd color="primary" fontSize="large" />}
            /> */}
      <Paper className={classes.pageContent}>
        <Grid
          className={classes.topMenu}
          container
          spacing={1}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item lg={1} md={1} sm={2} xs={4}>
            <Controls.DateInput
              label={props.addOrderLanguage.Input_Order_Date}
              width="100%"
              name="Order_Date"
              type="date"
              id="input_Order_Date"
              nextid="select_Delivery_Time"
              tabIndex={tabIndex}
              value={Order_Date}
              onChange={(Order_Date) => set_Order_Date(Order_Date)}
              disabled={orderList == null ? false : true}
            ></Controls.DateInput>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={4}>
            <Controls.Select
              name="Delivery_Time"
              // label={props.addOrderLanguage.Input_Order_Delivery_Time}
              label="Delivery"
              id="select_Delivery_Time"
              value={Delivery_Time}
              onChange={handleDeliveryTimeChange}
              options={[
                { id: 1, title: "Morning" },
                { id: 2, title: "Evening" },
              ]}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={3}>
            <Controls.Input
              label={props.addOrderLanguage.Input_Order_Milk_Qty}
              width="100%"
              name="totalqty"
              varient="qty"
              tabIndex={tabIndex}
              disabled={true}
              value={orderInfo.total_Milk_Qty}
            ></Controls.Input>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={5}>
            <Controls.Input
              label={props.addOrderLanguage.Input_Order_Milk_Rate}
              width="100%"
              name="totalAmount"
              varient="amount"
              tabIndex={tabIndex}
              disabled={true}
              value={Math.round(orderInfo.total_Milk_Amount)}
            ></Controls.Input>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={6}>
            <Controls.Input
              label={props.addOrderLanguage.Input_Order_Yog_Qty}
              width="100%"
              name="totalqty"
              varient="qty"
              tabIndex={tabIndex}
              disabled={true}
              value={orderInfo.total_Yog_Qty}
            ></Controls.Input>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <Controls.Input
              label={props.addOrderLanguage.Input_Order_Yog_Rate}
              width="100%"
              name="totalAmount"
              varient="amount"
              tabIndex={tabIndex}
              disabled={true}
              value={Math.round(orderInfo.total_Yog_Amount)}
            ></Controls.Input>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={6}>
            <Controls.Input
              label={props.addOrderLanguage.Input_Order_Others_Qty}
              width="100%"
              name="totalqty"
              tabIndex={tabIndex}
              disabled={true}
              varient="qty"
              value={orderInfo.total_Others_Qty}
            ></Controls.Input>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={6}>
            <Controls.Input
              label={props.addOrderLanguage.Input_Order_Others_Rate}
              width="100%"
              name="totalAmount"
              varient="amount"
              tabIndex={tabIndex}
              disabled={true}
              value={Math.round(orderInfo.total_Others_Amount)}
            ></Controls.Input>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={3}>
            <Controls.Button
              text={
                orderInfo.Is_Updated_Order === 1
                  ? props.addOrderLanguage.Button_Delete_All
                  : props.addOrderLanguage.Button_Update
              }
              height="4rem"
              id="button_update"
              nextid="button_close"
              onClick={
                orderInfo.Is_Updated_Order === 1
                  ? () => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure you want to do this ?",
                      subTitle: "You can't undo this operation",
                      onConfirm: onDeleteAllOrders,
                    });
                  }
                  : AddAndGetOrders
              }
              className={classes.newButton}
            ></Controls.Button>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={3}>
            <Controls.Button
              text={props.addOrderLanguage.Button_Save_Close}
              height="4rem"
              id="button_close"
              color="secondary"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure you want to do this ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    onClose();
                  },
                });
              }}
              className={classes.newButton}
            ></Controls.Button>
          </Grid>
        </Grid>
        <Grid
          className={classes.topMenuMobile}
          spacing={1}
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item lg={1} md={1} sm={2} xs={3}>
            <Controls.DateInput
              label={props.addOrderLanguage.Input_Order_Date}
              width="100%"
              name="Order_Date"
              type="date"
              id="input_Order_Date"
              nextid="button_close"
              tabIndex={tabIndex}
              value={Order_Date}
              onChange={(Order_Date) => set_Order_Date(Order_Date)}
              disabled={orderList == null ? false : true}
            ></Controls.DateInput>
          </Grid>
          <Grid item lg={1} md={1} sm={3} xs={3}>
            <Controls.Select
              name="Delivery_Time"
              label={props.addOrderLanguage.Input_Order_Delivery_Time}
              id="select_Delivery_Time"
              value={Delivery_Time}
              onChange={handleDeliveryTimeChange}
              options={[
                { id: 1, title: "Morning" },
                { id: 2, title: "Evening" },
              ]}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <Controls.ActionButton
              // text={orderInfo.Is_Updated_Order === 1 ?"DELETE ALL":"UPDATE"}
              height="4rem"
              colorPrimary="colorPrimary"
              id="button_update"
              nextid="input_customer_name"
              onClick={
                orderInfo.Is_Updated_Order === 1
                  ? () => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure you want to Delete this ?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => onDeleteAllOrders,
                    });
                  }
                  : AddAndGetOrders
              }
              className={classes.newButton}
            >
              {orderInfo.Is_Updated_Order === 1 ? <Delete /> : <Done />}
            </Controls.ActionButton>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <Controls.ActionButton
              height="4rem"
              id="button_close"
              colorPrimary="colorSecondary"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure you want to close this ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    onClose();
                  },
                });
              }}
              className={classes.newButton}
            >
              {orderInfo.Is_Updated_Order === 1 ? <Done /> : <Close />}
            </Controls.ActionButton>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <IconButton
              style={{
                border: "2px solid #5B2B17",
                height: "4rem",
                width: "4rem",
              }}
              onClick={() => {
                setCollapse(!collapse);
              }}
            >
              {collapse ? <ArrowUpward /> : <ArrowDownward />}
            </IconButton>
          </Grid>

          <Grid
            container
            spacing={1}
            className="topMenuMobileTotalDiv"
            sm={12}
            xs={12}
          >
            <Grid item lg={1} md={1} sm={2} xs={4}>
              <Controls.Input
                label={props.addOrderLanguage.Input_Order_Milk_Qty}
                width="100%"
                name="totalqty"
                varient="qty"
                tabIndex={tabIndex}
                disabled={true}
                value={orderInfo.total_Milk_Qty}
              ></Controls.Input>
            </Grid>

            <Grid item lg={1} md={1} sm={2} xs={4}>
              <Controls.Input
                label={props.addOrderLanguage.Input_Order_Yog_Qty}
                width="100%"
                name="totalqty"
                varient="qty"
                tabIndex={tabIndex}
                disabled={true}
                value={orderInfo.total_Yog_Qty}
              ></Controls.Input>
            </Grid>

            <Grid item lg={1} md={1} sm={2} xs={4}>
              <Controls.Input
                label={props.addOrderLanguage.Input_Order_Others_Qty}
                width="100%"
                name="totalqty"
                tabIndex={tabIndex}
                disabled={true}
                varient="qty"
                value={orderInfo.total_Others_Qty}
              ></Controls.Input>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={4}>
              <Controls.Input
                label={props.addOrderLanguage.Input_Order_Amount}
                width="100%"
                name="totalAmount"
                varient="amount"
                tabIndex={tabIndex}
                disabled={true}
                value={Math.round(orderInfo.total_Milk_Amount)}
              ></Controls.Input>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={4}>
              <Controls.Input
                label={props.addOrderLanguage.Input_Order_Amount}
                width="100%"
                name="totalAmount"
                varient="amount"
                tabIndex={tabIndex}
                disabled={true}
                value={Math.round(orderInfo.total_Yog_Amount)}
              ></Controls.Input>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={4}>
              <Controls.Input
                label={props.addOrderLanguage.Input_Order_Amount}
                width="100%"
                name="totalAmount"
                varient="amount"
                tabIndex={tabIndex}
                disabled={true}
                value={Math.round(orderInfo.total_Others_Amount)}
              ></Controls.Input>
            </Grid>
          </Grid>
        </Grid>

        <div
          className={`table-container ${localStorage.getItem("dodaily_urdu") == "true"
            ? "UrduFontTableHead"
            : ""
            }`}
        >

          <div>
            <table className="table table-bordered" id="table-main">
              <TblHead />
              {
                orderList !== null
                  ? orderList
                    .sort((a, b) => a.Customer_Sort_Number - b.Customer_Sort_Number)
                    .map((Order_List_Order, i2) => (
                      <tbody>
                        <tr
                          key={i2 + 2}
                          style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          {/* <td scope="row" style={{ ...styles, textAlign: "center" }}>
                            {Order_List_Order.Customer_Sort_Number}
                          </td> */}
                          <td scope="row" style={{ ...styles, textAlign: "center" }}>
                            <Controls.ActionButton
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure you want to delete this?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    onDeleteOrder(Order_List_Order.Trans_ID);
                                  },
                                });
                              }}
                              colorPrimary="colorSecondary"
                            >
                              <DeleteForever fontSize="small" />
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              onClick={() => {
                                openInPopup(Order_List_Order);
                              }}
                              colorPrimary="colorPrimary"
                            >
                              <Edit fontSize="small" />
                            </Controls.ActionButton>
                          </td>
                          <td style={{ ...styles, textAlign: "center" }}>
                            {Order_List_Order.Customer_Name}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {Order_List_Order.Trans_Milk_Qty}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {Order_List_Order.Trans_Milk_Rate}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {Order_List_Order.Trans_Yog_Qty}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {Order_List_Order.Trans_Yog_Rate}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {Order_List_Order.Trans_Ghee_Qty}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {Order_List_Order.Trans_Ghee_Rate}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {Order_List_Order.Trans_Milk_Qty *
                              Order_List_Order.Trans_Milk_Rate +
                              Order_List_Order.Trans_Yog_Qty *
                              Order_List_Order.Trans_Yog_Rate +
                              Order_List_Order.Trans_Ghee_Qty *
                              Order_List_Order.Trans_Ghee_Rate}
                          </td>
                        </tr>
                      </tbody>
                    )
                    )
                  :
                  null}
            </table>

            <table
              className="table"
              id="table-main-small">
              {
                orderList !== null
                  ? orderList
                    .sort((a, b) => a.Customer_Sort_Number - b.Customer_Sort_Number)
                    .map((Order_List_Order, i2) => (
                      <>
                        <tbody key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Action: </td>
                            <td scope="col" id="mobile-style">
                              <Controls.ActionButton
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: "Are you sure you want to Delete this ?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      onDeleteOrder(Order_List_Order.Trans_ID);
                                    },
                                  });
                                }}
                                colorPrimary="colorSecondary"
                              >
                                <DeleteForever fontSize="small" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                onClick={() => {
                                  openInPopup(Order_List_Order);
                                }}
                                colorPrimary="colorPrimary"
                              >
                                <Edit fontSize="small" />
                              </Controls.ActionButton>
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Customer: </td>
                            <td scope="col" id="mobile-style">
                              {Order_List_Order.Customer_Name}
                            </td>
                          </tr>
                          {Order_List_Order.Trans_Milk_Qty > 0 ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Milk Qty: </td>
                              <td scope="col" id="mobile-style">
                                {Order_List_Order.Trans_Milk_Qty}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Order_List_Order.Trans_Milk_Qty || Order_List_Order.Trans_Milk_Rate > 0 ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Milk Rate: </td>
                              <td scope="col" id="mobile-style">
                                {Order_List_Order.Trans_Milk_Rate}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {
                            Order_List_Order.Trans_Yog_Qty > 0 ?
                              <tr key={i2 + 1}>
                                <td scope="col" id="mobile-style" >Yog Qty: </td>
                                <td scope="col" id="mobile-style">
                                  {Order_List_Order.Trans_Yog_Qty}
                                </td>
                              </tr>
                              :
                              ""
                          }
                          {
                            Order_List_Order.Trans_Yog_Qty || Order_List_Order.Trans_Yog_Rate > 0 ?
                              <tr key={i2 + 1}>
                                <td scope="col" id="mobile-style" >Yog Rate: </td>
                                <td scope="col" id="mobile-style">
                                  {Order_List_Order.Trans_Yog_Rate}
                                </td>
                              </tr>
                              :
                              ""
                          }
                          {
                            Order_List_Order.Trans_Ghee_Qty > 0 ?
                              <tr key={i2 + 1}>
                                <td scope="col" id="mobile-style" >Others Qty: </td>
                                <td scope="col" id="mobile-style">
                                  {Order_List_Order.Trans_Ghee_Qty}
                                </td>
                              </tr>
                              :
                              ""
                          }
                          {
                            Order_List_Order.Trans_Ghee_Qty || Order_List_Order.Trans_Ghee_Rate > 0 ?
                              <tr key={i2 + 1}>
                                <td scope="col" id="mobile-style" >Others Rate: </td>
                                <td scope="col" id="mobile-style">
                                  {Order_List_Order.Trans_Ghee_Rate}
                                </td>
                              </tr>
                              :
                              ""
                          }
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Amount: </td>
                            <td scope="col" id="mobile-style">
                              {Order_List_Order.Trans_Milk_Qty *
                                Order_List_Order.Trans_Milk_Rate +
                                Order_List_Order.Trans_Yog_Qty *
                                Order_List_Order.Trans_Yog_Rate +
                                Order_List_Order.Trans_Ghee_Qty *
                                Order_List_Order.Trans_Ghee_Rate}
                            </td>
                          </tr>
                        </tbody>
                        <hr
                          style={{ margin: 5, padding: 0 }}
                        />
                      </>
                    )
                    )
                  :
                  null
              }
            </table>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
        <Grid
          className={classes.OrdersFooter}
          container
          spacing={1}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className="form_search_customer">
              <Controls.SearchList
                Input_ID="input_customer_name"
                ItemName="Customer_Name"
                ItemAddress="Customer_Address"
                form_Name="customer"
                items={customer_List}
                setSelected={setCustomerList}
              >
                <Controls.Input
                  label={props.addOrderLanguage.Input_Order_Customer_Name}
                  width="100%"
                  marginLeft="2rem"
                  name="Customer_Name"
                  id="input_customer_name"
                  nextid="input_MMQ"
                  tabIndex={tabIndex}
                  value={customer.Customer_Name}
                  onChange={changeSearchCustomerState}
                  placeholder="Select Customer"
                  aria-activedescenda
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                ></Controls.Input>
              </Controls.SearchList>
            </div>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={2}>
            <Controls.InputDecimalCustom
              label={props.addOrderLanguage.Input_Order_Milk_Qty}
              width="100%"
              marginLeft="2rem"
              type="number"
              id="input_MMQ"
              nextid="input_EMQ"
              name="MilkQTY"
              onChange={handleInputChange}
              varient="amount"
              value={order.MilkQTY}
            ></Controls.InputDecimalCustom>
          </Grid>

          <Grid item lg={1} md={1} sm={3} xs={3}>
            <Controls.InputDecimalCustom
              label={props.addOrderLanguage.Input_Order_Milk_Rate}
              width="100%"
              marginLeft="2rem"
              type="number"
              id="input_MR"
              nextid="input_MYQ"
              name="MilkRate"
              onChange={handleInputChange}
              varient="amount"
              value={order.MilkRate}
            ></Controls.InputDecimalCustom>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={2}>
            <Controls.InputDecimalCustom
              label={props.addOrderLanguage.Input_Order_Yog_Qty}
              width="100%"
              marginLeft="2rem"
              type="number"
              id="input_MYQ"
              nextid="input_EGQ"
              name="YogQTY"
              onChange={handleInputChange}
              varient="amount"
              value={order.YogQTY}
            ></Controls.InputDecimalCustom>
          </Grid>

          <Grid item lg={1} md={1} sm={3} xs={3}>
            <Controls.InputDecimalCustom
              label={props.addOrderLanguage.Input_Order_Yog_Rate}
              width="100%"
              marginLeft="2rem"
              type="number"
              id="input_YR"
              nextid="input_MGQ"
              name="YogRate"
              onChange={handleInputChange}
              varient="amount"
              value={order.YogRate}
            ></Controls.InputDecimalCustom>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={2}>
            <Controls.InputDecimalCustom
              label={props.addOrderLanguage.Input_Order_Others_Qty}
              width="100%"
              marginLeft="2rem"
              type="number"
              id="input_MOQ"
              nextid="input_EOQ"
              name="OthersQTY"
              onChange={handleInputChange}
              varient="amount"
              value={order.OthersQTY}
            ></Controls.InputDecimalCustom>
          </Grid>
          <Grid item lg={1} md={1} sm={3} xs={3}>
            <Controls.InputDecimalCustom
              label={props.addOrderLanguage.Input_Order_Others_Rate}
              //  width="100%"
              //  marginLeft="2rem"
              type="number"
              id="input_OR"
              nextid="input_details"
              name="OthersRate"
              onChange={handleInputChange}
              varient="amount"
              value={order.OthersRate}
            ></Controls.InputDecimalCustom>
          </Grid>
          <Grid item lg={2} md={2} sm={7} xs={7}>
            <Controls.Input
              label={props.addOrderLanguage.Input_Order_Detail}
              width="100%"
              marginLeft="2rem"
              height="4rem"
              onChange={handleInputChange}
              placeholder="details"
              name="details"
              id="input_details"
              nextid="button_submit"
              value={order.details}
              type="input"
            ></Controls.Input>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={2}>
            <Controls.Button
              text={props.addOrderLanguage.Button_Add}
              height="4rem"
              id="button_submit"
              nextid="input_customer_name"
              onClick={addtransOrder}
              className={classes.newButton}
            ></Controls.Button>
          </Grid>
        </Grid>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title="Orderer Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <OrderForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
    </>
  );
};

const mapStateToProps = (state) => ({
  addOrderLanguage: state.language.language.Order.Add_Order,
});

export default connect(mapStateToProps, null)(memo(Orders));
