import {combineReducers} from 'redux'
import Auth from "./reducers/authReducer"
import User from "./reducers/UserReducer"
import orderItemsList from "./reducers/orderItemsListReducer"
import OrdersList from "./reducers/ordersListReducer"
import ItemsList from "./reducers/itemsReducer"
import CustomerList from "./reducers/CustomersReducer"
import AreasList from "./reducers/AreasReducer"
import ReceivedsList from "./reducers/ReceivedsReducer"
import BalanceList from "./reducers/BalanceReducer"
import DailyOrderList from "./reducers/DailyOrdersReducer"
import AccountsBalancesList from "./reducers/AccountsBalancesReducer"
import language from './reducers/languageReducer'

export default combineReducers({
    Auth:Auth,
    User:User,
    orderItemsList:orderItemsList,
    OrdersList:OrdersList,
    ItemsList:ItemsList,
    CustomerList:CustomerList,
    AreasList:AreasList,
    ReceivedsList:ReceivedsList,
    BalanceList:BalanceList,
    DailyOrderList:DailyOrderList,
    AccountsBalancesList:AccountsBalancesList,
    language:language
})