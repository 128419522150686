// import {GETDAILYORDERS,DELETEDAILYORDER} from './types'
export const setDailyOrders =(DailyOrders)=>(
    
    {type:"SETDAILYORDERS",
    payload:DailyOrders}
)

export const DeleteDailyOrderFromReduxState =(OrderID,totalOrders,totalPages)=>(
    
    {type:"DELETEDAILYORDER",
       payload: {OrderID,totalOrders,totalPages}}
)

export const emptyDailyOrderList = () => ({
    type: 'EMPTYDAILYORDERLIST',
})