import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import $ from 'jquery'

const useStyles = makeStyles((theme) => ({
  Input: {
    "& .MuiOutlinedInput-input": {
      padding: "0"
    },
    "& .MuiInputBase-root ": {
      padding: "0",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      height: "4rem ",
      [theme.breakpoints.down('lg')]: {
        fontSize: "1.6rem !important"
      },
      [theme.breakpoints.down('md')]: {
        fontSize: "1.4rem !important"
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.3rem !important"
      }
    },
    "& .MuiInput-underline:before , .MuiInput-underline:after": {
      display: "none"
    },
    "& .MuiInputLabel-formControl": {
      backgroundColor: "white",
      paddingBottom: ".2rem !important",
      paddingRight: ".5rem !important",
      paddingLeft: ".5rem !important",
      fontSize: "1.4rem ",
      [theme.breakpoints.down('md')]: {
        fontSize: "1rem",
        paddingBottom: ".2rem !important",
        paddingRight: ".2rem !important",
        paddingLeft: ".2rem !important",
      },

      //   [theme.breakpoints.down('sm')]: {
      //     fontSize:"1.3rem !important" ,
      //     paddingBottom:".2rem", 
      //   }
    },

  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[0]/, /[3]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};



export default function InputMobile(props) {
  const classes = useStyles();
  const { name, label, value, error = null, onChange, nextid, saveKeyId, listItemsClassName, type = 'text', width, id, tabindex, ...other } = props;
  function handleKeyUp(event) {
    if (event.keyCode === 13) {
      $(`#${nextid}`).focus()
    }
    else if (event.keyCode === 13 && event.keyCode === 18) {
      $(`#${saveKeyId}`).focus()
    }
  }

  return (
    <FormControl variant="outlined" className={`${classes.Input} ${localStorage.getItem('dodaily_urdu') == "true" ? "UrduFontInputLabel" : ""}`}>
      <InputLabel variant="outlined" shrink={true} htmlFor={id}>{props.label}</InputLabel>

      <OutlinedInput
        className={classes.Input}
        aria-autocomplete="none"
        style={{ width: `${width}` }}
        variant="outlined"
        label={label}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        autoComplete='false'
        onKeyDown={handleKeyUp}
        id={id}
        {...other}
        {...(error && { error: true, helperText: error })}
        // inputComponent={TextMaskCustom}
      />
    </FormControl>
  );
}