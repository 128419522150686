import React from 'react'
import { TextField,makeStyles } from '@material-ui/core';
import $ from 'jquery'
// import Inputmask from "inputmask";
import misc from '../../fnc/misc'
const useStyles = makeStyles(theme => ({
    Input: {
        width:"100%",
        "&  .MuiInputBase-input ":{
            padding: "0",
            paddingLeft:"1rem",
            paddingRight:"1rem",
            height:"4rem ",
            [theme.breakpoints.down('lg')]: {
                fontSize:"1.6rem !important",
             
               },
            [theme.breakpoints.down('md')]: {
               fontSize:"1.4rem !important",
             
              },
              [theme.breakpoints.down('sm')]: {
                fontSize:"1.3rem !important",
             
              },
            //   "& .PrivateNotchedOutline-legendNotched-38":{
            //     maxWidth:"fit-content"
            // }
        },
        "& .MuiInputLabel-formControl":{
            backgroundColor: "white",
            width:"fit-content",
            fontSize:"1.3rem " ,
            [theme.breakpoints.down('md')]: {
                fontSize:"1rem " ,
            
              },
            //   [theme.breakpoints.down('sm')]: {
            //     fontSize:"1.3rem !important" ,
            //     paddingBottom:".2rem", 
            //   }
        },
        
       
    },
    "qty": {
        "& .MuiInputBase-root  .MuiInputBase-input":{
            textAlign:"right !important"
        },
          
    },
    "amount": {
        "& .MuiInputBase-root  .MuiInputBase-input":{
            textAlign:"right !important"
        },  
    },
    "urdu": {
        "& .MuiInputBase-root  .MuiInputBase-input":{
            textAlign:"right !important"
        },
    }
}))
export default function Input(props) {
    const classes = useStyles();
    const { name, label, value,error=null, onChange,nextid,saveKeyId,listItemsClassName,type='text',varient,id,tabindex ,...other } = props;
    
    
    

    $("input").blur(function() {
        if ($(this).attr("data-selected-all")) {
        //Remove atribute to allow select all again on focus        
        $(this).removeAttr("data-selected-all");
        
        }
      });
      
      $("input").click(function() {
        if (!$(this).attr("data-selected-all")) {
          try {
            $(this).selectionStart = 0;
            $(this).selectionEnd = $(this).value.length + 1;
            //add atribute allowing normal selecting post focus
            $(this).attr("data-selected-all", true);
          } catch (err) {
            $(this).select();
            //add atribute allowing normal selecting post focus
            $(this).attr("data-selected-all", true);
          }
        }
      });
    
    $(document).ready(function() {
        $('#custom2').keypress(function (event) {
            return isNumber(event, this)
        });
    });
    // THE SCRIPT THAT CHECKS IF THE KEY PRESSED IS A NUMERIC OR DECIMAL VALUE.
    function isNumber(evt, element) {

        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (
            (charCode != 45 || $(element).val().indexOf('-') != -1) &&      // “-” CHECK MINUS, AND ONLY ONE.
            (charCode != 46 || $(element).val().indexOf('.') != -1) &&      // “.” CHECK DOT, AND ONLY ONE.
            (charCode < 48 || charCode > 57))
            return false;

        return true;
    }    
    return (
        <TextField
        aria-autocomplete="none"
        className={[classes.Input, classes[varient] , localStorage.getItem('dodaily_urdu') == "true"?"UrduFontInputLabel":""]}
            variant="outlined"
            label={label}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            autoComplete='false'
        id="custom2"
            {...other}
            {...(error && {error:true,helperText:error})}
            InputLabelProps={{
                shrink: true
              }}
        />
    )
}
