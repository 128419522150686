import React from 'react'
import { Button, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 0,
        // margin: theme.spacing(0.2)
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiButton-label': {
            color: theme.palette.secondary.main,
        }
    },
    primary: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiButton-label': {
            color: theme.palette.primary.main,
        }
    },
}))

export default function ActionButton(props) {

    const { color, children, onClick,variant="contained",height } = props;
    const classes = useStyles();

    return (
        <Button
        // color={color}
        style={height?{height:`${height}`}:{height:`inherit`,}}
        variant={variant}
            className={`${classes.root} ${props.colorPrimary}`}
            onClick={onClick}>
            {children}
        </Button>
    )
}
