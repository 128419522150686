import {AUTHENTICATEUSER,LOGUOTUSER} from './types'

export const SigninHandler =(data)=>( {
  type: AUTHENTICATEUSER,
  payload:data
})
export const LogOutHandler =()=>dispatch=>{
  sessionStorage.removeItem("accountingauthkey")
  sessionStorage.removeItem("accountinguser")
  sessionStorage.removeItem("usermobile")
  sessionStorage.removeItem("usershop")
  sessionStorage.removeItem("usercontactperson")
  sessionStorage.removeItem("usershopaddress")
  sessionStorage.removeItem("usertype")
  sessionStorage.removeItem("usermobile1")
  sessionStorage.removeItem("usercontactperson1")
  dispatch({type: LOGUOTUSER})  
    
}
