
import axios from 'axios'

export const getBalances =(data,setLoading)=>dispatch=>{
    const headers={
        "x-access-token": sessionStorage.getItem("accountingauthkey")
    }
    axios.post('/api/getbalances',data,{headers:headers}).then(res=>{
        // console.log(res) 
      setLoading(false)
    dispatch({type:"SETBALANCES",payload:res.data})})
}


export const emptyBalanceList = () => ({
    type: 'EMPTYBALANCELIST',
})