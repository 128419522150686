import {addToCart,deleteItem} from '../utils/orderItemsUtils'
const initialState = {
    Order_Items_List:[]
}

const orderItemsList = (state = initialState, Action) => {
    switch (Action.type) {
        case 'DELETECARTITEM':
            
            return {
                ...state,
                Order_Items_List: deleteItem(state.Order_Items_List,Action.payload),
            }
        break
        case 'SETCARTITEM':
                
            return {
                    ...state,
                    Order_Items_List: addToCart(state.Order_Items_List,Action.payload),
            }
        break
        case 'SETCARTITEMS':
                
                    return {
                        ...state,
                        Order_Items_List: Action.payload,
                    }
        break

        case 'EMPTYCART':
                    return {
                        ...state,
                         Order_Items_List: [],
                    }
        break
        default:
            return state
            break
    }
}

export default orderItemsList
