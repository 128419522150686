import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";


const useStyles = makeStyles(theme => ({

    grid1: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
            "& > :not(:last-child)": {
                marginBottom: "1rem"
            },
        },
    },

    gridName: {
        width: "40%",
        [theme.breakpoints.down(600)]: {
            width: "100%",

        },
    },
    gridDetail: {
        width: "58%",
        [theme.breakpoints.down(600)]: {
            width: "100%",
        },

    },
    grid2: {
        display: "flex",
        justifyContent: "space-around",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
        },
    },
    gridMorning: {
        border: ".1rem solid #c4c4c4",
        borderRadius: ".4rem",
        padding: "1rem",
        position: "relative",
        margin: ".8rem .8rem 0rem 0rem",
        width: "50%",
        "& > :not(:last-child)": {
            marginBottom: "1rem"
        },
        [theme.breakpoints.down(600)]: {
            width: "100%",
            margin: ".8rem .0rem .8rem 0rem",

        },
        "& .LabelMorning": {
            top: "-.7rem",
            position: "absolute",
            backgroundColor: "white",
            color: theme.palette.primary.dark,
            fontSize: "1rem",
            padding: "0rem .4rem 0rem .4rem"
        }

    },
    gridEvening: {
        border: ".1rem solid #c4c4c4",
        borderRadius: ".4rem",
        padding: "1rem",
        margin: ".8rem .0rem 0rem .8rem",
        width: "50%",
        position: "relative",
        "& > :not(:last-child)": {
            marginBottom: "1rem"
        },
        [theme.breakpoints.down(600)]: {
            width: "100%",
            margin: ".8rem .0rem .8rem 0rem",

        },
        "& .LabelEvening": {
            top: "-.7rem",
            position: "absolute",
            backgroundColor: "white",
            color: theme.palette.primary.dark,
            fontSize: "1rem",
            padding: "0rem .4rem 0rem .4rem"
        }
    },
    grid3: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: ".8rem 0rem .8rem 0rem",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
            marginLeft: ".8rem"
        },
    },
    grid4: {
        display: "flex",
        width: "80%",
        justifyContent: "space-between",
        border: ".1rem solid #c4c4c4",
        borderRadius: ".4rem",
        padding: "1rem",
        margin: ".8rem .0rem 0rem .8rem",
        position: "relative",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
            margin: ".8rem",
            width: "100%",
        },
        "& .LabelRate": {
            top: "-.7rem",
            position: "absolute",
            backgroundColor: "white",
            color: theme.palette.primary.dark,
            fontSize: "1rem",
            padding: "0rem .4rem 0rem .4rem"
        }
    },
    grid5: {
        display: "flex",
        width: "20%",
        [theme.breakpoints.down(600)]: {
            width: "100%",
        },
        "& .MuiTypography-body1 , svg": {
            fontSize: "2rem !important"
        }
    },
    grid6: {
        margin: ".8rem .0rem 0rem 0rem",

    }


}))
const initialFValues = {
    trans_Customer_ID: null,
    trans_Order_ID: null,
    Customer_Name: "",
    Trans_Milk_Qty: "",
    Trans_Yog_Qty: "",
    Trans_Ghee_Qty: "",
    Trans_Milk_Rate: "",
    Trans_Yog_Rate: "",
    Trans_Ghee_Rate: "",
    Trans_Detail: ""
}

export default function CustomerForm(props) {
    const { addOrEdit, recordForEdit, formLanguage } = props
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const classes = useStyles()
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        // if ('Customer_Name' in fieldValues)
        //     temp.Customer_Name = fieldValues.Customer_Name ? "" : "Customer_Name is not valid."
        // if ('Customer_Mobile' in fieldValues)
        //     temp.Customer_Mobile = fieldValues.Customer_Mobile ? "" : "Customer_Mobile is not valid."
        // if ('Customer_Address' in fieldValues)
        //     temp.Customer_Address = fieldValues.Customer_Address ? "" : "Customer_Address is not valid."
        // if ('Customer_Morning_Milk_Qty' in fieldValues)
        //     temp.Customer_Morning_Milk_Qty = fieldValues.Customer_Morning_Milk_Qty > 0 ? "" : "Customer_Morning_Milk_Qty is not valid."
        // if ('Customer_Evening_Milk_Qty' in fieldValues)
        //     temp.Customer_Evening_Milk_Qty = fieldValues.Customer_Evening_Milk_Qty ? "" : "Customer_Evening_Milk_Qty is not valid."
        // if ('Customer_Morning_Yog_Qty' in fieldValues)
        //     temp.Customer_Morning_Yog_Qty = fieldValues.Customer_Morning_Yog_Qty ? "" : "Customer_Morning_Yog_Qty is not valid."
        // if ('Customer_Evening_Yog_Qty' in fieldValues)
        //     temp.Customer_Evening_Yog_Qty = fieldValues.Customer_Evening_Yog_Qty ? "" : "Customer_Evening_Yog_Qty is not valid."
        // if ('Customer_Morning_Ghee_Qty' in fieldValues)
        //     temp.Customer_Morning_Ghee_Qty = fieldValues.Customer_Morning_Ghee_Qty ? "" : "Customer_Morning_Ghee_Qty is not valid."
        // if ('Customer_Evening_Ghee_Qty' in fieldValues)
        //     temp.Customer_Evening_Ghee_Qty = fieldValues.Customer_Evening_Ghee_Qty ? "" : "Customer_Evening_Ghee_Qty is not valid."
        // if ('Customer_Milk_Rate' in fieldValues)
        //     temp.Customer_Milk_Rate = fieldValues.Customer_Milk_Rate ? "" : "Customer_Milk_Rate is not valid."
        // if ('Customer_Yog_Rate' in fieldValues)
        //     temp.Customer_Yog_Rate = fieldValues.Customer_Yog_Rate ? "" : "Customer_Yog_Rate is not valid."
        // if ('Customer_Ghee_Rate' in fieldValues)
        //     temp.Customer_Ghee_Rate = fieldValues.Customer_Ghee_Rate ? "" : "Customer_Ghee_Rate is not valid."

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputNunberChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = () => {
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
        // console.log(initialFValues)
        // console.log(recordForEdit)
    }, [recordForEdit])
    const onAddOrUdpateCustomer = () => {

        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }

    const onResetForm = () => {

        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        resetForm()
    }

    // console.log(formLanguage)

    return (
        <>
            <Form onSubmit={(e) => {
                e.preventDefault()
                setConfirmDialog({
                    isOpen: true,
                    title: 'Are you sure to Submit',
                    subTitle: "You can't undo this operation",
                    onConfirm: () => { onAddOrUdpateCustomer() }
                })
            }}>
                <div className={classes.grid1}>
                    <div className={classes.gridName}>
                        <Controls.Input
                            name="Customer_Name"
                            label={formLanguage.Input_Customer_Name}
                            value={values.Customer_Name}
                            onChange={handleInputChange}
                            error={errors.Customer_Name}
                        />
                    </div>
                    <div className={classes.gridDetail}>
                        <Controls.Input
                            name="Trans_Detail"
                            label={formLanguage.Input_Detail}
                            value={values.Trans_Detail}
                            onChange={handleInputChange}
                            error={errors.Trans_Detail}
                        />
                    </div>
                </div>

                <div className={classes.grid2}>
                    <div className={classes.gridMorning}>
                        <label className="LabelMorning">{formLanguage.Label_Quantity}</label>
                        <Controls.InputDecimalCustom
                            placeholder="0.00"
                            currencySymbol=""
                            name="Trans_Milk_Qty"
                            label={formLanguage.Input_Milk}
                            value={values.Trans_Milk_Qty}
                            onChange={handleInputChange}
                            error={errors.Trans_Milk_Qty}
                        />
                        <Controls.InputDecimalCustom
                            placeholder="0.00"
                            currencySymbol=""
                            name="Trans_Yog_Qty"
                            label={formLanguage.Input_Yog}
                            value={values.Trans_Yog_Qty}
                            onChange={handleInputChange}
                            error={errors.Trans_Yog_Qty}
                        />
                        <Controls.InputDecimalCustom
                            placeholder="0.00"
                            currencySymbol=""
                            name="Trans_Ghee_Qty"
                            label={formLanguage.Input_Others}
                            value={values.Trans_Ghee_Qty}
                            onChange={handleInputChange}
                            error={errors.Trans_Ghee_Qty}
                        />
                    </div>

                    <div className={classes.gridEvening}>
                        <label className="LabelEvening">{formLanguage.Label_Price}</label>
                        <Controls.InputDecimalCustom
                            placeholder="0.00"
                            currencySymbol=""
                            name="Trans_Milk_Rate"
                            label={formLanguage.Input_Milk}
                            value={values.Trans_Milk_Rate}
                            onChange={handleInputChange}
                            error={errors.Trans_Milk_Rate}
                        />
                        <Controls.InputDecimalCustom
                            placeholder="0.00"
                            currencySymbol=""
                            name="Trans_Yog_Rate"
                            label={formLanguage.Input_Yog}
                            value={values.Trans_Yog_Rate}
                            onChange={handleInputChange}
                            error={errors.Trans_Yog_Rate}
                        />
                        <Controls.InputDecimalCustom
                            placeholder="0.00"
                            currencySymbol=""
                            name="Trans_Ghee_Rate"
                            label={formLanguage.Input_Others}
                            value={values.Trans_Ghee_Rate}
                            onChange={handleInputChange}
                            error={errors.Trans_Ghee_Rate}
                        />
                    </div>
                </div>
                <div className={classes.grid6}>
                    <Controls.Button
                        keyupfire={false}
                        type="submit"
                        // text={formLanguage.Add_Button}
                        text="Submit"
                    />
                    {/* <Controls.Button
                        keyupfire={false}
                            text="Reset"
                            color="default"
                            onClick={() => {
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to reset',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onResetForm() }
                                })
                                }}/> */}
                </div>

            </Form>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
