import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss'; 
import App from "./App"
import { Provider } from 'react-redux'
import store from './redux/Store'

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
)

// serviceWorkerRegistration.register();

