import {deleteOrder} from '../utils/orderListUtls'
const initilastate ={
    Order_List:[],
    totalPages:"",
    currentPage:"",
    totalOrders:"",
    }

    const OrdersList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "GETORDERS":
                  
                return{
                    ...state,
                    Order_List: [...state.Order_List,...Action.payload.Orders],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalOrders:Action.payload.totalOrders,
                    }
            break;
            case "DELETEORDER":
                return{
                    ...state,
                    Order_List:deleteOrder(state.Order_List,Action.payload.OrderID),
                    totalOrders:Action.payload.totalOrders,
                    totalPages:Action.payload.totalPages
                    }
            case 'EMPTYORDERLIST':
                    return {
                        ...state,
                        Order_List: [],
                        totalPages:"",
                        currentPage:"",
                        totalOrders:"",
                    }
                    break
            default:
                return state
                break;
        }
    
    }
    
    export default OrdersList
    
    

