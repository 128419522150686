import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import '../css/font.css';
import useTable from "../../components/useTable";
import { DeleteForever, Edit, NoteAdd, Search } from "@material-ui/icons";
import "../Customers/pages.css"
import {
  Paper,
  Toolbar,
  makeStyles,
  Grid,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import CurrencyTextField from "@lupus-ai/mui-currency-textfield";
import Controls from "../../components/controls/Controls";
import Notification from "../../components/Notification";
import { connect } from "react-redux";
import {
  getBalances,
  emptyBalanceList,
} from "../../redux/actions/BalancesAction";
import "jspdf-autotable";
import $ from "jquery";
import misc from "../../fnc/misc";
import PageHeader from "../../components/PageHeader";
import { useTabIndex } from "react-tabindex";
import ConfirmDialog from "../../components/ConfirmDialog";
// import e from "cors";

let myheight = window.innerHeight;
let myWidth = window.innerWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    "& .pageHeaderPageInfo": {
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    "& .makeStyles-pageHeader-32": {
      justifyContent: "flex-end",
    },
  },
  pageContent: {
    padding: "1rem 1rem 1rem 1rem",
    height: `calc(100vh - 18rem)`,
    [theme.breakpoints.down("md")]: {
      height: `calc(100vh - 18rem)`,
    },
    [theme.breakpoints.down("sm")]: {
      height: `calc(100vh - 20rem)`,
    },
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - 23rem)`,
    },
    overflow: "hidden",
    "& .MuiGrid-item > *": {
      maxWidth: `calc(100% - 1rem) !important`,
      width: `calc(100% - 1rem) !important`,
      marginRight: `1rem !important`,
    },
    "& .table-container": {
      height: `100%`,
      overflow: "auto",
      "& thead": {
        "&  tr": {
          "& [name=name]": {
            minWidth: "10rem !important",
          },
          "& [name=detail]": {
            minWidth: "20rem !important",
          },
          "& [name=amount]": {
            minWidth: "10rem !important",
          },
          "& [name=actions]": {
            minWidth: "10rem !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=name]": {
              minWidth: "auto !important",
            },
            "& [name=detail]": {
              minWidth: "auto !important",
            },
            "& [name=amount]": {
              minWidth: "auto !important",
            },
            "& [name=actions]": {
              minWidth: "auto !important",
            },
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& [name=name]": {
            minWidth: "10rem !important",
          },
          "& [name=detail]": {
            minWidth: "20rem !important",
          },
          "& [name=amount]": {
            minWidth: "10rem !important",
          },
          "& [name=actions]": {
            minWidth: "10rem !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=name]": {
              minWidth: "auto !important",
            },
            "& [name=detail]": {
              minWidth: "auto !important",
              height: "1.9rem",
            },
            "& [name=amount]": {
              minWidth: "auto !important",
            },
            "& [name=actions]": {
              minWidth: "auto !important",
            },
          },
        },
      },

      "& .code": {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
    },
    //     "& .MuiGrid-item > *":{

    //         maxWidth: `calc(100% - 1rem) !important`,
    //         width: `calc(100% - 1rem) !important`,
    //         marginRight: `1rem !important`
    //    },
  },

  BalanceHeader: {
    "& > * ": {
      marginBottom: "2rem",
    },
  },

  orderID: {
    paddingTop: ".9rem",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0rem",
      alignItems: "center",
    },
  },

  mobileAddBtn: {
    [theme.breakpoints.down(599)]: {
      marginBottom: ".5rem !important",
    },
  },
  ActionBtnDiv: {
    [theme.breakpoints.down(900)]: {
      // display:"flex !important",
      // flexDirection:"column"
      "& > *": {
        width: "4rem",
        height: "3rem",
        padding: ".5rem",
      },
      "& svg": {
        fontSize: "1.6rem",
      },
    },
  },
  // ToolbarItems:{
  //     display:"flex",
  //     alignItems:"center",
  // },
}));

const Balance = (props) => {
  $(document).ready(function () {
    $("input").attr("autocomplete", "off");
    // $('input#input_receivable').blur(function(){
    //     var num = parseFloat($(this).val());
    //     var cleanNum = num.toFixed(2);
    //     $(this).val(cleanNum);
    //     if(num/cleanNum < 1){
    //         $('#error').text('Please enter only 2 decimal places, we have truncated extra points');
    //         }
    //     });
  });
  let headCells =
    props.balanceLanguage.Balance.Balance_Table.Balance_Table_Head;
  // Customers List Values
  const classes = useStyles();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [balance, setBalance] = useState({
    transId: null,
    receivable: 0,
    payable: 0,
    details: "",
  });
  const [loading, setLoading] = useState(false);
  //on date 31 june is set but it pick 30 june.
  const [Balance_Date, set_Balance_Date] = useState(new Date("2021-06-30"));
  const tabIndex = useTabIndex();
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    rowsPerPage,
    page,
    setPage,
    handleScroll,
    handleSortRequest,
    setOrder,
  } = useTable(
    props.BalanceList,
    headCells,
    filterFn,
    null,
    "medium",
    props.totalPages,
    props.currentPage,
    props.totalBalances,
    props.getBalances,
    props.emptyBalanceList,
    true,
    setLoading
  );
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter(
            (x) =>
              `${x.Customer_Name}${x.Customer_Mobile}`
                .toUpperCase()
                .includes(target.value) ||
              misc
                .capitalize(`${x.Customer_Name}${x.Customer_Mobile}`)
                .includes(target.value) ||
              `${x.Customer_Name}${x.Customer_Mobile}`
                .toLowerCase()
                .includes(target.value)
          );
      },
    });
  };

  // general input change function

  const handleInputChange = (e) => {
    setBalance({
      ...balance,
      [e.target.name]: e.target.value,
    });
  };
  //input change balance receivable value
  const handleInputbalanceReceivableChange = (value) => {
    setBalance({
      ...balance,
      receivable: value,
    });
  };
  //input change balance payable value
  const handleInputBalancePayableChange = (value) => {
    setBalance({
      ...balance,
      payable: value,
    });
  };
  // customer fucctionality start //
  const [customer, setCustomer] = useState({
    Customer_Name: "",
    Customer_ID: "",
  });
  const [searchCustomerName, setSearchCustomerName] = useState(null);
  const [customer_List, set_Customer_List] = useState([]);

  const getCustomers = () => {
    // console.log("i ran");
    const data = { searchCustomerName: searchCustomerName };
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post("/api/getallcustomers", data, { headers })
      .then((res) => set_Customer_List(res.data.result))
      .catch((err) => {
        // console.log("ERROR: ====", err);
      });
  };

  const changeSearchCustomerState = (e) => {
    const searchCustomerNameValue = e.target.value;
    if (searchCustomerNameValue !== "") {
      setSearchCustomerName(searchCustomerNameValue);
      setCustomer({ ...customer, Customer_Name: searchCustomerNameValue });
    } else {
      setSearchCustomerName(null);
      setCustomer({ Customer_Name: "", Customer_ID: null });
    }
  };

  let setCustomerList = (customer) => {
    setCustomer({
      ...customer,
      Customer_Name: customer.Customer_Name,
      Customer_ID: customer.Customer_ID,
    });
    setSearchCustomerName(null);
    $("#input_receivable").focus();
  };
  setCustomerList = setCustomerList.bind(this);
  useEffect(() => {
    getCustomers();
  }, [searchCustomerName]);
  // customer fucctionality end //

  // add or update balance fucctionality start //
  const AddOrUpdateOrder = () => {
    let endPoint;
    balance.transId === null
      ? (endPoint = "addbalance")
      : (endPoint = "updatebalance");

    const data = {
      transId: balance.transId,
      balanceDate: Balance_Date,
      receivable: balance.receivable,
      payable: balance.payable,
      details: balance.details,
      customerID: customer.Customer_ID,
    };
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post(`/api/${endPoint}`, data, { headers })
      .then((res) => {
        if (res.data.iserror === false) {
          setNotify({
            isOpen: true,
            message: res.data.message,
            type: res.data.iserror ? "error" : "success",
          });
          setBalance({
            transId: null,
            receivable: "",
            payable: "",
            details: "",
          });
          setCustomer({ Customer_Name: "", Customer_ID: "" });
          props.emptyBalanceList();
          setPage(0);
          props.getBalances({ page: 0, limit: rowsPerPage }, setLoading);
        }
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: error.response.data.iserror ? "error" : "success",
        });
      });
  };

  const onAddOrUpdateOrder = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    AddOrUpdateOrder();
  };
  let addAndModifyBalance = (ModifyBalance) => {
    setBalance({
      transId: ModifyBalance.Trans_ID,
      payable: ModifyBalance.Credit_Amount,
      receivable: ModifyBalance.Debit_Amount,
      details: ModifyBalance.Trans_Detail,
    });
    setCustomer({
      Customer_Name: ModifyBalance.Customer_Name,
      Customer_ID: ModifyBalance.Trans_Customer_ID,
    });
    setSearchCustomerName(null);
  };
  addAndModifyBalance = addAndModifyBalance.bind(this);
  // add or update balance fucctionality end //

  const deleteBalance = (transId) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    Axios.post("/api/deletebalance", { transId, transId }, { headers: headers })
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          props.emptyBalanceList();
          setPage(0);
          props.getBalances({ page: 0, limit: rowsPerPage }, setLoading);
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };

  const onDeleteBalance = (dalanceId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteBalance(dalanceId);
  };
  let styles = {
    // fontWeight: "bold",
    padding: 5,
    textAlign: "left",
    verticalAlign: "middle",
    fontSize: 14,
    color: "black",
    fontWeight: "500"
  }
  return (
    <div class={classes.root}>
      <PageHeader
        title={props.balanceLanguage.Balance.Balance_Header.Title}
        icon={<NoteAdd color="primary" fontSize="large" />}
      >
        <Grid
          container
          spacing={1}
          className={classes.ToolbarItems}
          lg={9}
          md={9}
          sm={12}
          xs={12}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Controls.Input
              id="fonting"
              label={
                props.balanceLanguage.Balance.Balance_Header.SearchInputTitle
              }
              width="100%"
              onChange={handleSearch}
            />
          </Grid>
        </Grid>
      </PageHeader>
      <Paper className={classes.pageContent}>
        <Grid
          className={classes.BalanceHeader}
          container
          spacing={1}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          {/* <Grid item lg={1} md={2} sm={3} xs={3}>
                    <Controls.DateInput 
                            label="Date"
                            width="100%"
                            disabled="true"
                            name="Order_Date"
                            type="date"
                            id="input_Order_Date"
                            tabIndex={tabIndex}
                            nextid='input_Order_Reference'
                         value={ Balance_Date}
                          onChange={()=>set_Balance_Date(Balance_Date)}>
                        </Controls.DateInput>
                        </Grid> */}
          <Grid item xl={4} lg={3} md={3} sm={6} xs={12}>
            <div className="form_search_customer">
              <Controls.SearchList
                Input_ID="input_customer_name"
                ItemName="Customer_Name"
                ItemAddress="Customer_Address"
                form_Name="customer"
                items={customer_List}
                setSelected={setCustomerList}
              >
                <Controls.Input
                  label={
                    props.balanceLanguage.Balance.Balance_Form
                      .Input_Balance_Name
                  }
                  width="100%"
                  marginLeft="2rem"
                  name="Customer_Name"
                  // id="input_customer_name"
                  id="fonting"
                  nextid="input_receivable"
                  tabIndex={tabIndex}
                  value={customer.Customer_Name}
                  onChange={changeSearchCustomerState}
                  // placeholder="Select Customer"
                  aria-activedescenda
                ></Controls.Input>
              </Controls.SearchList>
            </div>
          </Grid>
          <Grid item xl={1} lg={2} md={2} sm={3} xs={6}>
            <Controls.InputDecimalCustom
              // placeholder="0.00"

              currencySymbol=""
              label={
                props.balanceLanguage.Balance.Balance_Form
                  .Input_Balance_Receivable
              }
              //  type="number"
              id="input_receivable"
              nextid="input_payable"
              name="receivable"
              onChange={handleInputChange}
              varient="amount"
              value={balance.receivable}
            />
          </Grid>
          <Grid item xl={1} lg={2} md={2} sm={3} xs={6}>
            <Controls.InputDecimalCustom
              label={
                props.balanceLanguage.Balance.Balance_Form.Input_Balance_Payable
              }
              currencySymbol=""
              name="payable"
              id="input_payable"
              nextid="input_details"
              onChange={handleInputChange}
              type="number"
              varient="amount"
              value={balance.payable}
            ></Controls.InputDecimalCustom>
          </Grid>
          <Grid item xl={5} lg={4} md={4} sm={10} xs={9}>
            <Controls.Input
              label={
                props.balanceLanguage.Balance.Balance_Form.Input_Balance_Detail
              }
              width="100%"
              marginLeft="2rem"
              height="4rem"
              onChange={handleInputChange}
              placeholder="Details"
              name="details"
              id="input_details"
              nextid="button_submit"
              value={balance.details}
              type="input"
            ></Controls.Input>
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={2} xs={3}>
            <Controls.Button
              text={
                balance.transId == null
                  ? props.balanceLanguage.Balance.Balance_Form.Button_Add
                  : props.balanceLanguage.Balance.Balance_Form.Button_Update
              }
              height="4rem"
              id="button_submit"
              nextid="input_Order_Item_Name"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: `Are you sure you want to ${balance.transId == null ? "add" : "update"
                    } this ?`,
                  subTitle: "You can't undo this operation",
                  onConfirm: onAddOrUpdateOrder,
                });
              }}
            ></Controls.Button>
          </Grid>
        </Grid>
        <div
          className={`table-container ${localStorage.getItem("dodaily_urdu") == "true"
            ? "UrduFontTableHead"
            : ""
            }`}
          onScroll={handleScroll}
        >
          {/* <TblContainer> */}
          <div>
            <table className="table table-bordered" id="table-main">
              <TblHead />
              {
                props.BalanceList !== null ? (
                  recordsAfterPagingAndSorting().map(
                    (Banance_List_Balance, i2) => (
                      <tbody>
                        <tr
                          key={i2 + 2}
                          style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <td scope="row" style={{ ...styles, textAlign: "center" }}>
                            {Banance_List_Balance.Customer_Name + " - " + Banance_List_Balance.Customer_Address}
                          </td>
                          <td style={styles}>
                            {Banance_List_Balance.Trans_Detail}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Banance_List_Balance.Debit_Amount
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Banance_List_Balance.Credit_Amount
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "center" }}>
                            <Controls.ActionButton
                              onClick={() => {
                                addAndModifyBalance(Banance_List_Balance);
                              }}
                              colorPrimary="colorPrimary"
                            >
                              <Edit fontSize="small" />
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: `Are you sure you want to delete this?`,
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () =>
                                    onDeleteBalance(Banance_List_Balance.Trans_ID),
                                });
                              }}
                              colorPrimary="colorSecondary"
                            >
                              <DeleteForever fontSize="small" />
                            </Controls.ActionButton>
                          </td>
                        </tr>

                      </tbody>
                    )
                  )
                )
                  :
                  (
                    <Controls.Loader />
                  )}
              {page < props.totalPages ? (
                <Controls.Button
                  id="scroll_Btn"
                  width="100%"
                  height="4rem"
                  text="Load"
                  onClick={() =>
                    page < props.totalPages ? setPage(page + 1) : null
                  }
                ></Controls.Button>
              ) : null
              }
            </table>
            <table
              className="table"
              id="table-main-small">
              {
                props.BalanceList !== null ? (
                  recordsAfterPagingAndSorting().map((Banance_List_Balance, i2) =>
                    <>
                      <tbody key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                        <tr key={i2 + 1}>
                          <td scope="col" id="mobile-style" >Customer: </td>
                          <td scope="col" id="mobile-style">
                            {Banance_List_Balance.Customer_Name}
                          </td>
                        </tr>
                        <tr key={i2 + 1}>
                          <td scope="col" id="mobile-style" >Details: </td>
                          <td scope="col" id="mobile-style">
                            {Banance_List_Balance.Trans_Detail}
                          </td>
                        </tr>
                        <tr key={i2 + 1}>
                          <td scope="col" id="mobile-style" >Receivable: </td>
                          <td scope="col" id="mobile-style">
                            {misc.currencyOnlyDecimal(
                              Banance_List_Balance.Debit_Amount
                            )}
                          </td>
                        </tr>
                        <tr key={i2 + 1}>
                          <td scope="col" id="mobile-style" >Payable: </td>
                          <td scope="col" id="mobile-style">
                            {misc.currencyOnlyDecimal(
                              Banance_List_Balance.Credit_Amount
                            )}
                          </td>
                        </tr>
                        <tr key={i2 + 1}>
                          <td scope="col" id="mobile-style" >Actions: </td>
                          <td scope="col" id="mobile-style">
                            <Controls.ActionButton
                              onClick={() => {
                                addAndModifyBalance(Banance_List_Balance);
                              }}
                              colorPrimary="colorPrimary"
                            >
                              <Edit fontSize="small" />
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: `Are you sure you want to delete this?`,
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () =>
                                    onDeleteBalance(Banance_List_Balance.Trans_ID),
                                });
                              }}
                              colorPrimary="colorSecondary"
                            >
                              <DeleteForever fontSize="small" />
                            </Controls.ActionButton>
                          </td>
                        </tr>
                      </tbody>
                      <hr
                        style={{ margin: 5, padding: 0 }}
                      />
                    </>
                  )
                )
                  :
                  (
                    <Controls.Loader />
                  )
              }
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </table>
            <br />
            <br />
            <br />
            <br />
          </div>
          {/* </TblContainer> */}
          {loading ? <Controls.Loader /> : null}
          {!loading && props.BalanceList.length === 0 ? (
            <Typography
              variant="h4"
              style={{ margin: "auto", display: "block", textAlign: "center" }}
            >
              No Record
            </Typography>
          ) : null}
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  balanceLanguage: state.language.language,
  BalanceList: state.BalanceList.Balance_List,
  totalPages: state.BalanceList.totalPages,
  currentPage: state.BalanceList.currentPage,
  totalBalances: state.BalanceList.totalBalances,
});

const mapDispatchToProps = (dispatch) => ({
  getBalances: (balances, setLoading) => {
    dispatch(getBalances(balances, setLoading));
  },

  emptyBalanceList: () => {
    dispatch(emptyBalanceList());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Balance);
