import React, { useEffect,useState } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";
import misc from "../../fnc/misc"




export default function UserForm(props) {
    const { addOrEdit, recordForEdit } = props

    const [initialFValues,setInitialFValues] =useState({
        User_Name:"",
        User_Password_Normal:"",
        User_Contact_Person:"",
        User_Contact_Number:"",
        User_Email:"",
        User_Shop_Area_ID:"",
        User_Shop_Address:"",
        User_Login_Allowed:new Date(),
        User_Status:""
     })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('User_Name' in fieldValues)
            temp.User_Name = fieldValues.User_Name ? "" : "User Name is not valid."
        if ('User_Password_Normal' in fieldValues)
            temp.User_Password_Normal = fieldValues.User_Password_Normal ? "" : "User Password is not valid."
        if ('User_Status' in fieldValues)
            temp.User_Status = fieldValues.User_Status ? "" : "User Status is not valid."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = () => {
        
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit,User_Login_Allowed: new Date(misc.formatDateyearmonthday(recordForEdit.User_Login_Allowed))
            },console.log(initialFValues))
            
            console.log(recordForEdit)
    }, [recordForEdit])

    const onAddOrUdpateUser = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }
    
    const onResetForm = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       resetForm()
    }

     /// date change //
     const [Allow_Date,set_Allow_Date]=useState(new Date())
     const handleDateChange=(Date)=>{
         set_Allow_Date(Date)
         setInitialFValues({...initialFValues,User_Login_Allowed:misc.formatDateyearmonthday(Date)})
         setValues({...values,User_Login_Allowed: misc.formatDateyearmonthday(Date)})
     }
    return (
        <>
        <Form onSubmit={(e) => {
            e.preventDefault()
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to Submit',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onAddOrUdpateUser() }
                                })
                            }}>
            <Grid spacing={2} container>
                <Grid item xs={12}>
                    <Controls.Input
                        label="User Name"
                        name="User_Name"
                        value={values.User_Name}
                        onChange={handleInputChange}
                        error={errors.User_Name}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <Controls.Input
                        label="Password"
                        name="User_Password_Normal"
                        value={values.User_Password_Normal}
                        onChange={handleInputChange}
                        error={errors.User_Password_Normal}
                    />
                      </Grid>
                    <Grid item xs={12}>
                    <Controls.Input
                        label="Contact Person"
                        name="User_Contact_Person"
                        value={values.User_Contact_Person}
                        onChange={handleInputChange}
                        error={errors.User_Contact_Person}
                    />
                      </Grid>
                    <Grid item xs={12}>
                     <Controls.Input
                        label="Contact Number"
                        name="User_Contact_Number"
                        value={values.User_Contact_Number}
                        onChange={handleInputChange}
                        error={errors.User_Contact_Number}
                    />
                      </Grid>
                    <Grid item xs={12}>
                     <Controls.Input
                        label="Email"
                        name="User_Email"
                        value={values.User_Email}
                        onChange={handleInputChange}
               
                    />
                      </Grid>
                    <Grid item xs={12}>
                     <Controls.Select
                        label="Area"
                        name="User_Shop_Area_ID"
                        value={values.User_Shop_Area_ID}
                        onChange={handleInputChange}
                        options={[{id:10,title:"korangi3"},{id:11,title:"landhi6"}]}
                      
                    />
                      </Grid>
                    <Grid item xs={12}>
                     <Controls.Input
                        label="Address"
                        name="User_Shop_Address"
                        value={values.User_Shop_Address}
                        onChange={handleInputChange}
                
                    />
                      </Grid>
                    <Grid item xs={12}>
                <Controls.Select
                        label="Status"
                        name="User_Status"
                        value={values.User_Status}
                        onChange={handleInputChange}
                        options={[{id:1,title:"Permimum"},{id:2,title:"Trail"},{id:3,title:"block"}]}
                        error={errors.User_Status}
                    />
                      </Grid>
                    <Grid item xs={12}>
                     <Controls.DateImputMaterialUi
                        label="Allow Date"
                        name="User_Login_Allowed"
                        value={values.User_Login_Allowed}
                        onChange={(Allow_Date)=>handleDateChange(Allow_Date)}
                        error={errors.User_Login_Allowed}
                    />
                      </Grid>
                    <Grid item xs={12}>
                        <Controls.Button
                        keyupfire={false}
                            type="submit"
                            text="Submit" />
                </Grid>
            </Grid>
        </Form>
        <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
    />
    </>
    )
}
