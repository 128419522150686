import React, { useState,useRef } from 'react'
import UserForm from "./UserForm";
import PageHeader from "../../components/PageHeader";
import axios from 'axios'
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Box,Grid, IconButton } from '@material-ui/core';
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import {connect} from 'react-redux'
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import FileCopy from '@material-ui/icons/FileCopy';
import Popup from "../../components/Popup";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close'; 
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {GetUsers,EmptyUsers,DeleteUser} from '../../redux/actions/UsersAction'
import jsPDF from 'jspdf';
import misc from '../../fnc/misc'
import 'jspdf-autotable';
const useStyles = makeStyles(theme => ({

    pageContent: {
        padding: theme.spacing(3,3,0,3),
        height: `calc(${window.innerHeight}px - 150px)`,
        [theme.breakpoints.up('md')]:{
            height: `calc(${window.innerHeight}px - 200px)`,
        },
        
        overflow: "hiddin",
        "& .MuiGrid-item > *":{
       
            maxWidth: `calc(100% - 1rem) !important`,
            width: `calc(100% - 1rem) !important`,
            marginRight: `1rem !important`
       },
       /// balance table css
       "& .table-container":{
        height: `calc(${window.innerHeight}px - 250px)`,
        overflow:"auto",
        "& [name=actions]":{
            "& svg":{
                visibility:"hidden"
            }
        },
        "& #scroll_Btn":{
            margin:"auto",display:"none",
            [theme.breakpoints.between("xs","sm")]:{
                display:"block"
            }
        }
    }
    },
    Toolbar:{
    },
    
    ToolbarItems:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        [theme.breakpoints.between("xs","sm")]:{
            
            paddingLeft:"1rem"
        },
    },
    
       
          
    buttonContainerGrid:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.between("xs","sm")]:{
            marginRight:"2rem",
        },
        "& button":{
           
            width:"32.333%",
            [theme.breakpoints.between("md","xl")]:{
                marginRight:".3rem",
            },
        },

 
  },
    newButtonMobile:{
        padding:0,
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"flex"
          },
          [theme.breakpoints.up('md')]: {
            display:"none"
          },
    },
    newButton:{
        height: "4rem",
        margin:0,
        [theme.breakpoints.down('md')]: {
            display:"none"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex"
          },
    }
}))


const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'inherit',
    m: 1,
    padding:0,
    border: 1,
    style: { width: '4rem', height: '4rem' },
    display:"flex",
    margin:"0rem",
    alignItems:"center",
    justifyContent:"center",
    
  };
const headCells = [
    { id: 'User_Name', label: 'User Name' },
    { id: 'User_Password_Normal', label: 'Password' },
    { id: 'User_Contact_Person', label: 'Full Name' },
    { id: 'User_Contact_Number', label: 'Mobile' },
    { id: 'User_Email', label: 'Email' },
    { id: 'User_Shop_Area_Name', label: 'Area' },
    { id: 'User_Shop_Address', label: 'Address' },
    { id: 'User_Type_Name', label: 'Type' },
    { id: 'User_Time_Stamp', label: 'Created At'},
    { id: 'User_Login_Allowed', label: 'Allowed Date'},
    { id: 'User_Status', label: 'Status' },
    { id: 'actions', label: 'Actions', name:'actions' }
]

const Users = (props)=> {

    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: users => { return users; } })
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const componentRef = useRef();
    const [loading, setLoading] = useState(false)
    const [searchItemName,setSearchItemName] =useState(null)
    
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll
    } = useTable(props.users, headCells, filterFn,componentRef,'medium',props.totalPages,props.currentPage,props.totalUsers,props.getAllUsers,props.EmptyUsers,true,setLoading);
    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: users => {
                if (target.value == "")
                    return users;
                else
                    return users.filter(x => `${x.User_Full_Name}${x.User_Name}${x.User_Type_Name}`.toLowerCase().includes(target.value)||`${x.User_Full_Name}${x.User_Name}${x.User_Type_Name}`.toUpperCase().includes(target.value)||  misc.capitalize(`${x.User_Full_Name}${x.User_Name}${x.User_Type_Name}`).includes(target.value))
            }
        })
    }

   
// generte pdf code
const generatePdf = ()=> {
var doc = new jsPDF();
doc.autoTable({
    html: '#table',
    theme:'grid',
    textColor:22
});
doc.save('table.pdf');
};


// generate pdf code
    const insertUser = (user)=>{
        console.log('insert run')
        const headers={
          "x-access-token": sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/adduser',user,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
    props.EmptyUsers()
    setPage(0)
    props.getAllUsers({page:0,limit:rowsPerPage},setLoading)
    }
      }
      )
    }
    
    const updateUser = (user)=>{
        console.log('update run')
        console.log(user)
        const headers={
          "x-access-token": sessionStorage.getItem("accountingauthkey")
      }
     axios.post('/api/updateuser',user,{headers:headers}).then(res=>{
         setNotify({
        isOpen: true,
        message: res.data.message,
        type: res.data.iserror ? 'error':'success'
    })
    if(res.data.iserror === false){
        props.EmptyUsers()
        setPage(0)
        props.getAllUsers({page:0,limit:rowsPerPage},setLoading)
        }
     })
    }
    const addOrEdit = (User, resetForm) => {
  
        if (!User.User_ID){
          
           insertUser(User)
        }
        else if( User.User_ID  ){
            updateUser(User)
        }
        resetForm()
        setRecordForEdit(null)
        setOpenPopup(false)
    }


    const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }
   const deleteUser=(userName,userID)=>{
    const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  console.log('delete run',userName)
 axios.post('/api/deleteuser',{userName:userName},{headers:headers}).then(res=>{
     setNotify({
    isOpen: true,
    message: res.data.message,
    type: res.data.iserror ? 'error':'success'
})
if(res.data.iserror === false){
    props.EmptyUsers()
    setPage(0)
    props.getAllUsers({page:0,limit:rowsPerPage},setLoading)
    }
 })
    }
    const onDelete = (userName,userID) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        deleteUser(userName,userID);
    }
  
    const changeSearchState = (e) => {
        const searchItemNameValue = e.target.value
      if (searchItemNameValue !== '') {
        setSearchItemName(searchItemNameValue)
      } else {
           setSearchItemName(null)
      }
        
    }


    return (
        <>
            <PageHeader 
                title="Users"
                icon={<SupervisedUserCircle color="primary" fontSize="large" />}
            >
                        <Grid container  className={classes.ToolbarItems} lg={8} md={9} sm={12} xs={12} >
                <Grid container lg={5} md={5} sm={6} xs={6}   >
                    <Controls.Input
                        label="Search"
                        width="100%"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    </Grid>
                    <Grid className={classes.buttonContainerGrid}   container lg={7} md={7} sm={4} xs={5} >
                    
                    <IconButton
                        variant="outlined"
                        className={classes.newButtonMobile}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    ><Box borderRadius="50%" {...defaultProps}><AddIcon /></Box></IconButton>
                    <Controls.Button
                        text="Add New"
                        width="100%"
                        height="4rem"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    />
                
                    <IconButton
                        className={classes.newButtonMobile}
                        onClick={generatePdf}
                    ><Box borderRadius="50%" {...defaultProps}><Print /></Box></IconButton>
                    <Controls.Button
                        text="Print"
                        width="100%"
                        height="4rem"
                        startIcon={<Print/>}
                        className={classes.newButton}
                        onClick={generatePdf}
                    />

                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>misc.exportTableToExcel('table')}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    />
                    
                  
                    </Grid>
                </Grid>
            </PageHeader>
            <Paper elevation={7} className={classes.pageContent}>
            <div className="table-container" onScroll={handleScroll}>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {props.users !== null?(
                            recordsAfterPagingAndSorting().map(User_List_User =>
                                (<TableRow key={User_List_User.User_Name} >
                                    <TableCell  data-heading='Customer Name' >{User_List_User.User_Name}</TableCell>
                                    <TableCell  data-heading='Password' >{User_List_User.User_Password_Normal}</TableCell>
                                    <TableCell  data-heading='Full Name' >{User_List_User.User_Contact_Person}</TableCell>
                                    <TableCell  data-heading='Mobile' >{User_List_User.User_Contact_Number}</TableCell>
                                    <TableCell  data-heading='Email' >{User_List_User.User_Email}</TableCell>
                                    <TableCell  data-heading='Type' >{User_List_User.User_Area_Name}</TableCell>
                                    <TableCell  data-heading='Type' >{User_List_User.User_Address}</TableCell>
                                    <TableCell  data-heading='Type' >{User_List_User.User_Type_Name}</TableCell>
                                    <TableCell  data-heading='Type' >{misc.TimeStampFormat( new Date(User_List_User.User_Time_Stamp))}</TableCell>
                                    <TableCell  data-heading='Type' >{User_List_User.User_Login_Allowed}</TableCell>
                                    <TableCell  data-heading='Status' >{User_List_User.User_Status== "1"?"Premimum":User_List_User.User_Status== "2"?"Trail":"Block"}</TableCell>
                                    <TableCell  data-heading='Action' >
                                        { User_List_User.User_Type_ID !== 1?(
                                        <>
                                        <Controls.ActionButton
                                            colorPrimary="colorPrimary"
                                            onClick={() => { openInPopup(User_List_User) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                        colorPrimary="colorSecondary"
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Are you sure to delete ?',
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => { onDelete(User_List_User.User_Name,User_List_User.User_ID) }
                                                })
                                            }}>
                                            <CloseIcon fontSize="small" />
                                        </Controls.ActionButton></>):('')}
                                    </TableCell>
                                </TableRow>)
                            )):(<Controls.Loader/>)
                        }
                    </TableBody>
                    
                </TblContainer>
                {loading?<Controls.Loader/>:null}
                </div>
<table id="table" style={{width:" 100%",borderCollapse: "collapse",border: "1px solid black",display:"none"}} >
  <tr>
    {headCells.map((User_List_User,index)=> User_List_User.label !== "Actions"?<th key={index} style={{border: "1px solid black"}}>{User_List_User.label}</th>:null)}
  </tr>
  {props.users?recordsAfterPagingAndSorting().map((User_List_User,index)=>(
  <tr key={index}>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='User Name' >{User_List_User.User_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Password' >{User_List_User.User_Password_Normal}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Full Name' >{User_List_User.User_Contact_Person}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Mobile' >{User_List_User.User_Contact_Number}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Email' >{User_List_User.User_Email}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Type' >{User_List_User.User_Area_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Type' >{User_List_User.User_Address}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Type' >{User_List_User.User_Type_Name}</td>
    <td style={{border: "1px solid black",color:"black"}}  data-heading='Status' >{User_List_User.User_Status== "0"?"Blocked":User_List_User.User_Status== "1"?"Trail":"Member"}</td>
</tr>)):null}
</table>
            
            </Paper>
            <Popup
                title="User Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <UserForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        
        </>
    )
}
const mapStateToProps=state=>({
    users: state.User.users,
    totalPages:state.User.totalPages,
    currentPage:state.User.currentPage,
    totalUsers:state.User.totalUsers
  })
  const mapDispatchToProps = dispatch=>({
    getAllUsers : (data,setLoading)=>{dispatch(GetUsers(data,setLoading))},
    EmptyUsers:()=>{dispatch(EmptyUsers())},
    DeleteUser:(userID,totalPages,totalUsers)=>{dispatch(DeleteUser(userID,totalPages,totalUsers))}
  })
  export default connect(mapStateToProps,mapDispatchToProps)(Users)