const InitialState = {
    english: {
        main:{
            Common:{
                Yes:"yes",
                No:"No",
                title:"Are you sure to do this?"
            },
            Sidebar:{
                link_Users:"Users",
                link_Customers:"Customers",
                link_Balance:"Balance",
                link_Orders:"Orders",
                link_Daily_Orders:"Daily Orders",
                link_Receipts:"Receipts",
                link_Accounts_Balance:"Account's Balance"

            },
            header:{
                Button_Logout:"Logout",
                Confirm_Logout:'Are you sure to Logout ?'
            }
        },
        //account profile start english
        Account_Profile:{
            title:"Account Profile",
            Input_User_Name:"User Name",
            Input_Shop_Name:"Shop Name",
            Input_Address:"Address",
            Input_Contact_Person:"Contact Person",
            Input_Phone_Number:"Phone Number",
            Button_Update:"Update"
        },
        //account profile end english
        // Users start english
        User:{
            User_Header:{
                SearchInputTitle:"Search",
                Add_Button:"Add New",
                Print_Button:"Print",
                Excel_Button:"Excel",
                Title:"User"
            },
            User_Table:{
                User_Table_Head: [
                    { id: 'User_Name', label: 'User Name' },
                    { id: 'User_Password_Normal', label: 'Password' },
                    { id: 'User_Contact_Person', label: 'Full Name' },
                    { id: 'User_Contact_Number', label: 'Mobile' },
                    { id: 'User_Email', label: 'Email' },
                    { id: 'User_Shop_Area_Name', label: 'Area' },
                    { id: 'User_Shop_Address', label: 'Address' },
                    { id: 'User_Type_Name', label: 'Type' },
                    { id: 'User_Time_Stamp', label: 'Created At'},
                    { id: 'User_Login_Allowed', label: 'Allowed Date'},
                    { id: 'User_Status', label: 'Status' },
                    { id: 'actions', label: 'Actions', name:'actions' }
                ]
                
            },
            User_Form:{
                Title:"User",
                Input_User_Name:"User Name",
                Input_User_Password:"Password",
                Input_User_Contact_Person:"Contact Person",
                Input_User_Address:"Address",
                Label_User_Contact_Number:"Contact Number",
                Label_User_Email:"Email",
                Label_User_Rate:"Rate",
                Input_User_Area:"Area",
                Input_User_Status:"Status",
                Button_Update:"Update",
                Button_Add:"Add"
            },
            User_Bill_Form:{
                title:"Picker a Month",
                Print_Button:"Print"
            }

        },
        //Users end englist
        Customer:{
            Customer_Print:{
                Print_Button:"Print",
                Customer_Button:"Customers"
            },
            Customer_Header:{
                SearchInputTitle:"Search",
                Add_Button:"Add New",
                Print_Button:"Print",
                Title:"Customer"
            },
            Customer_Table:{
                Customer_Table_Head: [
                    { id: 'Customer_Name', label: 'Customer',name:"name" },
                    { id: 'Customer_Mobile', label: 'Mobile',name:"mobile" },
                    { id: 'Customer_Morning_Milk_QTY', label: 'Morning Milk', name:"qty" },
                    { id: 'Customer_Morning_yog_QTY', label: 'Morning yog', name:"qty" },
                    { id: 'Customer_Morning_Ghee_QTY', label: 'Morning Others', name:"qty" },
                    { id: 'Customer_Evening_Milk_QTY', label: 'Evening Milk', name:"qty" },
                    { id: 'Customer_Evening_yog_QTY', label: 'Evening yog', name:"qty" },
                    { id: 'Customer_Evening_Ghee_QTY', label: 'Evening Others', name:"qty" },
                    { id: 'Customer_Milk_Rate', label: 'Milk Rate', name:"rate" },
                    { id: 'Customer_Yog_Rate', label: 'Yog Rate', name:"rate" },
                    { id: 'Customer_Ghee_Rate', label: 'Others Rate', name:"rate" },
                    { id: 'Customer_Active', label: 'Active',name:"active" },
                    { id: 'actions', label: 'Actions',name:"actions" }
                ]
                
            },
            Customer_Form:{
                Title:"Customer",
                Input_Customer_Name:"Customer Name",
                Input_Customer_Urdu_Name:"Customer Name Urdu",
                Input_Customer_Mobile:"Mobile Number",
                Input_Customer_Sort_Number:"Sort Number",
                Input_Customer_Status:"Active",
                Input_Customer_Address:"Address",
                Label_Customer_Morning_Qty:"Morning_Qty",
                Label_Customer_Evening_Qty:"Evening_Qty",
                Label_Customer_Rate:"Rate",
                Input_Customer_Milk:"Milk",
                Input_Customer_Yog:"Yog",
                Input_Customer_Others:"Others",
                Input_Customer_Payable:"Payable",
                Input_Customer_Receivable:"Receiveable",
                Input_Customer_Detail:"Balance Detail",
                Button_Update:"Update",
                Button_Add:"Add"
            },
            Customer_Bill_Form:{
                title:"Picker a Month",
                Print_Button:"Print"
            }

        },
        Balance:{
            Balance_Header:{
                SearchInputTitle:"Search",
                Title:"Balance"
            },
            Balance_Table:{
                Balance_Table_Head: [
                    { id: 'Customer_Name', label: 'Customer',disableSorting:true,name:"name" },
                    { id: 'Trans_Detail', label: 'Details',disableSorting:true,name:"detail"  },
                    { id: 'Debit_Amount', label: 'Receivable',disableSorting:true,name:"amount" },
                    { id: 'Credit_Amount', label: 'Payable',disableSorting:true,name:"amount" },
                    { id: 'actions', label: 'Actions',disableSorting:true,name:"actions"}
                ]
                
            },
            Balance_Form:{
                Input_Balance_Name:"Balance Name",
                Input_Balance_Payable:"Payable",
                Input_Balance_Receivable:"Receiveable",
                Input_Balance_Detail:"Detail",
                Button_Update:"Update",
                Button_Add:"Add"
            }

        },
        Order:{
            Order_Header:{
                SearchInputTitle:"Search",
                Add_Button:"Add New",
                Print_Button:"Print",
                Title:"Order"
            },
            Order_Table:{
                Order_Table_Head: [
                    { id: 'OrderDate', label: 'Date',name:"date" },
                    { id: '', label: 'Event',name:"event" },
                    { id: '', label: 'Bills',name:"bills" },
                    { id: '', label: 'Milk QTY',name:"amount" },
                    { id: '', label: 'Amount',name:"amount"  },
                    { id: '', label: 'Yog QTY',name:"amount" },
                    { id: '', label: 'Amount',name:"amount"  },
                    { id: '', label: 'Others QTY',name:"amount" },
                    { id: '', label: 'Amount',name:"amount"  },
                    { id: '', label: 'Total Amount',name:"amount"  },
                    { id: '', label: 'Actions', name:'actions' }
                ]
                
            },
            Add_Order:{
                Title:"Order",
                Input_Order_Date:"Date",
                Input_Order_Delivery_Time:"Delivery Time",
                Input_Order_Milk_Qty:"Milk Qty",
                Input_Order_Yog_Qty:"Yog Qty",
                Input_Order_Others_Qty:"Others Qty",
                Input_Order_Amount:"Milk Qty",
                Button_Update:"Update",
                Button_Delete_All:"Delete All",
                Button_Save_Close:"Save/Close",
                Add_Order_Table_Head:[
                    { id: 'actions', label: 'Actions',name:"actions",disableSorting:true},
                    { id: 'Customer_Name', label: 'Customer',disableSorting:true, },
                    { id: 'Trans_Milk_Qty', label: 'Milk Qty',name:"amount" ,disableSorting:true, },
                    { id: 'Trans_Milk_Rate', label: 'Milk Rate',name:"amount",disableSorting:true, },
                    { id: 'Trans_Yog_Qty', label: 'Yog Qty',name:"amount" ,disableSorting:true, },
                    { id: 'Trans_Yog_Rate', label: 'Yog Rate',name:"amount",disableSorting:true, },
                    { id: 'Trans_Others_Qty', label: 'Others Qty',name:"amount" ,disableSorting:true, },
                    { id: 'Trans_Others_Rate', label: 'Others Rate',name:"amount",disableSorting:true, },
                    { id: 'Debit_Amount', label: 'Amount',name:"amount",disableSorting:true, },
                ],
                Input_Order_Name:"Customer Name",
                Input_Order_Milk_Qty:" Milk Qty",
                Input_Order_Yog_Qty:" Yog Qty",
                Input_Order_Others_Rate:" Others Rate",
                Input_Order_Milk_Rate:" Milk Rate",
                Input_Order_Yog_Rate:" Yog Rate",
                Input_Order_Others_Rate:" Others Rate",
                Input_Order_Detail:"Detail"
            }

        },
        Daily_Orders:{
            Daily_Orders_Header:{
                Input_From:"From",
                Input_To:"To",
                Input_Customer_Name:"Customer_Name",
                Input_Delivery_Time:"Delivery Time",
                Button_Filter:"Apply Filter",
                Button_Clear_Filter:"Clear_Filter"

            },
            Daily_Orders_Table:{
                Daily_Orders_Table_Head: [
                    { id: '', label: 'Actions',name:"actions" },
                    { id: 'Trans_Event_ID', label: 'Event',name:"event" },
                    { id: 'Trans_Date', label: 'Date',name:"date" },
                    { id: 'Customer_Name', label: 'Customer',name:"name" },
                    { id: 'Trans_Milk_Qty', label: 'Milk QTY',name:"qty" },
                    { id: 'Trans_Milk_Rate', label: 'Milk Rate',name:"rate" },
                    { id: 'Trans_Milk_Amount', label: 'Milk Amount',name:"amount" },
                    { id: 'Trans_Yog_Qty', label: 'Yog QTY',name:"qty" },
                    { id: 'Trans_Yog_Rat,', label: 'Yog Rate',name:"rate" },
                    { id: 'Trans_Yog_Amount', label: 'Yog Amount',name:"amount" },
                    { id: 'Trans_Ghee_Qty', label: 'Other QTY',name:"qty" },
                    { id: 'Trans_Ghee_Rate', label: 'Other Rate',name:"rate" },
                    { id: 'Trans_Ghee_Amount', label: 'Other Amount',name:"amount" },
                    { id: 'Trans_Detail', label: 'Detail',name:"detail" },
                    { id: 'Debit_Amount', label: 'Total Amount',name:"totalamount" },
                ]
                
            },
            Daily_Orders_Form:{
                Title:"Order",
                Input_Customer_Name:"Customer_Name",
                Input_Detail:"Detail",
                Label_Quantity:"Quantity",
                Label_Price:"Price",
                Input_Milk:"Milk",
                Input_Yog:'Yog',
                Input_Others:"Others"
            }
        },

    // Receipts english start
    Receipts:{
        Receipts_Header:{
            SearchInputTitle:"Search",
            Add_Button:"Add New",
            Print_Button:"Print",
            Excel_Button:"Excel",
            Title:"Receipts"
        },
        Receipts_Table:{
            Receipts_Table_Head: [
                { id: 'Trans_Date', label: 'Date',name:"date" },
                { id: 'Customer_Name', label: 'Customer', },
                { id: 'Trans_Detail', label: 'Detail' },
                { id: 'Credit_Amount', label: 'Amount',name:"amount" },
                { id: 'actions', label: 'Actions', name:'actions' }
            ]
            
        },
        Receipts_Form:{
            title:"Receipts",
            Input_Customer_Name:"Customer_Name",
            Input_Date:"Date",
            Input_Amount:"Sort Number",
            Input_Detail:"Detail",
            Button_Update:"Update",
            Button_Save:"SAVE",
            Button_Reset:"Reset"
        }

    },
    // Receipts english end

    // Account Balance englist start

    Account_Balance:{
        Account_Balance_Header:{
            SearchInputTitle:"Search",
            Title:"Account's Balance",
            Print_Button:"Print",

        },
        Account_Balance_Table:{
            Account_Balance_Table_Head: [
                { id: 'Customer_Name', label: 'Customer Name',name:"name" },
                { id: 'Customer_Mobile', label: 'Customer Mobile',name:"mobile" },
                { id: 'Debit', label: 'Bills',name:"amount" },
                { id: 'Credit', label: 'Receipts',name:"amount" },
                { id: 'Balance', label: 'Balance',name:"amount" },
                { id: 'actions', label: 'Actions', name:'actions' }
            ]
            
        },
        Account_Balance_Form:{
            Title:"Receipts",
            Input_Cutomer_Name:"Customer_Name",
            Input_Date:"Date",
            Input_Amount:"Amount",
            Input_Detail:"Detail",
            Button_Update:"Update",
            Button_Save:"SAVE",
            Button_Reset:"Reset"

        }

    },
     // Account Balance englist end

    },
    urdu: {
        main:{
            Common:{
                Yes:"ہاں",
                No:"نہیں",
                title:"کیا آپ یہ کرنا  ہے؟"
            },
            Sidebar:{
                link_Users:"صارف",
                link_Customers:"گاهک",
                link_Balance:"بقیہ رقم",
                link_Orders:"آرڈر",
                link_Daily_Orders:"آرڈر ڈیلی",
                link_Receipts:"رسیدیں",
                link_Accounts_Balance:"اکاؤنٹ کا بقیہ رقم"
            },
            header:{
                Button_Logout:"باہر جاءیے",
                Confirm_Logout:"کیا آپ باہر نکلنا چاہتے ہیں ؟"
            }
        },
         //account profile start urdu
         Account_Profile:{
            title:"اکاؤنٹ پروفائل",
            Input_User_Name:"صارف کا نام",
            Input_Shop_Name:"دکان کا نام",
            Input_Address:"پتہ",
            Input_Contact_Person:"رابطے کا بندہ",
            Input_Phone_Number:"فون نمبر",
            Button_Update:"اپ ڈیٹ کریں"
        },
        //account profile end urdu
        Customer:{
            Customer_Print:{
                Print_Button:"پرنٹ کریں",
                Customer_Button:"گاهک"
            },
            Customer_Header:{
                SearchInputTitle:"تلاش کریں",
                Add_Button:"نیا گاهک",
                Print_Button:"پرنٹ کریں",
                Title:"گاهک"
            },
            Customer_Table:{
                
                Customer_Table_Head: [
                    { id: 'actions', label: 'اعمال',name:"actions" },
                    { id: 'Customer_Active', label: 'فعال',name:"active" },
                    { id: 'Customer_Ghee_Rate', label: 'دیگر کی قیمت', name:"rate" },
                    { id: 'Customer_Yog_Rate', label: 'دہی کی قیمت', name:"rate" },
                    { id: 'Customer_Milk_Rate', label: 'دودھ کی قیمت', name:"rate" },
                    { id: 'Customer_Evening_Ghee_QTY', label: 'شام دیگر', name:"qty" },
                    { id: 'Customer_Evening_yog_QTY', label: 'شام کا دہی', name:"qty" },
                    { id: 'Customer_Evening_Milk_QTY', label: 'شام کا دودھ', name:"qty" },
                    { id: 'Customer_Morning_Ghee_QTY', label: 'صبح دیگر', name:"qty" },
                    { id: 'Customer_Morning_yog_QTY', label: 'صبح کا دہی', name:"qty" },
                    { id: 'Customer_Morning_Milk_QTY', label: 'صبح کا دودھ', name:"qty" },
                    { id: 'Customer_Mobile', label: 'موبائل نمبر',name:"mobile" },
                    { id: 'Customer_Name', label: "گاہک کا نام",name:"name" }
                ]
                
            },
            Customer_Form:{
                Title:"گاهک",
                Input_Customer_Name:"گاہک کا نام",
                Input_Customer_Urdu_Name:"کسٹمر کا نام اردو",
                Input_Customer_Mobile:"موبائل نمبر",
                Input_Customer_Status:"فعال",
                Input_Customer_Sort_Number:"نمبر ترتیب دیں",
                Input_Customer_Address:"پتہ",
                Label_Customer_Morning_Qty:"صبح کی مقدار",
                Label_Customer_Evening_Qty:"شام کی مقدار",
                Label_Customer_Rate:"بهاؤ",
                Input_Customer_Milk:"دودھ",
                Input_Customer_Yog:"دہی",
                Input_Customer_Others:"دیگر",
                Input_Customer_Payable:"قابل ادائیگی",
                Input_Customer_Receivable:"قابل وصول",
                Input_Customer_Detail:"بیلنس کی تفصیل",
                Button_Update:"اپ ڈیٹ کریں",
                Button_Add:"شامل کریں"
            },
            Customer_Bill_Form:{
                title:"مہینا منتخب کریں",
                Print_Button:"پرنٹ کریں"
            }
        },
        Balance:{
            Balance_Header:{
                SearchInputTitle:"تلاش کریں",
                Title:"بقیہ رقم"
            },
            Balance_Table:{
                Balance_Table_Head: [
                    { id: 'Customer_Name', label: 'گاہک',disableSorting:true,name:"name" },
                    { id: 'Trans_Detail', label: "بیلنس کی تفصیل",disableSorting:true,name:"detail"  },
                    { id: 'Debit_Amount', label: "قابل وصول",disableSorting:true,name:"amount" },
                    { id: 'Credit_Amount', label: "قابل ادائیگی",disableSorting:true,name:"amount" },
                    { id: 'actions', label: 'اعمال',disableSorting:true,name:"actions"}
                ]
                
            },
            Balance_Form:{
                Input_Balance_Name:"گاہک",
                Input_Balance_Payable:"قابل ادائیگی",
                Input_Balance_Receivable:"قابل وصول",
                Input_Balance_Detail:"بقیہ رقم کی تفصیل",
                Button_Update:"اپ ڈیٹ کریں",
                Button_Add:"شامل کریں"
            }

        },
        Order:{
            Order_Header:{
                SearchInputTitle:"تلاش کریں",
                Add_Button:"نیا آرڈر",
                Print_Button:"پرنٹ کریں",
                Title:"آرڈر"
            },
            Order_Table:{
                Order_Table_Head: [
                    { id: 'OrderDate', label: 'تاریخ',name:"date" },
                    { id: '', label: 'تقریب',name:"event" },
                    { id: '', label: 'بلز',name:"bills" },
                    { id: '', label: 'دودھ کی مقدار',name:"amount" },
                    { id: '', label: 'رقم',name:"amount"  },
                    { id: '', label: 'دہی کی مقدار',name:"amount" },
                    { id: '', label: 'رقم',name:"amount"  },
                    { id: '', label: "دیگر کی مقدار",name:"amount" },
                    { id: '', label: 'رقم',name:"amount"  },
                    { id: '', label: 'کل رقم',name:"amount"  },
                    { id: '', label: 'اعمال', name:'actions' }
                ]
                
            },
            Add_Order:{
                Title:"نیا آرڈر",
                Input_Order_Date:"تاریخ",
                Input_Order_Delivery_Time:"ڈلوری کا وقت",
                Input_Order_Milk_Qty:'دودھ کی مقدار',
                Input_Order_Yog_Qty:'دہی کی مقدار',
                Input_Order_Others_Qty:"دیگر کی مقدار",
                Input_Order_Amount:"رقم",
                Button_Update:"اپ ڈیٹ کریں",
                Button_Delete_All:"تمام حذف کریں",
                Button_Save_Close:"محفوظ/بند کریں",
                Add_Order_Table_Head:[
                    { id: 'actions', label: 'اعمال',name:"actions",disableSorting:true},
                    { id: 'Customer_Name', label: 'گاہک',disableSorting:true, },
                    { id: 'Trans_Milk_Qty', label:  'دودھ کی مقدار',name:"amount" ,disableSorting:true, },
                    { id: 'Trans_Milk_Rate', label: 'دودھ کی قیمت',name:"amount",disableSorting:true, },
                    { id: 'Trans_Yog_Qty', label: 'دہی کی مقدار',name:"amount" ,disableSorting:true, },
                    { id: 'Trans_Yog_Rate', label: 'دہی کی قیمت',name:"amount",disableSorting:true, },
                    { id: 'Trans_Others_Qty', label: "دیگر کی مقدار",name:"amount" ,disableSorting:true, },
                    { id: 'Trans_Others_Rate', label: 'دیگر کی قیمت',name:"amount",disableSorting:true, },
                    { id: 'Debit_Amount', label: 'رقم',name:"amount",disableSorting:true, },
                ],
                Input_Order_Customer_Name:"گاہک کا نام",
                Input_Order_Milk_Qty:'دودھ کی مقدار',
                Input_Order_Yog_Qty:'دہی کی مقدار',
                Input_Order_Others_Rate:"دیگر کی مقدار",
                Input_Order_Milk_Rate:'دودھ کی قیمت',
                Input_Order_Yog_Rate:'دہی کی قیمت',
                Input_Order_Others_Rate:'دیگر کی قیمت',
                Input_Order_Detail:"تفصیل",
                Button_Add:"شامل کریں"
            }

        },
        Daily_Orders:{
            Daily_Orders_Header:{
                Input_From:"سے",
                Input_To:"تک",
                Input_Customer_Name:"گاہک کا نام",
                Input_Delivery_Time:"ڈلیوری ٹائم",
                Button_Filter:"فلٹر لگائیں",
                Button_Clear_Filter:"فلٹر ہٹائیں"

            },
            Daily_Orders_Table:{
                Daily_Orders_Table_Head: [
                    { id: '', label: 'اعمال',name:"actions" },
                    { id: 'Trans_Event_ID', label: 'تقریب',name:"event" },
                    { id: 'Trans_Date', label: 'تاریخ',name:"date" },
                    { id: 'Customer_Name', label: 'گاهک',name:"name" },
                    { id: 'Trans_Milk_Qty', label: 'دودھ کی مقدار',name:"qty" },
                    { id: 'Trans_Milk_Rate', label: 'دودھ کی قیمت',name:"rate" },
                    { id: 'Trans_Milk_Amount', label: 'دودھ کی رقم',name:"amount" },
                    { id: 'Trans_Yog_Qty', label: "دہی کی مقدار",name:"qty" },
                    { id: 'Trans_Yog_Rat,', label: 'دہی کی قیمت',name:"rate" },
                    { id: 'Trans_Yog_Amount', label: 'دہی کی رقم',name:"amount" },
                    { id: 'Trans_Ghee_Qty', label: "دیگر کی مقدار",name:"qty" },
                    { id: 'Trans_Ghee_Rate', label: 'دیگر کی قیمت',name:"rate" },
                    { id: 'Trans_Ghee_Amount', label: 'دیگر کی رقم',name:"amount" },
                    { id: 'Trans_Detail', label: "تفصیل",name:"detail" },
                    { id: 'Debit_Amount', label: 'کل رقم',name:"totalamount" },
                ]
                
            },
            Daily_Orders_Form:{
                Title:"آرڈر",
                Input_Customer_Name:"گاہک کا نام",
                Input_Detail:"تفصیل",
                Label_Quantity:"مقدار",
                Label_Price:"قیمت",
                Input_Milk:"دودھ",
                Input_Yog:'دہی',
                Input_Others:"دیگر",
                Add_Button:"محفوظ کریں"
            }
           
        },

            // Receipts urdu start
    Receipts:{
        Receipts_Header:{
            SearchInputTitle:"تلاش کریں",
            Add_Button:"نئی رسید",
            Print_Button:"پرنٹ کریں",
            Excel_Button:"ایکسل",
            Title:"رسیدیں"
        },
        Receipts_Table:{
            Receipts_Table_Head: [
                { id: 'Trans_Date', label: 'تاریخ',name:"date" },
                { id: 'Customer_Name', label: 'گاهک', },
                { id: 'Trans_Detail', label: 'تفصیل' },
                { id: 'Credit_Amount', label: 'رقم',name:"amount" },
                { id: 'actions', label: 'اعمال', name:'actions' }
            ]
            
        },
        Receipts_Form:{
            title:"رسید",
            Input_Customer_Name:"گاہک کا نام",
            Input_Date:"تاریخ",
            Input_Amount:"رقم",
            Input_Detail:"تفصیل",
            Button_Update:"اپ ڈیٹ کریں",
            Button_Save:"محفوظ کریں",
            Button_Reset:"ری سیٹ کریں"
        },
       

    },
    // Receipts urdu end

    
    // Account Balance urdu start

    Account_Balance:{
        Account_Balance_Header:{
            SearchInputTitle:"تلاش کریں",
            Print_Button:"پرنٹ کریں",
            Title:"اکاؤنٹ کا بیلنس",

        },
        Account_Balance_Table:{
            Account_Balance_Table_Head: [
                { id: 'Customer_Name', label: "گاہک کا نام",name:"name" },
                { id: 'Customer_Mobile', label: 'موبائل نمبر',name:"mobile" },
                { id: 'Debit', label: 'بلز',name:"amount" },
                { id: 'Credit', label: 'رسیدیں',name:"amount" },
                { id: 'Balance', label: 'بقیہ رقم',name:"amount" },
                { id: 'actions', label: 'اعمال', name:'actions' }
            ]
        },
        Account_Balance_Form:{
            Title:"رسید",
            Input_Cutomer_Name:"گاہک کا نام",
            Input_Date:"تاریخ",
            Input_Amount:"رقم",
            Input_Detail:"تفصیل",
            Button_Update:"اپ ڈیٹ کریں",
            Button_Save:"محفوظ کریں",
        }

    },
     // Account Balance urdu end
    },
    language: [],
}

const language = (state = InitialState, Action) => {
    switch (Action.type) {
        case 'ENGLISH':
            return {
                ...state,
                language: state.english,
            }
            break
        case 'URDU':
            return {
                ...state,
                language: state.urdu,
            }
            break
        default:
            return {
                ...state,
                language: localStorage.getItem("dodaily_urdu") == "true"?state.urdu:state.english,
            }
            break
    }
}
export default language
