import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HashLoader from "react-spinners/HashLoader";


const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(0, 0, 0, 0.4)",
    left: 0,
    top: 0,
    position:"absolute",
    width:"100vw",
    height:"100vh",
    display: 'flex',
    alignItems:"center",
    justifyContent:"center",
    width:"100%",
  },
  
}));

const FullScreenLoader=()=> {
  const classes = useStyles();

  return <div className={classes.root} ><HashLoader size="40" color='#421f10'  /></div>
}
export default  FullScreenLoader