import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
//components
import { changeLanguage } from "../../redux/actions/languageAction";
const geturdu = localStorage.getItem("dodaily_urdu");
const Toggle = ({ setLanguage }) => {
  const history = useHistory();
  let path = history.location.pathname;
  const [urdu, setUrdu] = useState(localStorage.getItem("dodaily_urdu"));
  useEffect(() => {
    localStorage.getItem("dodaily_urdu")
      ? localStorage.getItem("dodaily_urdu")
      : localStorage.setItem("dodaily_urdu", "false");
    if (urdu == "true") {
      $(".toggle_thumb").addClass("thumb-active");
      setLanguage("URDU");
    } else if (urdu == "false") {
      $(".toggle_thumb").removeClass("thumb-active");
      setLanguage("ENGLISH");
    }
  }, [urdu]);
  const toggleUrdu = async () => {
    if (localStorage.getItem("dodaily_urdu") == "true") {
      localStorage.setItem("dodaily_urdu", "false");
      await setUrdu(localStorage.getItem("dodaily_urdu"));
    } else if (localStorage.getItem("dodaily_urdu") == "false") {
      localStorage.setItem("dodaily_urdu", "true");
      await setUrdu(localStorage.getItem("dodaily_urdu"));
    }
  };
  return (
    <div className="toggle" onClick={toggleUrdu}>
      <div className="toggle_track">
        <div className="toggle_track_check">
          <span className="toggle_BsTx">ENG</span>
        </div>
        <div className="toggle_track_x">
          <span className="toggle_BsTx">URDU</span>
        </div>
      </div>
      <div className="toggle_thumb"></div>
      <input
        aria-label="Dark mode toggle"
        className="toggle_screenreader-only"
        type="checkbox"
      ></input>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => {
    dispatch(changeLanguage(language));
  },
});

export default connect(null, mapDispatchToProps)(Toggle);
