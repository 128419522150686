import React, { useState, memo, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import "../Customers/pages.css"
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  Grid,
  IconButton,
  Box,
  Typography,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { connect } from "react-redux";
import Popup from "../../components/Popup";
import axios from "axios";
import {
  EditOutlined,
  Delete,
  Print,
  Search,
  Receipt,
  Add,
  FileCopy,
  ViewList,
} from "@material-ui/icons";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import {
  GetOrders,
  emptyOrderList,
  DeleteOrderFromReduxState,
} from "../../redux/actions/ordersListAction";
import { setItems } from "../../redux/actions/orderItemsAction";
import misc from "../../fnc/misc";
import jsPDF from "jspdf";

const innerHeight = window.innerHeight;
const innerWidth = window.innerWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    "& .pageHeaderPageInfo": {
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
  },
  pageContent: {
    padding: "1rem 1rem 1rem 1rem",
    height: `calc(100vh - 18rem)`,
    [theme.breakpoints.down("md")]: {
      height: `calc(100vh - 18rem)`,
    },
    [theme.breakpoints.down("sm")]: {
      height: `calc(100vh - 23rem)`,
    },
    overflow: "hiddin",
    "& .MuiGrid-item > *": {
      maxWidth: `calc(100% - 1rem) !important`,
      width: `calc(100% - 1rem) !important`,
      marginRight: `1rem !important`,
    },
    "& .table-container": {
      height: `100%`,
      overflow: "auto",
      "& thead": {
        "&  tr": {
          "& [name=date]": {
            ninWidth: "10rem !important",
          },
          "& [name=bills]": {
            ninWidth: "3rem !important",
          },
          "& [name=event]": {
            ninWidth: "5rem !important",
          },
          "& [name=amount]": {
            ninWidth: "min-content !important",
          },
          "& [name=actions]": {
            ninWidth: "10% !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=date]": {
              ninWidth: "auto !important",
            },
            "& [name=bill]": {
              ninWidth: "auto !important",
            },
            "& [name=event]": {
              ninWidth: "auto !important",
            },
            "& [name=amount]": {
              ninWidth: "auto !important",
            },
            "& [name=actions]": {
              ninWidth: "auto !important",
            },
          },
        },
      },
      "& tbody": {
        "&  tr": {
          "& td": {
            [theme.breakpoints.down(900)]: {
              paddingLeft: "20rem",
            },
            [theme.breakpoints.down(539)]: {
              paddingLeft: "15rem",
            },
          },
          "& [name=date]": {
            ninWidth: "10rem !important",
          },
          "& [name=bills]": {
            ninWidth: "3rem !important",
          },
          "& [name=event]": {
            ninWidth: "5rem !important",
          },
          "& [name=amount]": {
            ninWidth: "min-content !important",
          },
          "& [name=actions]": {
            ninWidth: "10% !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=date]": {
              ninWidth: "auto !important",
            },
            "& [name=bills]": {
              ninWidth: "auto !important",
            },
            "& [name=event]": {
              ninWidth: "auto !important",
            },
            "& [name=amount]": {
              ninWidth: "auto !important",
            },
            "& [name=actions]": {
              ninWidth: "auto !important",
            },
          },
        },
      },
      "& #scroll_Btn": {
        margin: "auto",
        display: "none",
        [theme.breakpoints.between("xs", "sm")]: {
          display: "block",
        },
      },
    },
  },
  ToolbarItems: {
    display: "flex",
    alignItems: "center",
  },
  //     buttonContainerGrid:{
  //         display:"flex",
  //         alignItems:"center",
  //         justifyContent:"flex-end",
  //         [theme.breakpoints.between("xs","sm")]:{
  //             marginRight:"2rem",
  //         },
  //         "& button":{

  //             width:"32.333%",
  //             [theme.breakpoints.between("md","xl")]:{
  //                 marginRight:".3rem",
  //             },
  //         },

  //   },
  newButtonMobile: {
    padding: 0,
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  newButton: {
    height: "4rem",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  ActionBtnDiv: {
    [theme.breakpoints.down(900)]: {
      // display:"flex !important",
      // flexDirection:"column"
      "& > *": {
        width: "4rem",
        height: "3rem",
        padding: ".5rem",
      },
      "& svg": {
        fontSize: "1.6rem",
      },
    },
  },
  grid6: {
    display: "flex",
    justifyContent: "space-between",
    width: "15rem",
    margin: ".8rem .0rem 15rem .8rem",
  },
}));

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "inherit",
  m: 1,
  padding: 0,
  border: 1,
  style: { width: "4rem", height: "4rem" },
  display: "flex",
  margin: "0rem",
  alignItems: "center",
  justifyContent: "center",
};

const ViewOrders = (props) => {
  let headCells = props.viewOrderLanguage.Order.Order_Table.Order_Table_Head;

  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openPopupBill, setOpenPopupBill] = useState(false);
  const [customer, setCustomer] = useState({
    customerId: null,
    customerName: "",
  });
  const [searchCustomerName, setSearchCustomerName] = useState(null);
  const [customer_List, set_Customer_List] = useState([]);

  const getOrders = (data) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios.post("/api/getorders", data, { headers: headers }).then((res) => {
      props.GetOrders(res.data);
      setLoading(false);
      if (res.data.iserror) {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
      }
    });
  };
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    rowsPerPage,
    page,
    setPage,
    handleScroll,
    handleSortRequest,
  } = useTable(
    props.Order_List,
    headCells,
    filterFn,
    null,
    "medium",
    props.totalPages,
    props.currentPage,
    props.totalOrders,
    getOrders,
    props.emptyOrderList,
    true,
    setLoading
  );

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (orders) => {
        if (target.value == "") return orders;

        return orders.filter(
          (x) =>
            misc
              .formatDate(x.OrderDate)
              .toString()
              .toLowerCase()
              .includes(target.value) ||
            misc
              .formatDate(x.OrderDate)
              .toString()
              .toUpperCase()
              .includes(target.value) ||
            misc
              .capitalize(misc.formatDate(x.OrderDate).toString())
              .includes(target.value)
        );
      },
    });
  };
  // Print Functionality  start

  // Bill Print code start
  const [receipts, setReceipts] = useState(null); // recipts data for table maping
  const [receiptCustomer, setReceiptCustomer] = useState({}); // customer data of which receipt is printing

  const [Start_Date, Set_Start_Date] = useState(new Date()); // initial date for further programming in receipt
  const [End_Date, Set_End_Date] = useState(new Date()); // end date for further programming in receipt

  // generatering dates from 1 to end of month dinamically function start
  const update_dates = (receipts, Balance, Receipts) => {
    let dates = [];
    let s_date = new Date(Start_Date.getFullYear(), Start_Date.getMonth(), 0); // 1 date of month
    const e_date = new Date(End_Date.getFullYear(), End_Date.getMonth() + 1, 0); // end date of month

    for (let i = s_date; i < e_date;) {
      s_date.setDate(s_date.getDate() + 1);
      dates.push(misc.formatDateyearmonthday(s_date));
    }
    // console.log("update_dates" + Balance);
    mergeArrays(dates, receipts, Balance, Receipts);
  };
  // generatering dates from 1 to end of month dinamically function end

  // merge dates and receipts arrays based on date match function start
  const mergeArrays = async (arr1 = [], arr2 = [], Balance, Receipts) => {
    // console.log("mergeArrays" + Balance);
    let res = [];
    res = arr1.map((date) => {
      const index = arr2.findIndex(
        (el) => misc.formatDateyearmonthday(el.Trans_Date) == date
      );
      const item = index !== -1 ? arr2[index] : {};
      return {
        date,
        ...item,
      };
    });
    await setReceipts([
      res,
      {
        T_Milk: arr2.reduce((a, b) => a + (b.Milk || 0), 0),
        T_Yog: arr2.reduce((a, b) => a + (b.Yog || 0), 0),
        T_Others: arr2.reduce((a, b) => a + (b.Ghee || 0), 0),
        T_Amount: arr2.reduce((a, b) => a + (b.Amount || 0), 0),
        Balance: Balance != null ? Balance : 0,
        Receipts: Receipts != null ? Receipts : 0,
      },
    ]);
    PrintReceipt();
  };
  // merge dates and receipts arrays based on date match function end

  //  fetch receipt data function start
  const getMonthlyReceipt = (customer_ID, customer) => {
    // console.log("get receipt data");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/getmonthlyreceipt", { customer_ID: customer_ID }, { headers })
      .then(async (res) => {
        if (res.data.records.length !== 0) {
          await setReceiptCustomer(customer);
          update_dates(
            res.data.records,
            res.data.PrevBalance.Balance,
            res.data.Receipts.Receipts
          );
        } else {
          setNotify({
            isOpen: true,
            message: "Empty Receipt Record For this Customer",
            type: "error",
          });
        }
      })
      .catch((err) => {
        // console.log("ERROR: ====", err);
      });
  };

  let arrayItems;
  arrayItems =
    receipts != null
      ? receipts[0].map((receipt) => {
        let html = (
          <tr>
            {/* receipt.Event == "1"?"M":"E" +  */}
            <td style={{ textAlign: "center", fontSize: "10pt" }}>
              {misc.LZero(new Date(receipt.date).getDate(), 2)}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
              }}
            >
              {receipt.Milk ? receipt.Milk : "-"}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
              }}
            >
              {receipt.Yog ? receipt.Yog : "-"}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
              }}
            >
              {receipt.Ghee ? receipt.Ghee : "-"}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
              }}
            >
              {receipt.Amount ? receipt.Amount : "-"}
            </td>
          </tr>
        );
        return html;
      })
      : null;
  // generating receipt table rows dynamically end

  // print receipt function start
  const PrintReceipt = () => {
    var sOption = "width=1000,height=1000,left=100,top=25";
    var sWinHTML = document.getElementById("invoice-POS").innerHTML;
    var winprint = window.open("", "", sOption);
    winprint.document.open();
    winprint.document.write("<html><head><style>");
    winprint.document.write(`
           @media screen{
            @page{width:80mm;}
              
             /* customer_List -table */
             table{
               border-collapse: collapse;
               width: -webkit-fill-available;
             }
             /* customer_List - table rows and column  */
             td, th {
               padding: 2px;
               border: 1px solid black;
               text-align: left;
             
             }

             #pagebreak{ page-break-before: always; }
             .pagebreak{ page-break-before: always; }
             
           }
          
          
          @media print{
        
            
              
             table{
               border-collapse: collapse;
               width: -webkit-fill-available;
             }
             /* customer_List - table rows and column  */
             td, th {
               padding: 2px;
               border: 1px solid black;
               text-align: left;
             
             }
             #pagebreak{ page-break-before: always; }
             .pagebreak{ page-break-before: always; }
             
           }
          `);

    winprint.document.write(
      `</style></head><body style="margin:auto" onload="window.print()">`
    );
    winprint.document.write(sWinHTML);
    winprint.document.write("</body></html>");
    //    winprint.document.close();
    winprint.focus();
    winprint.print();
    //    if( !/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //     winprint.close();
    //    }
    setReceiptCustomer({});
    return true;
  };
  // print receipt function end
  //print Functionality end

  // deleteallorders start
  const deleteAllOrders = (orderId) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/deleteAllorder", { orderId: orderId }, { headers: headers })
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          props.emptyOrderList();
          getOrders({ page: 0, limit: rowsPerPage });
          setLoading(false);
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };
  // deleteallorders end

  // generte pdf code
  const generatePdf = () => {
    var doc = new jsPDF();
    doc.autoTable({
      html: "#table",
      theme: "grid",
      textColor: 22,
    });
    doc.save("table.pdf");
  };

  const onDeleteOrder = (OrderID) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteAllOrders(OrderID);
  };

  // customer fucctionality start //

  const getCustomers = () => {
    const data = {
      searchCustomerName: searchCustomerName,
    };
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/getsearchcustomers", data, { headers })
      .then((res) => {
        set_Customer_List(res.data.Customers);
        // console.log(res.data.Customers);
      })
      .catch((err) => {
        // console.log("ERROR: ====", err);
      });
  };

  let setCustomerList = (customer) => {
    setCustomer({
      customerId: customer.Customer_ID,
      customerName: customer.Customer_Name,
    });
    setSearchCustomerName(null);
  };

  const changeSearchCustomerState = (e) => {
    const searchCustomerNameValue = e.target.value;
    if (searchCustomerNameValue !== "") {
      setSearchCustomerName(searchCustomerNameValue);
      setCustomer({ ...customer, customerName: searchCustomerNameValue });
    } else {
      setSearchCustomerName(null);
      setCustomer({ customerId: null, customerName: "" });
    }
  };

  setCustomerList = setCustomerList.bind(this);
  useEffect(() => {
    getCustomers();
  }, [searchCustomerName]);
  // customer fucctionality end //
  // console.log(props.Order_List)
  let styles = {
    // fontWeight: "bold",
    padding: 5,
    textAlign: "left",
    verticalAlign: "middle",
    fontSize: 14,
    color: "black",
    fontWeight: "500"
  }
  // console.log(props.Order_List)
  return (
    <div className={classes.root}>
      <PageHeader
        title={props.viewOrderLanguage.Order.Order_Header.Title}
        icon={<ViewList color="primary" fontSize="large" />}
      >
        <Grid
          container
          spacing={1}
          className={classes.ToolbarItems}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <Grid item lg={6} md={6} sm={10} xs={9}>
            <Controls.Input
              label={
                props.viewOrderLanguage.Order.Order_Header.SearchInputTitle
              }
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid spacing={1} container lg={6} md={6} sm={2} xs={3}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <IconButton
                focusRipple={false}
                TouchRippleProps={false}
                disableRipple={true}
                className={classes.newButtonMobile}
                variant="outlined"
              >
                <Link to="addorder">
                  <Box borderRadius="50%" {...defaultProps}>
                    <Add />
                  </Box>
                </Link>
              </IconButton>
              <Controls.Button
                text={props.viewOrderLanguage.Order.Order_Header.Add_Button}
                width="100%"
                height="4rem"
                onClick={() => history.push("/addorder")}
                className={classes.newButton}
                startIcon={<Add />}
              >
                {" "}
              </Controls.Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <IconButton
                className={classes.newButtonMobile}
                onClick={generatePdf}
              >
                <Box borderRadius="50%" {...defaultProps}>
                  <Print />
                </Box>
              </IconButton>
              <Controls.Button
                text={props.viewOrderLanguage.Order.Order_Header.Print_Button}
                width="100%"
                height="4rem"
                startIcon={<Print />}
                className={classes.newButton}
                onClick={generatePdf}
              />
            </Grid>
            {/* <Grid    item lg={4} md={4} sm={4} xs={4} >
                    <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={()=>setOpenPopupBill(true)}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Bill"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>setOpenPopupBill(true)}
                    />
                    </Grid> */}
          </Grid>
        </Grid>
      </PageHeader>
      <Paper className={classes.pageContent} elevation={7}>
        <div
          className={`table-container ${localStorage.getItem("dodaily_urdu") == "true"
            ? "UrduFontTableHead"
            : ""
            }`}
          // onScroll={handleScroll}
        >

          <div>
            <table className="table table-bordered" id="table-main">
              <TblHead
              // cellId={"OrderDate"}
              />
              {
                props.Order_List !== null ? (
                  props.Order_List
                    // .sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate))
                    .map((OrdersList_Order, i2) => (
                      <tbody>
                        <tr
                          key={i2 + 2}
                          style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <td scope="row" style={{ ...styles, textAlign: "center" }}>
                            {misc.formatDate(OrdersList_Order.OrderDate).toString()}
                          </td>
                          <td style={{ ...styles, textAlign: "center" }}>
                            {OrdersList_Order.Trans_Event_ID == 1
                              ? "Morning"
                              : "Evening"}
                          </td>
                          <td style={{ ...styles, textAlign: "center" }}>
                            {OrdersList_Order.Customers}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(OrdersList_Order.Milk_Qty)}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(OrdersList_Order.Milk_Amount)}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(OrdersList_Order.Yog_Qty)}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(OrdersList_Order.Yog_Amount)}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(OrdersList_Order.Others_Qty)}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(OrdersList_Order.Others_Amount)}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              OrdersList_Order.Others_Amount +
                              OrdersList_Order.Yog_Amount +
                              OrdersList_Order.Milk_Amount
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "center" }}>
                            {OrdersList_Order.User_Type !== 1 ? (
                              <>
                                <Controls.ActionButton
                                  colorPrimary="colorPrimary"
                                  onClick={() =>
                                    history.push("/addorder", {
                                      order: OrdersList_Order,
                                    })
                                  }
                                >
                                  <EditOutlined fontSize="small" />
                                </Controls.ActionButton>
                                <Controls.ActionButton
                                  colorPrimary="colorSecondary"
                                  onClick={() => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title: "Are you sure to delete ?",
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        onDeleteOrder(
                                          OrdersList_Order.Trans_Order_ID
                                        );
                                      },
                                    });
                                  }}
                                >
                                  <Delete fontSize="small" />
                                </Controls.ActionButton>
                                {/* <Controls.ActionButton
                                        onClick={()=>getOderItems(OrdersList_Order.Order_ID,OrdersList_Order,true)}
                                            color="text-primary"
                                            >
                                            <Print fontSize="small" />
                                        </Controls.ActionButton> */}
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    )
                    )
                )
                  :
                  (
                    <Controls.Loader />
                  )}
              {page < props.totalPages ? (
                <Controls.Button
                  id="scroll_Btn"
                  width="100%"
                  height="4rem"
                  text="Load"
                  onClick={() =>
                    page < props.totalPages ? setPage(page + 1) : null
                  }
                ></Controls.Button>
              ) : null}
            </table>

            <table
              className="table"
              id="table-main-small">
              {
                props.Order_List !== null ? (
                  props.Order_List
                    // .sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate))
                    .map((OrdersList_Order, i2) => (
                      <>
                        <tbody key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <tr>
                            <td scope="col" id="mobile-style" >Date </td>
                            <td scope="col" id="mobile-style">
                              {misc.formatDate(OrdersList_Order.OrderDate).toString()}
                            </td>
                          </tr>
                          <tr>
                            <td scope="col" id="mobile-style" >Event </td>
                            <td scope="col" id="mobile-style">
                              {OrdersList_Order.Trans_Event_ID == 1
                                ? "Morning"
                                : "Evening"}
                            </td>
                          </tr>
                          <tr>
                            <td scope="col" id="mobile-style" >Bills </td>
                            <td scope="col" id="mobile-style">
                              {OrdersList_Order.Customers}
                            </td>
                          </tr>
                          {OrdersList_Order.Milk_Qty > 0 ?
                            <tr>
                              <td scope="col" id="mobile-style" >Milk Qty </td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(OrdersList_Order.Milk_Qty)}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {OrdersList_Order.Milk_Qty || OrdersList_Order.Milk_Amount > 0 ?
                            <tr>
                              <td scope="col" id="mobile-style" >Amount </td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(OrdersList_Order.Milk_Amount)}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {OrdersList_Order.Yog_Qty > 0 ?
                            <tr>
                              <td scope="col" id="mobile-style" >Yog Qty </td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(OrdersList_Order.Yog_Qty)}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {OrdersList_Order.Yog_Qty || OrdersList_Order.Yog_Amount > 0 ?
                            <tr>
                              <td scope="col" id="mobile-style" >Amount </td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(OrdersList_Order.Yog_Amount)}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {OrdersList_Order.Others_Qty > 0 ?
                            <tr>
                              <td scope="col" id="mobile-style" >Others Qty </td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(OrdersList_Order.Others_Qty)}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {OrdersList_Order.Others_Qty || OrdersList_Order.Others_Amount > 0 ?
                            <tr>
                              <td scope="col" id="mobile-style" >Amount </td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(OrdersList_Order.Others_Amount)}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          <tr>
                            <td scope="col" id="mobile-style" >Total Amount </td>
                            <td scope="col" id="mobile-style">
                              {misc.currencyOnlyDecimal(
                                OrdersList_Order.Others_Amount +
                                OrdersList_Order.Yog_Amount +
                                OrdersList_Order.Milk_Amount
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td scope="col" id="mobile-style" >Actions </td>
                            <td scope="col" id="mobile-style">
                              {OrdersList_Order.User_Type !== 1 ? (
                                <>
                                  <Controls.ActionButton
                                    colorPrimary="colorPrimary"
                                    onClick={() =>
                                      history.push("/addorder", {
                                        order: OrdersList_Order,
                                      })
                                    }
                                  >
                                    <EditOutlined fontSize="small" />
                                  </Controls.ActionButton>
                                  <Controls.ActionButton
                                    colorPrimary="colorSecondary"
                                    onClick={() => {
                                      setConfirmDialog({
                                        isOpen: true,
                                        title: "Are you sure you want to delete?",
                                        subTitle: "You can't undo this operation",
                                        onConfirm: () => {
                                          onDeleteOrder(
                                            OrdersList_Order.Trans_Order_ID
                                          );
                                        },
                                      });
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </Controls.ActionButton>
                                  {/* <Controls.ActionButton
                                        onClick={()=>getOderItems(OrdersList_Order.Order_ID,OrdersList_Order,true)}
                                            color="text-primary"
                                            >
                                            <Print fontSize="small" />
                                        </Controls.ActionButton> */}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                        <hr
                          style={{ margin: 5, padding: 0 }}
                        />
                      </>
                    )
                    )
                )
                  :
                  (
                    <Controls.Loader />
                  )
              }
            </table>
          </div>
          {loading ? <Controls.Loader /> : null}
          {!loading && props.Order_List.length === 0 ? (
            <Typography
              variant="h4"
              style={{ margin: "auto", display: "block", textAlign: "center" }}
            >
              No Record
            </Typography>
          ) : null}
        </div>

        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <table
          id="table"
          style={{
            width: " 100%",
            borderCollapse: "collapse",
            border: "1px solid black",
            display: "none",
          }}
        >
          <tr>
            {headCells.map((OrdersList_Order, index) =>
              OrdersList_Order.label !== "Actions" ? (
                <th key={index} style={{ border: "1px solid black" }}>
                  {OrdersList_Order.label}
                </th>
              ) : null
            )}
          </tr>
          {props.Order_List !== null
            ? props.Order_List
              .sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate))
              .map((OrdersList_Order, index) => (
                <tr key={index}>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="date"
                    data-heading="Date"
                  >
                    {misc.formatDate(OrdersList_Order.OrderDate).toString()}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="event"
                    data-heading="Event "
                  >
                    {OrdersList_Order.Trans_Event_ID == 1
                      ? "Morning"
                      : "Evening"}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="bills"
                    data-heading="Bills"
                  >
                    {OrdersList_Order.Customers}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="amount"
                    data-heading="Milk Qty "
                  >
                    {misc.currencyOnlyDecimal(OrdersList_Order.Milk_Qty)}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="amount"
                    data-heading="Milk Amount "
                  >
                    {misc.currencyOnlyDecimal(OrdersList_Order.Milk_Amount)}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="amount"
                    data-heading="Yog Qty "
                  >
                    {misc.currencyOnlyDecimal(OrdersList_Order.Yog_Qty)}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="amount"
                    data-heading="Yog Amount "
                  >
                    {misc.currencyOnlyDecimal(OrdersList_Order.Yog_Amount)}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="amount"
                    data-heading="Others Qty "
                  >
                    {misc.currencyOnlyDecimal(OrdersList_Order.Others_Qty)}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="amount"
                    data-heading="Others Amount "
                  >
                    {misc.currencyOnlyDecimal(OrdersList_Order.Others_Amount)}
                  </td>
                  <td
                    style={{ border: "1px solid black", color: "black" }}
                    name="amount"
                    data-heading="Total Amount "
                  >
                    {misc.currencyOnlyDecimal(
                      OrdersList_Order.Others_Amount +
                      OrdersList_Order.Yog_Amount +
                      OrdersList_Order.Milk_Amount
                    )}
                  </td>
                </tr>
              ))
            : null}
        </table>
      </Paper>

      <Popup
        title="Bill"
        openPopup={openPopupBill}
        setOpenPopup={setOpenPopupBill}
      >
        <Grid container spacing={2} lg={12} md={12} sm={12}>
          <Grid item lg={6} md={6}>
            <Controls.DateInput
              name="Start_Date"
              label="Start Date"
              value={Start_Date}
              onchange={(Start_Date) => Set_Start_Date(Start_Date)}
            ></Controls.DateInput>
          </Grid>
          <Grid item lg={6} md={6}>
            <Controls.DateInput
              name="End_Date"
              label="End Date"
              value={End_Date}
              onchange={(End_Date) => Set_End_Date(End_Date)}
            ></Controls.DateInput>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="form_search_customer">
              <Controls.SearchList
                Input_ID="input_customer_name"
                ItemName="Customer_Name"
                ItemAddress="Customer_Address"
                form_Name="customer"
                items={customer_List}
                setSelected={setCustomerList}
              >
                <Controls.Input
                  label="Customer Name"
                  // width="100%"
                  // marginLeft="2rem"
                  name="Customer_Name"
                  id="input_customer_name"
                  nextid="input_MMQ"
                  value={customer.customerName}
                  onChange={changeSearchCustomerState}
                  placeholder="Select Customer"
                  aria-activedescenda
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                ></Controls.Input>
              </Controls.SearchList>
            </div>
          </Grid>
          <div className={classes.grid6}>
            <Controls.Button keyupfire={false} type="submit" text="Submit" />
          </div>
        </Grid>
      </Popup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  viewOrderLanguage: state.language.language,
  Order_List: state.OrdersList.Order_List,
  totalPages: state.OrdersList.totalPages,
  currentPage: state.OrdersList.currentPage,
  totalOrders: state.OrdersList.totalOrders,
});

const mapDispatchToProps = (dispatch) => ({
  GetOrders: (orders) => {
    dispatch(GetOrders(orders));
  },
  setItems: (items) => {
    dispatch(setItems(items));
  },
  DeleteOrderFromReduxState: (OrderID, totalOrders, totalPages) => {
    dispatch(DeleteOrderFromReduxState(OrderID, totalOrders, totalPages));
  },
  emptyOrderList: () => {
    dispatch(emptyOrderList());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(ViewOrders))