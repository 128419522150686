import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
//
import { NavLink } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { LogOutHandler } from "../redux/actions/AuthAction";
import Toggle from "./common/toggle";
import clsx from "clsx";
import { Grid, Backdrop } from "@material-ui/core";
import Controls from "./controls/Controls";

import ConfirmDialog from "../components/ConfirmDialog";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Profile from "../pages/profile";
import Popup from "./Popup";
import {
  SupervisedUserCircle,
  AccountBox,
  AccountBalance,
  AccountBalanceWallet,
  ViewList,
  Receipt,
  AssignmentInd,
  ExitToApp,
  FindInPage,
} from "@material-ui/icons";
//

//
// import Users from "../pages/Users/Users";
// import Balance from "../pages/Balance/Balance";
// import AccountsBalances from "../pages/AccountsBalances/AccountsBalances";
// import Areas from "../pages/Areas/Areas";
// import Receipts from "../pages/Receipts/Receipts";
// import Customers from "../pages/Customers/Customers";
// import CustomersPrint from "../pages/Customers/CustomerListPrint"; //Print customers
// import ViewOrders from "../pages/Purchases/ViewOrders";
// import DailyOrders from "../pages/DailyOrders/DailyOrders";
// import AddOrder from "../pages/Purchases/AddOrder";
//

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.primary.main,
    "& .MuiToolbar-root": {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
      },
    },
  },
  //
  rightMenu: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& > *": {
      marginRight: "1rem",
    },
  },
  rightMenuGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .userName": {
      color: "#FFFF !important",
    },
    "& .title": {
      color: "#FFFF !important",
    },
  },
  rightMenuMobile: {
    width: "100%",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .MuiIconButton-root": {
      padding: ".5rem",
    },
    // [theme.breakpoints.up('xs')]:{
    //   display:"none"
    // },
    "& > *": {
      color: "white",
      marginLeft: ".5rem",
    },
  },
  mobileLogoDiv: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down(600)]: {
      minHeight: "60px ",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1.4, 3),
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
    },
    "& input[type=Date]": {
      [theme.breakpoints.down("xs")]: {
        height: "0rem !important",
        padding: "1rem 2rem 3rem 2rem !important",
      },
    },
  },
  mobileDiv: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  desktopHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 1rem 0 1rem",
  },
  TabsWrapper: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "fit-content",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  },
  desktopLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "10rem",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "10rem",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  desktopRightDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "27rem",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  //
}));

const defaultProps = {
  bgcolor: "#5B2B17",
  borderColor: "white",
  m: 1,
  padding: 0,
  border: 1,
  style: { width: "4rem", height: "4rem" },
  display: "flex",
  margin: "0rem",
  alignItems: "center",
  justifyContent: "center",
};

const ScrollableTabsButtonForce = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const LogOutHandle = () => {
    dispatch(LogOutHandler());
  };
  const onLogout = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    LogOutHandle();
  };
  // console.log(props.HeaderLanguage)
  return (
    <div className={classes.root}>
      <AppBar position="static">
        {/*  */}
        <Toolbar>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.rightMenuGrid}
          >
            <Grid
              className={classes.mobileLogoDiv}
              item
              xs={4}
              sm={6}
              md={7}
              lg={7}
            >
              <div className={classes.desktopLogo}>
                <img
                  style={{ width: "3.9rem" }}
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                ></img>
                <Typography
                  variant="subtitle2"
                  className="Simple_Solutions_Logo"
                >
                  MILK{" "}
                  <Typography
                    variant="subtitle2"
                    className="Simple_Solutions_Logo_child"
                  >
                    DODAILY
                  </Typography>
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.mobileDiv}
              item
              xs={6}
              sm={3}
              md={4}
              lg={4}
            >
              <div className={classes.rightMenu}>
                <Toggle />
                <Button
                  onClick={() => {
                    // setOpenPopup(true);
                  }}
                >
                  <Typography className="userName" variant="h6">
                    {props.userName}
                  </Typography>
                </Button>
                <Controls.ActionButton
                  colorPrimary="colorSecondary"
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: props.HeaderLanguage.main.header.Confirm_Logout,
                      subTitle: "",
                      onConfirm: () => {
                        onLogout();
                      },
                    });
                  }}
                  fontSize="medium"
                >
                  <Typography color="textSecondary">
                    {props.HeaderLanguage.main.header.Button_Logout}
                  </Typography>
                </Controls.ActionButton>
              </div>
              <div className={classes.rightMenuMobile}>
                <Toggle />
                <IconButton
                  onClick={() => {
                    // setOpenPopup(true);
                  }}
                >
                  <Box borderRadius="50%" {...defaultProps}>
                    <AssignmentInd />
                  </Box>
                </IconButton>
                <IconButton
                  colorPrimary="colorSecondary"
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: props.HeaderLanguage.main.header.Confirm_Logout,
                      subTitle: "",
                      onConfirm: () => {
                        onLogout();
                      },
                    });
                  }}
                >
                  <Box borderRadius="50%" {...defaultProps}>
                    <ExitToApp />
                  </Box>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        <div className={classes.desktopHeader}>
          <div className={classes.logo}>
            <img
              style={{ width: "3.9rem" }}
              src={`${process.env.PUBLIC_URL}/logo.png`}
            ></img>
            <Typography variant="subtitle2" className="Simple_Solutions_Logo">
              MILK{" "}
              <Typography
                variant="subtitle2"
                className="Simple_Solutions_Logo_child"
              >
                DODAILY
              </Typography>
            </Typography>
          </div>
          <Tabs
            className={classes.TabsWrapper}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            {props.userType == "1" ? (
              <NavLink to="/users">
                <Tab
                  label={props.HeaderLanguage.main.Sidebar.link_Users}
                  icon={<SupervisedUserCircle />}
                  {...a11yProps(0)}
                />
              </NavLink>
            ) : null}
            <NavLink to="/customers">
              <Tab
                label={props.HeaderLanguage.main.Sidebar.link_Customers}
                icon={<AccountBox />}
                {...a11yProps(1)}
              />
            </NavLink>
            <NavLink to="/balance">
              <Tab
                label={props.HeaderLanguage.main.Sidebar.link_Balance}
                icon={<AccountBalanceWallet />}
                {...a11yProps(2)}
              />
            </NavLink>
            <NavLink to="/viewOrders">
              {localStorage.getItem("dodaily_urdu") == "true"
                ?
                <Tab
                  label={props.HeaderLanguage.main.Sidebar.link_Orders}
                  icon={<ViewList />}
                  {...a11yProps(3)}
                />
                :
                <Tab
                  label={"Daily Orders"}
                  icon={<ViewList />}
                  {...a11yProps(3)}
                />
              }
            </NavLink>

            <NavLink to="/dailyorders">
              {localStorage.getItem("dodaily_urdu") == "true"
                ?
                <Tab
                  label={props.HeaderLanguage.main.Sidebar.link_Daily_Orders}
                  icon={<FindInPage />}
                  {...a11yProps(4)}
                />
                :
                <Tab
                  label={"Search Orders"}
                  icon={<FindInPage />}
                  {...a11yProps(4)}
                />
              }
            </NavLink>
            <NavLink to="/receipt">
              <Tab
                label={props.HeaderLanguage.main.Sidebar.link_Receipts}
                icon={<Receipt />}
                {...a11yProps(5)}
              />
            </NavLink>
            <NavLink to="/accountsbalances">
              {localStorage.getItem("dodaily_urdu") == "true"
                ?
                <Tab
                  label={props.HeaderLanguage.main.Sidebar.link_Accounts_Balance}
                  icon={<AccountBalance />}
                  {...a11yProps(6)}
                />
                :
                <Tab
                  label={"Receivable List"}
                  icon={<AccountBalance />}
                  {...a11yProps(6)}
                />
              }
            </NavLink>
          </Tabs>
          <div className={classes.desktopRightDiv}>
            <div className={classes.rightMenu}>
              <Toggle />
              <Button
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                <Typography className="userName" variant="h6">
                  {props.userName}
                </Typography>
              </Button>
              <Controls.ActionButton
                colorPrimary="colorSecondary"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: props.HeaderLanguage.main.header.Confirm_Logout,
                    subTitle: "",
                    onConfirm: () => {
                      onLogout();
                    },
                  });
                }}
                fontSize="medium"
              >
                <Typography color="textSecondary">
                  {props.HeaderLanguage.main.header.Button_Logout}
                </Typography>
              </Controls.ActionButton>
            </div>
            <div className={classes.rightMenuMobile}>
              <Toggle />
              <IconButton
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                <Box borderRadius="50%" {...defaultProps}>
                  <AssignmentInd />
                </Box>
              </IconButton>
              <IconButton
                colorPrimary="colorSecondary"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: props.HeaderLanguage.main.header.Confirm_Logout,
                    subTitle: "",
                    onConfirm: () => {
                      onLogout();
                    },
                  });
                }}
              >
                <Box borderRadius="50%" {...defaultProps}>
                  <ExitToApp />
                </Box>
              </IconButton>
            </div>
          </div>
        </div>
      </AppBar>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {props.children}
      </main>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title="Account Profile"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Profile />
      </Popup>
      {/* <TabPanel value={value} index={0}>
        <User />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Customers />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Balance />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ViewOrders />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <DailyOrders />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Receipts />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <AccountsBalances />
      </TabPanel> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  HeaderLanguage: state.language.language,
  userName: state.Auth.userName,
  userType: state.Auth.userType,
});

export default connect(mapStateToProps)(ScrollableTabsButtonForce);
