import React from 'react'
import { TextareaAutosize ,makeStyles } from '@material-ui/core';
import $ from 'jquery'
const useStyles = makeStyles(theme => ({
    Input: {
        "& .MuiInputBase-root  .MuiInputBase-input":{
            padding: "0",
            paddingLeft:"1rem",
            paddingRight:"1rem",
            height:"4rem ",
            [theme.breakpoints.down('lg')]: {
                fontSize:"1.6rem !important"
               },
            [theme.breakpoints.down('md')]: {
               fontSize:"1.4rem !important"
              },
              [theme.breakpoints.down('sm')]: {
                fontSize:"1.3rem !important"  
              }
        },
        "& .MuiInputLabel-formControl":{
            backgroundColor: "white",
            paddingBottom:"1rem",
            paddingRight:"1rem",
              [theme.breakpoints.down('sm')]: {
                fontSize:"1.3rem !important" ,
                paddingBottom:".2rem", 
              }
        },
       
    },
    "qty": {
        "& .MuiInputBase-root  .MuiInputBase-input":{
            textAlign:"right !important"
        },
          
    },
    "amount": {
        "& .MuiInputBase-root  .MuiInputBase-input":{
            textAlign:"right !important"
        },
          
    }
}))
export default function Textarea(props) {
    const classes = useStyles();
    const { name, label, value,error=null, height,onChange,nextid,saveKeyId,listItemsClassName,placeholder,type='text',varient,width,marginLeft,id,tabindex ,...other } = props;
    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            $(`#${nextid}`).focus()
        }
        else if (event.keyCode === 13 && event.keyCode === 18) {
            $(`#${saveKeyId}`).focus()
        }


        var keys = {};


    //     if(listItemsClassName){
    //       const listLitem =  document.querySelectorAll(`.${listItemsClassName}`)
    //     const focusable= [...listLitem]; 
    //     const index = focusable.indexOf(document.activeElement);
    //     console.log(listLitem)
    //     console.log(focusable)
    //     let nextIndex = 0;
    //     if (event.keyCode === 38) {
    //         // up arrow
    //         event.preventDefault();
    //         nextIndex= index > 0 ? index-1 : 0;
    //         listLitem.focus()
    //       }
    //       else if (event.keyCode === 40) {
    //         // down arrow
    //         event.preventDefault();
    //         nextIndex= index+1 < focusable.length ? index+1 : index;
    //     listLitem.focus()
    //       }
    // }
      }
    return (
        <TextareaAutosize 
        aria-autocomplete="none"
        className={[classes.Input, classes[varient]]}
        style={{width: `calc(${width - marginLeft})`,height:`${height}`}}
            variant="outlined"
            placeholder={placeholder}
            label={label}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            autoComplete='false'
            onKeyDown={handleKeyUp}
        id={id}
            {...other}
            {...(error && {error:true,helperText:error})}
            InputLabelProps={{
                shrink: true
              }}
        />
    )
}
