import React from 'react'
import { makeStyles, FormControlLabel } from '@material-ui/core';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery"
const useStyles = makeStyles(theme => ({
  dateInputWrapper: {
    width: "100%",
    position: 'relative'
  },
  label: {
    position: "absolute",
    left: "1rem",
    top: "-.7rem",
    width: "fit-content",
    textAlign: "center",
    backgroundColor: "#ffffff",
    color: "#7d7b7b",
    fontSize: "1.3rem ",
    [theme.breakpoints.down('md')]: {
      fontSize: "1rem ",

    },

  },
  input: {
    padding: "0",
    paddingLeft: ".5rem",
    width: "100%",
    height: "4rem ",
    border: `1px solid #c4c4c4`,
    borderRadius: ".4rem",
    "&:focus": {
      border: `2px solid ${theme.palette.primary.main} !important`,
      outline: `2px solid transparent !important`,
      zIndex: 99999
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: "1.3rem !important"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "1.2rem !important"
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.2rem !important"
    }
  },

}
))
export default function DateInput(props) {
  const classes = useStyles();
  const { name, label, disabled, value, error = null, nextid, onChange, tabindex, id, type = 'text', width, ...other } = props;

  function handleKeyUp(event) {

    $(`#${nextid}`).focus()
  }
  return (
    <div className={`${classes.dateInputWrapper} dateInput  ${localStorage.getItem('dodaily_urdu') == "true" ? "UrduFontInputLabel" : ""}`}>
      <DatePicker
        className={classes.input}
        name={name}
        width="100%"
        id={id}
        aria-label={label}
        ariaLabelledBy={label}
        excludeTimes
        disabled={disabled}
        selected={value}
        onChange={onChange}
        onSelect={handleKeyUp}
        onCalendarClose={handleKeyUp}
        dateFormat="dd-MM-yyyy"
      >
      </DatePicker>
      <label className={classes.label}>{label}</label>
    </div>
  )
}
