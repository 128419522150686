import React, { useState } from 'react';
import { Grid, Paper, Avatar, TextField, Button, Typography, FormControl, InputLabel, Input, InputAdornment, IconButton } from '@material-ui/core'
import { Lock, VisibilityOff, Visibility } from '@material-ui/icons';
import { connect } from 'react-redux'
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SigninHandler } from "../../redux/actions/AuthAction"
import TransitionAlerts from "../common/Alert"
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import $ from 'jquery'

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundSize: "cover",
    height: "100vh",
    background: `url(${process.env.PUBLIC_URL}/images/auth-bg-desktop.jpg)`,
    [theme.breakpoints.only('xl')]: {
      background: `url(${process.env.PUBLIC_URL}/images/auth-bg-bigdesktop.jpg)`,
      backgroundSize: "cover",
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      background: `url(${process.env.PUBLIC_URL}/images/auth-bg-mobile.jpg)`,
      backgroundSize: "cover",
    }
  },
  inputField: {
    "& .MuiInputBase-root": {
      fontSize: "1.6rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: "2rem"
      }
    },
    "& .MuiFormLabel-root": {
      [theme.breakpoints.down('sm')]: {
        fontSize: "2rem"
      }
    }
  },
  Alert: {
    margin: "2rem 0rem 2rem 10rem",
    [theme.breakpoints.down('sm')]: {
      margin: "0",
      width: "fit-content",
      position: "fixed",
      top: "90%",
      left: "50%",
      transform: "translate(-50%,-70%)"
    },
  },
  paperStyle: {
    padding: 20,
    height: "min-content",
    width: 250,
    margin: "2rem 0rem 2rem 19rem",
    backgroundColor: "rgba(255,255,255,.9)",
    [theme.breakpoints.down('sm')]: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-70%)",
      width: 300,
      margin: "2rem auto 2rem auto",
      backgroundColor: "rgba(255,255,255,.5)"
    },
  },

  authLint: {
    color: theme.palette.primary.main
  },
  avatarStyle: {
    backgroundColor: theme.palette.primary.main
  },
  caption: {
    position: "absolute",
    top: "95%",
    left: "1%",
    fontSize: "1.6rem",
    "& a": {
      fontWeight: "bold"
    }
  },
  imageDiv: {
    "& img": {
      width: "100%", height: "auto", objectFit: "cover",
      [theme.breakpoints.down('md')]: {

        width: "70%"
      }
    }
  },
  AnimatedTextDiv: {
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  }
}));
const Login = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({ phoneNumber: '', password: '' })
  const [alert, setAlert] = useState({ isOpen: false, message: '', type: '' })
  const [values, setValues] = useState({
    showPassword: false
  });
  const history = useHistory()
  //jquery
  $(document).ready(() => {
    $(document).keydown((event) => {
      if (event.keyCode === 13) {
        $('#signInBtn').click()
      }
    })
  });

  function onChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const handleLogin = (event) => {
    event.preventDefault()
    const headers = {
      'Content-Type': 'application/json'
    }
    const data = JSON.stringify(state)
    axios.post('/auth/login', data, {
      headers: headers
    }).then(res => {
      if (res.data.token) {
        // console.log(res.data)
        props.signinhandle(res.data)
        history.push(`/${res.data.redirect}`)
      } else {
        setAlert({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? 'error' : 'success'
        })
      }

    }).catch(err => {
      // console.log(err)
      setAlert({
        isOpen: true,
        message: err.response.data.message,
        type: err.response.data.iserror ? 'error' : 'success'
      })
    })

  }
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const avatarStyle = { backgroundColor: '#1bbd7e' }
  const btnstyle = { margin: '8px 0' }
  return (
    <>
      <div className={classes.root}>

        <Grid container>
          <Grid className={classes.Alert} item xs={8} sm={6} lg={4}><TransitionAlerts message={alert.message} open={alert.isOpen} setOpen={() => setAlert({ ...alert, isOpen: false })} type={alert.type} reopen={false} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>

            <Paper elevation={10} className={classes.paperStyle}>
              <Grid align='center'>
                <Avatar className={classes.avatarStyle}><Lock /></Avatar>
                <h2>Sign In</h2>
              </Grid>
              <TextField className={classes.inputField} label='Mobile Number' name="phoneNumber"
                value={state.phoneNumber}
                onChange={onChange} placeholder='03000000000' fullWidth required />
              {/* <TextField label='Password' placeholder='Enter password'
    value={state.password}
    name="password"
    onChange={onChange} type='password' fullWidth required
    
    ></TextField>  */}
              <FormControl className={classes.inputField}>
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={state.password}
                  name="password"
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button id="signInBtn" onClick={handleLogin} color='primary' variant="contained" style={btnstyle} fullWidth>Sign In</Button>
              {/* <Typography > Do you have an account ?
         <Link className={classes.authLint} to="/register" >
            Register
            </Link>
  </Typography> */}
              <Typography > Don't have an account?&nbsp;
                <Link
                  className={classes.authLint}
                  to="/register" >
                  Register
                </Link>
              </Typography>
              {/* <Typography > Do you have an account?&nbsp;
         <Link className={classes.authLint} to="/forgetpassword" > 
         Forget Password?
         </Link>
    </Typography> */}
              <Grid className={classes.imageDiv} align='center' item lg={12}>
                <img src={process.env.PUBLIC_URL + 'logo.png'} />
              </Grid>
            </Paper>
          </Grid>
          <Grid className={classes.AnimatedTextDiv} container item lg={6} md={6}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{
              padding: 20,
              position: "relative",
              minHeight: "35rem",
              margin: "2rem 0rem 2rem 2rem",
              fontSize: "1.6rem", color: "white"
            }}>
              <TypeAnimation

                style={{ marginBottom: "1rem", display: "block" }}
                className='parent-wrapper'
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  'We provide exceptional service to milk sellers',
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                  'ہم دودھ بیچنے والوں کو معیاری خدمات فراہم کرتے ہیں۔',
                ]}
                wrapper="span"
                speed={20}
                repeat={Infinity}

              ></TypeAnimation>
              <div
                className='discussProjectBtn'
              >
                <Link
                  to='/register'
                  className='btn site_btn'
                >
                  Are You A New User Click Here!
                </Link>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* <div className={classes.caption}>Image by <a href="https://pixabay.com/users/anniespratt-4900708/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1209635" target="__blank">Annie Spratt</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1209635" target="__blank">Pixabay</a></div> */}
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  signinhandle: (data) => { dispatch(SigninHandler(data)) }

})
export default connect(null, mapDispatchToProps)(Login);
