import React from 'react'
import { Button as MuiButton, makeStyles } from "@material-ui/core";
import $ from 'jquery'

const useStyles = makeStyles(theme => ({
    root: {

        fontSize:"1.3rem",
        width:"100%"
    },
    label: {
        textTransform: 'none'
    }
}))

export default function Button(props) {

    const { text, size, color,nextid,variant, onClick,id,height,...other } = props
    const classes = useStyles();
    function handleKeyUp(event) {
            $(`#${nextid}`).focus()
        
        // if (event.keyCode === 39) {
            
        //   }
        // if (event.keyCode === 40) {
        //     nextid.focus()
        //   }
      }
      const handleClick=()=>{
          if(onClick){
              onClick()
          }
        if(nextid){
        handleKeyUp()
        }
      }
    return (
        <MuiButton 
            variant={variant || "contained"}
            style={height?{height:`${height}`}:{height:`inherit`}}
            color={color || "primary"}
            onClick={handleClick}
            id={id}
            {...other}
            classes={{ root: classes.root, label: classes.label }}>
            {text}
        </MuiButton>
    )
}
