import {GETAREAS,UPDATEAREA,DELETEAREA,EMPTYAREAS} from './types'
import axios from 'axios'

export const GetAreas =(data,setLoading)=>dispatch=>{
    const headers={
        "x-access-token": sessionStorage.getItem("accountingauthkey")
    }
    axios.post('/api/getareas',data,{headers:headers}).then(res=>{ 
    dispatch({type:GETAREAS,payload:res.data})
    setLoading(false)
  })
}

export const EmptyAreas =()=>dispatch=>{
  dispatch({type:EMPTYAREAS})
}
export const UpdateArea=(area)=>dispatch=>{
  const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  axios('/api/updatearea',area,headers).then(res=>dispatch({type:UPDATEAREA,payload:res.message}))
}

export const DeleteArea =(areaID,totalPages,totalAreas)=>(
  {type:DELETEAREA,
  payload:{areaID,totalPages,totalAreas}}
)