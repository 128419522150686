import {GETORDERS,DELETEORDER} from './types'
import axios from "axios"
export const GetOrders =(orders)=>(
    
    {type:GETORDERS,
    payload:orders}
)

export const DeleteOrderFromReduxState =(OrderID,totalOrders,totalPages)=>(
    
    {type:DELETEORDER,
       payload: {OrderID,totalOrders,totalPages}}
)

export const emptyOrderList = () => ({
    type: 'EMPTYORDERLIST',
})