import React from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const GenericPdfDownloader = ({ rootElementId, downloadFileName }) => {

    const downloadPdfDocument = () => {
        const input = document.getElementById(rootElementId);
        const innermostDiv = input.querySelector("#testId > div > div")
        innermostDiv.style.display = ""
        html2canvas(innermostDiv)
            .then((canvas) => {
                console.log(canvas)
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'mm',
                    format: [83, 283],
                });
                pdf.addImage(imgData, 'JPEG', 1.5, 3);
                pdf.save(`${downloadFileName}.pdf`);
                innermostDiv.style.display = "none"
            })
    }

    // return <button onClick={downloadPdfDocument}>Download Pdf</button>
    return {
        downloadPdfDocument
    }

}

export default GenericPdfDownloader;