import {GETACCOUNTSBALANCES,UPDATEACCOUNTSBALANCE,DELETEACCOUNTSBALANCE,EMPTYACCOUNTSBALANCES} from './types'
import axios from 'axios'

export const GetAccountsBalances =(data,setLoading)=>dispatch=>{
    const headers={
        "x-access-token": sessionStorage.getItem("accountingauthkey")
    }
    axios.post('/api/getaccountsbalances',data,{headers:headers}).then(res=>{ 
    dispatch({type:GETACCOUNTSBALANCES,payload:res.data})
    setLoading(false)
  })
}

export const EmptyAccountsBalances =()=>dispatch=>{
  dispatch({type:EMPTYACCOUNTSBALANCES})
}
export const UpdateAccountsBalance=(receipt)=>dispatch=>{
  const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  axios('/api/updateaccountsbalance',receipt,headers).then(res=>dispatch({type:UPDATEACCOUNTSBALANCE,payload:res.message}))
}

export const DeleteAccountsBalance =(accountsBalanceID,totalPages,totalAccountsBalances)=>(
  {type:DELETEACCOUNTSBALANCE,
  payload:{accountsBalanceID,totalPages,totalAccountsBalances}}
)