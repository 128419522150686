export const deleteItem = (id) => ({
    type: 'DELETECARTITEM',
    payload: id,
})
export const addItem = (item) => ({
    type: 'SETCARTITEM',
    payload: item,
})

export const setItems = (items) => ({
    type: 'SETCARTITEMS',
    payload: items
})

export const emptyOrderItemsList = () => ({
    type: 'EMPTYCART',
})


