import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CustomerForm from "./CustomerForm";
import PageHeader from "../../components/PageHeader";
import axios from "axios";
import AccountBox from "@material-ui/icons/AccountBox";
import "./pages.css"
import '../css/font.css'
import GenericPdfDownloader from "./pdf";
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import Modal from 'react-modal';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { connect } from "react-redux";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Print from "@material-ui/icons/Print";

import Popup from "../../components/Popup";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Delete from "@material-ui/icons/Delete";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  GetCustomers,
  EmptyCustomer,
  DeleteCustomer,
  SetCustomers,
} from "../../redux/actions/CustomerAction";
import { changeLanguage } from "../../redux/actions/languageAction";

import misc from "../../fnc/misc";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .pageHeaderPageInfo": {
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
  },

  // balance page css
  pageContent: {
    padding: "1rem 1rem 1rem 1rem",
    height: `calc(100vh - 18rem)`,
    [theme.breakpoints.down("md")]: {
      height: `calc(100vh - 18rem)`,
    },
    [theme.breakpoints.down("sm")]: {
      height: `calc(100vh - 20rem)`,
    },
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - 23rem)`,
    },
    overflow: "hiddin",
    "& .MuiGrid-item > *": {
      maxWidth: `calc(100% - 1rem) !important`,
      width: `calc(100% - 1rem) !important`,
      marginRight: `1rem !important`,
    },
    "& .table-container": {
      height: `100%`,
      overflow: "auto",
      "& thead": {
        "&  tr": {
          "& [name=date]": {
            minWidth: "10rem !important",
          },
          "& [name=name]": {
            minWidth: "15rem !important",
          },

          "& [name=mobile]": {
            minWidth: "5rem !important",
          },

          "& [name=rate]": {
            minWidth: "5rem !important",
          },
          "& [name=qty]": {
            minWidth: "3rem !important",
          },
          "& [name=active]": {
            minWidth: "3rem !important",
          },

          "& [name=actions]": {
            minWidth: "10rem !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=date]": {
              minWidth: "auto !important",
            },
            "& [name=name]": {
              minWidth: "auto !important",
            },
            "& [name=mobile]": {
              minWidth: "auto !important",
            },

            "& [name=rate]": {
              minWidth: "auto !important",
            },
            "& [name=qty]": {
              minWidth: "auto !important",
            },
            "& [name=active]": {
              minWidth: "auto !important",
            },
            "& [name=actions]": {
              minWidth: "auto !important",
            },
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            [theme.breakpoints.down(900)]: {
              paddingLeft: "20rem",
              // "& p":{
              //     display:"inline",
              //     width:"50%"
              // }
            },
            [theme.breakpoints.down(539)]: {
              paddingLeft: "15rem",
            },
          },
          "& [name=date]": {
            minWidth: "10rem !important",
          },
          "& [name=name]": {
            minWidth: "15rem !important",
          },

          "& [name=mobile]": {
            minWidth: "5rem !important",
          },

          "& [name=rate]": {
            minWidth: "5rem !important",
          },
          "& [name=qty]": {
            minWidth: "3rem !important",
          },
          "& [name=active]": {
            minWidth: "3rem !important",
          },

          "& [name=actions]": {
            minWidth: "10rem !important",
          },
          [theme.breakpoints.down(900)]: {
            "& [name=date]": {
              minWidth: "auto !important",
            },
            "& [name=name]": {
              minWidth: "auto !important",
            },
            "& [name=mobile]": {
              minWidth: "auto !important",
            },

            "& [name=rate]": {
              minWidth: "auto !important",
            },
            "& [name=qty]": {
              minWidth: "auto !important",
            },
            "& [name=active]": {
              minWidth: "auto !important",
            },
            "& [name=actions]": {
              minWidth: "auto !important",
            },
          },
        },
      },

      "& #scroll_Btn": {
        margin: "auto",
        display: "none",
        [theme.breakpoints.between("xs", "sm")]: {
          display: "block",
        },
      },
    },
  },
  Toolbar: {},

  ToolbarItems: {
    display: "flex",
    alignItems: "center",
    // [theme.breakpoints.between("xs","sm")]:{
    //     paddingLeft:"1rem"
    // },
  },

  //     buttonContainerGrid:{
  //         display:"flex",
  //         alignItems:"center",
  //         justifyContent:"flex-end",
  //         [theme.breakpoints.between("xs","sm")]:{
  //             marginRight:"2rem",
  //         },
  //         "& button":{
  //             width:"49%",
  //             [theme.breakpoints.between("md","xl")]:{
  //                 marginRight:".3rem",
  //             },
  //         },

  //   },
  newButtonMobile: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  newButton: {
    height: "4rem",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  ActionBtnDiv: {
    [theme.breakpoints.down(900)]: {
      // display:"flex !important",
      // flexDirection:"column"
      "& > *": {
        width: "4rem",
        height: "3rem",
        padding: ".5rem",
      },
      "& svg": {
        fontSize: "1.6rem",
      },
    },
  },
}));

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "inherit",
  m: 1,
  padding: 0,
  border: 1,
  style: { width: "4rem", height: "4rem" },
  display: "flex",
  margin: "0rem",
  alignItems: "center",
  justifyContent: "center",
};
const Customers = (props) => {

  Modal.setAppElement(Customers)
  Modal.setAppElement('#root')

  function openModal() {
    setIsOpen(true)
  }

  let headCells =
    props.customerLanguage.Customer.Customer_Table.Customer_Table_Head;

  const classes = useStyles();
  const [modalIsOpen, setIsOpen] = useState(false)
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [Billing_Customer, Set_Billing_Customer] = useState(null);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopupForm, setopenPopupForm] = useState(false); // customer form popup state
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: '20%',
      bottom: '-10%',
      marginRight: '-20%',
      transform: 'translate(-50%, -50%)',
    },
  }

  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchCustomerName, setSearchCustomerName] = useState(null);
  const [Receipt_Date, Set_Receipt_Date] = useState(new Date()); // receipt date selection state
  const [Open_Popup_Receipt_Date, Set_Open_Popup_Receipt_Date] =
    useState(false);
  const [open_Popup_All_Bills, Set_Open_Popup_All_Bills] =
    useState(false);
  const history = useHistory();
  const [Billing_Info, Set_Billing_Info] = useState(null); // recipts data for table maping
  const [receiptCustomer, setReceiptCustomer] = useState({}); // customer data of which receipt is printing
  const [Duration_Date, Set_Duration_Date] = useState(new Date()) // initial date for further programming in receipt
  const [allCustomersList, setAllCustomersList] = useState([{}])
  const [finalPrintList, setFinalPrintList] = useState([{}])

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    rowsPerPage,
    page,
    setPage,
    handleSortRequest,
    handleScroll,
    TblHeadforcustomer
  } = useTable(
    props.Customers_List,
    headCells,
    filterFn,
    componentRef,
    "medium",
    props.totalPages,
    props.currentPage,
    props.totalCustomers,
    props.getAllCustomers,
    props.EmptyCustomer,
    true,
    setLoading
  )

  // const sortByCustomerSortNumber = () => {
  //   const sortedArray = [...props.Customers_List].sort((a, b) => a.Customer_Sort_Number - b.Customer_Sort_Number);
  //   SetCustomers_List(sortedArray)
  // }

  const handleSearch = async (e) => {
    //     const searchCustomerNameValue = e.target.value
    //   if (searchCustomerNameValue !== '') {
    //     setSearchCustomerName(searchCustomerNameValue)
    //   } else {
    //       await setSearchCustomerName(null)
    //       await props.EmptyCustomer()
    //       await setPage(0)
    //      await  props.getAllCustomers({page:0,limit:rowsPerPage},setLoading)
    //   }
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter(
            (x) =>
              `${x.Customer_Name}${x.Customer_Mobile}`
                .toUpperCase()
                .includes(target.value) ||
              misc
                .capitalize(`${x.Customer_Name}${x.Customer_Mobile}`)
                .includes(target.value) ||
              `${x.Customer_Name}${x.Customer_Mobile}`
                .toLowerCase()
                .includes(target.value)
          );
      },
    });
  };

  const searchCustomer = () => {
    // console.log("search customer");
    const data = { searchCustomerName: searchCustomerName };
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/searchcustomer", data, { headers })
      .then((res) => {
        props.EmptyCustomer();
        props.SetCustomers(res.data, setLoading);
      })
      .catch((err) => {
        // console.log("ERROR: ====", err);
      });
  };

  useEffect(() => {
    // sortByCustomerSortNumber()
    if (searchCustomerName !== null) {
      searchCustomer();
    }
  }, [searchCustomerName])

  // Bill Print code start

  // generatering dates from 1 to end of month dinamically function start
  const update_dates = (bills, Balance, Receipts, download, filename) => {
    let dates = [];
    let s_date = new Date(
      Receipt_Date.getFullYear(),
      Receipt_Date.getMonth(),
      0
    ); // 1 date of month
    const e_date = new Date(
      Receipt_Date.getFullYear(),
      Receipt_Date.getMonth() + 1,
      0
    ); // end date of month

    for (let i = s_date; i < e_date;) {
      s_date.setDate(s_date.getDate() + 1);
      dates.push(misc.formatDateyearmonthday(s_date));
    }
    // console.log("update_dates" + Balance);
    if (download == "download") {
      mergeArrays(dates, bills, Balance, Receipts, 'download', filename);
    }
    else {
      mergeArrays(dates, bills, Balance, Receipts);
    }
  };
  // generatering dates from 1 to end of month dinamically function end

  // merge dates and Billing_Info arrays based on date match function start
  const mergeArrays = async (arr1 = [], arr2 = [], Balance, Receipts, download, filename) => {
    // console.log("mergeArrays" + Balance);
    let res = [];
    res = arr1.map((date) => {
      const index = arr2.findIndex(
        (el) => misc.formatDateyearmonthday(el.Trans_Date) == date
      );
      const item = index !== -1 ? arr2[index] : {};
      return {
        date,
        ...item,
      };
    });
    // console.log(res)
    await Set_Billing_Info([
      res,
      {
        T_Milk: arr2.reduce((a, b) => a + (b.Milk || 0), 0),
        T_Yog: arr2.reduce((a, b) => a + (b.Yog || 0), 0),
        T_Others: arr2.reduce((a, b) => a + (b.Ghee || 0), 0),
        T_Amount: arr2.reduce((a, b) => a + (b.Amount || 0), 0),
        Balance: Balance != null ? Balance : 0,
        Receipts: Receipts != null ? Receipts : 0,
      },
    ]);

    if (download == "download") {
      const rootElementId = "testId";
      const downloadFileName = filename;
      const { downloadPdfDocument } = GenericPdfDownloader({ rootElementId, downloadFileName })
      downloadPdfDocument()
    }

    else {
      PrintReceipt();
    }
  };
  // merge dates and Billing_Info arrays based on date match function end

  //  fetch receipt data function start
  const getMonthlyReceipt = (customer) => {
    // console.log(Receipt_Date);
    // console.log("get receipt data");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/getmonthlyreceipt",
        { Customer_ID: customer.Customer_ID, Receipt_Date: Receipt_Date },
        { headers }
      )
      .then(async (res) => {
        // console.log(res.data)
        if (res.data.records.length !== 0) {
          await setReceiptCustomer(customer);
          update_dates(
            res.data.records,
            res.data.PrevBalance.Balance,
            res.data.Receipts.Receipts
          );
        } else {
          setNotify({
            isOpen: true,
            message: "No record found in the selected month",
            type: "error",
          });
        }
      })
      .catch((err) => {
        // console.log("ERROR: ====", err);
      });
  }

  const getMonthlyReceiptForAll = () => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/getmonthlyreceiptforall",
        { Receipt_Date: Receipt_Date },
        { headers }
      )
      .then(async (res) => {
        // console.log(res.data)
        Set_Open_Popup_All_Bills(true)
        if (res.data.length !== 0) {
          // await setReceiptCustomer(customer);
          // update_dates(
          //   res.data.records,
          //   res.data.PrevBalance.Balance,
          //   res.data.Receipts.Receipts
          // );
          setAllCustomersList(res.data)
        } else {
          setNotify({
            isOpen: true,
            message: "No record found in the selected month",
            type: "error",
          });
        }
      })
      .catch((err) => {
        // console.log("ERROR: ====", err);
      });
  }


  const downloadMonthlyReceipt = (customer) => {
    // console.log(customer);
    // console.log(Receipt_Date);
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/getmonthlyreceipt",
        { Customer_ID: customer.Customer_ID, Receipt_Date: Receipt_Date },
        { headers }
      )
      .then(async (res) => {
        if (res.data.records.length !== 0) {
          // console.log(Billing_Info)
          await setReceiptCustomer(customer)
          update_dates(
            res.data.records,
            res.data.PrevBalance.Balance,
            res.data.Receipts.Receipts,
            "download",
            customer.Customer_Name + " - " + `${monthNames[Receipt_Date.getMonth()]
            } ${Receipt_Date.getFullYear()}`
          );
        } else {
          setNotify({
            isOpen: true,
            message: "No record found in the selected month",
            type: "error",
          });
        }
      })
      .catch((err) => {
        // console.log("ERROR: ====", err);
      });
  };

  //decimal function start

  // function decimalToMixedFraction(decimal) {
  //   const tolerance = 1.0e-9; // Adjust the tolerance as needed

  //   const wholeNumber = Math.floor(decimal);
  //   let fractionalPart = decimal - wholeNumber;

  //   let numerator = 0;
  //   let denominator = 1;

  //   while (Math.abs(fractionalPart - Math.round(fractionalPart)) > tolerance) {
  //     fractionalPart *= 10;
  //     numerator = Math.round(fractionalPart);
  //     denominator *= 10;
  //   }

  //   const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
  //   const commonFactor = gcd(numerator, denominator);

  //   numerator /= commonFactor;
  //   denominator /= commonFactor;

  //   let sup = `<span style={{ verticalAlign: "super" }}>${numerator / denominator}</span>`;

  //   if (Math.round(wholeNumber) === decimal) {
  //     return misc.currencyOnlyDecimal(
  //       wholeNumber
  //     )
  //   } else if (wholeNumber === 0) {
  //     return `${numerator}/${denominator}`;
  //   } else {
  //     return `${wholeNumber} ${sup}`;
  //   }
  // }

  // decimal function end

  let arrayItems;
  arrayItems =
    Billing_Info != null
      ? Billing_Info[0].map((receipt) => {
        // const fractionString = decimalToMixedFraction(receipt.Milk)
        let html = (
          <tr>
            {/* receipt.Event == "1"?"M":"E" +  */}
            <td style={{ textAlign: "center", fontSize: "10pt", fontWeight: 500 }}>
              {misc.LZero(new Date(receipt.date).getDate(), 2)}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
                fontWeight: 500
              }}
            >
              {receipt.Milk ?
                misc.currencyOnlyDecimal(
                  // fractionString
                  receipt.Milk
                )
                : "-"}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
                fontWeight: 500
              }}
            >
              {receipt.Yog ?
                misc.currencyOnlyDecimal(
                  receipt.Yog
                )
                : "-"}
            </td>
            <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
                fontWeight: 500
              }}
            >
              {receipt.Ghee ?
                misc.currencyOnlyDecimal(
                  receipt.Ghee
                )
                : "-"}
            </td>
            {/* <td
              style={{
                textAlign: "right",
                paddingRight: "5px",
                fontSize: "10pt",
                fontWeight: 500
              }}
            >
              {receipt.Amount
                ?
                misc.currencyOnlyDecimal(
                  receipt.Amount
                )
                : "-"}
            </td> */}
          </tr>
        );
        return html;
      })
      : null;
  // generating receipt table rows dynamically end

  // print receipt function start
  const PrintReceipt = () => {
    var sOption = "width=1000,height=1000,left=100,top=25";
    var sWinHTML = document.getElementById("invoice-POS").innerHTML;
    var winprint = window.open("", "", sOption);
    winprint.document.open();
    winprint.document.write("<html style='margin: 0; padding: 0;'><head style='margin: 0; padding: 0;'><style style='margin: 0; padding: 0;'>");
    winprint.document.write(`
    @media screen {
      body {
        margin: 0;
      }
    
      @page {
        width: 80mm; /* Adjust based on your requirements */
        height: 210mm; /* Adjust based on your requirements */
      }
    
      /* customer_List - table */
      table {
        border-collapse: collapse;
        /* Uncomment and adjust if needed */
        /* width: -webkit-fill-available; */
      }
    
      /* customer_List - table rows and column */
      td, th {
        padding: 2px;
        border: 1px solid black;
        text-align: left;
      }
    }
    
    @media print {
      table {
        border-collapse: collapse;
        /* Uncomment and adjust if needed */
        /* width: -webkit-fill-available; */
      }
    
      /* customer_List - table rows and column */
      td, th {
        padding: 2px;
        border: 1px solid black;
        text-align: left;
      }
    
      body {
        margin: 0;
      }
    
      .pagebreak {
        page-break-after: always;
      }
    }
    
          `);

    winprint.document.write(
      `</style></head><body style="width: 250px; margin: 0; padding: 0;" onload="window.print()">`
    );
    winprint.document.write(sWinHTML)
    winprint.document.write("</body></html>")
    //    winprint.document.close()
    winprint.focus()
    winprint.print()
    //    if( !/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //     winprint.close();
    //    }
    setReceiptCustomer({})
    return true
  }

  const styles1 = {
    page: {
      marginLeft: '4pt',
      // marginRight: '4pt',
      'page-break-after': 'always',
    },
  };

  const PrintReceiptAll = (finalPrintList) => {

    // console.log(finalPrintList)

    var sOption = "width=1000,height=auto,left=100,top=25"
    // var sWinHTML = document.getElementById("invoice-POS-all").innerHTML
    var winprint = window.open("", "", sOption)
    winprint.document.open()
    winprint.document.write("<html style='margin: 0; padding: 0;'><head style='margin: 0; padding: 0;'><style style='margin: 0; padding: 0;'>")
    winprint.document.write(`
    @media screen {
      body {
        margin: 0;
      }
    
      @page {
        width: 80mm; /* Adjust based on your requirements */
        height: 210mm; /* Adjust based on your requirements */
      }
    
      /* customer_List - table */
      table {
        border-collapse: collapse;
        /* Uncomment and adjust if needed */
        /* width: -webkit-fill-available; */
      }
    
      /* customer_List - table rows and column */
      td, th {
        padding: 2px;
        border: 1px solid black;
        text-align: left;
      }
    }
    
    @media print {
      table {
        border-collapse: collapse;
        /* Uncomment and adjust if needed */
        /* width: -webkit-fill-available; */
      }
    
      /* customer_List - table rows and column */
      td, th {
        padding: 2px;
        border: 1px solid black;
        text-align: left;
      }
    
      body {
        margin: 0;
      }
    
      .pagebreak {
        page-break-after: always;
      }
    }
    
          `)

    winprint.document.write(
      `</style></head><body style="width: 250px; margin: 0; padding: 0;" onload="window.print()">`
    )
    // winprint.document.write(JSON.stringify(sWinHTML))
    winprint.document.write("</body></html>")
    //    winprint.document.close();
    winprint.focus()
    winprint.print()
    //    if( !/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //     winprint.close();
    //    }

    // setReceiptCustomer({})

    return true
  }

  // print receipt function end

  // bill print code end
  const insertCustomer = (customer) => {
    // console.log("insert run");
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post("/api/addcustomer", customer, { headers: headers })
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });

        if (res.data.iserror === false) {
          props.EmptyCustomer();
          setPage(0);
          props.getAllCustomers({ page: 0, limit: rowsPerPage }, setLoading);
        }
      });
  };
  const updateCustomer = async (customer) => {
    // console.log(customer)
   
    try{
      setLoading(true)
      const headers = {
        "x-access-token": sessionStorage.getItem("accountingauthkey"),
      };
  const res=   await  axios
        .post("/api/updatecustomer", customer, { headers: headers })
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          // Update the customer in the existing list instead of refetching
          console.log("run-----")
          const updatedCustomers = {Customers:props.Customers_List.map(c => 
              c.Customer_ID === customer.Customer_ID ? { ...c, ...customer } : c
          )}
          props.SetCustomers(updatedCustomers, setLoading); // Update the state with the modified list
          // setPage(0); // Optional: If you want to reset the page
        }
    }catch(e){
console.log(e)
    }finally{
      setLoading(false)
    }
  };
  const addOrEdit = (customer, resetForm) => {
    if (!customer.Customer_ID) {
      insertCustomer(customer);
    } else if (customer.Customer_ID) {
      updateCustomer(customer);
    }
    resetForm();
    setRecordForEdit(null);
    setopenPopupForm(false);
  };

  const openInPopupForm = (item) => {
    setRecordForEdit(item);
    setopenPopupForm(true);
  };
  // console.log(Billing_Info)
  const openInPopupReceipt = (Customer) => {
    Set_Billing_Customer(Customer);
    Set_Open_Popup_Receipt_Date(true);
  };
  const deleteCustomer = (CuntomerName, Customer_ID) => {
    const headers = {
      "x-access-token": sessionStorage.getItem("accountingauthkey"),
    };
    axios
      .post(
        "/api/deletecustomer",
        { Customer_ID: Customer_ID },
        { headers: headers }
      )
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: res.data.iserror ? "error" : "success",
        });
        if (res.data.iserror === false) {
          props.EmptyCustomer();
          setPage(0);
          props.getAllCustomers({ page: 0, limit: rowsPerPage }, setLoading);
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: err.response.data.iserror ? "error" : "success",
        });
      });
  };
  const onDeleteCustomer = (CuntomerName, Customer_ID) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteCustomer(null, Customer_ID);
  };
  let styles = {
    // fontWeight: "bold",
    // padding: 5,
    textAlign: "left",
    verticalAlign: "middle",
    fontSize: 14,
    color: "black",
    fontWeight: "500",
    padding: 4,
    // margin: 0
  }

  // console.log(allCustomersList)

  var checkedArray = []

  function checkAll() {
    var checkboxes = document.getElementsByClassName("checkbox")
    var anyChecked = Array.from(checkboxes).some(checkbox => checkbox.checked)
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = !anyChecked
    }
    updateCheckedArray()
  }

  function updateCheckedArray() {
    checkedArray = []
    var checkboxes = document.getElementsByClassName("checkbox")
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkedArray.push({
          id: checkboxes[i].id
        })
      }
    }
    console.log("Checked Array:", checkedArray)
  }

  function updateCheckedArrayOnChange(checkbox) {
    // console.log(checkbox.target.id)
    if (checkbox.target.checked) {
      checkedArray.push({
        id: checkbox.target.id
      })
    } else {
      checkedArray = checkedArray.filter(item => item.id !== checkbox.target.id)
    }
    console.log("Checked Array:", checkedArray)
  }

  function printAll() {
    function fetchRecords(array1, array2) {
      const resultArray = []

      for (const item2 of array2) {
        const foundItem1 = array1.find(item1 => item1.Trans_Customer_ID === item2.id)

        if (foundItem1) {
          resultArray.push(foundItem1)
        }
      }
      return resultArray
    }
    const resultArray = fetchRecords(allCustomersList, checkedArray)
    // setFinalPrintList(resultArray)
    PrintReceiptAll(resultArray)
  }

  console.log(Billing_Info)

  return (
    <div
      className={classes.root}
    >
      <PageHeader
        title={props.customerLanguage.Customer.Customer_Header.Title}
        icon={<AccountBox color="primary" fontSize="large" />}
      >
        <Grid
          container
          spacing={1}
          className={classes.ToolbarItems}
          lg={5}
          md={7}
          sm={12}
          xs={12}
        >
          <Grid spacing={1} item lg={4} md={4} sm={4} xs={4}>
            <Controls.Input
              label={
                props.customerLanguage.Customer.Customer_Header.SearchInputTitle
              }
              id="fonting"
              width="100%"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid spacing={1} container lg={8} md={6} sm={6} xs={6}>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <IconButton
                variant="outlined"
                className={classes.newButtonMobile}
                onClick={() => {
                  setopenPopupForm(true);
                  setRecordForEdit(null);
                }}
              >
                <Box borderRadius="50%" {...defaultProps}>
                  <AddIcon />
                </Box>
              </IconButton>
              <Controls.Button
                text={
                  props.customerLanguage.Customer.Customer_Header.Add_Button
                  // "Hello"
                }
                width="100%"
                height="4rem"
                startIcon={<AddIcon />}
                className={classes.newButton}
                onClick={() => {
                  setopenPopupForm(true);
                  setRecordForEdit(null);
                }}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <IconButton
                className={classes.newButtonMobile}
                onClick={() => history.push("/customersprint")}
              >
                <Box borderRadius="50%" {...defaultProps}>
                  <Print />
                </Box>
              </IconButton>
              <Controls.Button
                text={
                  props.customerLanguage.Customer.Customer_Header.Print_Button
                }
                width="100%"
                height="4rem"
                startIcon={<Print />}
                className={classes.newButton}
                onClick={() => history.push("/customersprint")}
              />
            </Grid>
            {/* <Grid item lg={4} md={6} sm={6} xs={6}>
              <IconButton
                className={classes.newButtonMobile}
                onClick={() => getMonthlyReceiptForAll()}
              >
                <Box borderRadius="50%" {...defaultProps}>
                  <Print />
                </Box>
              </IconButton>
              <Controls.Button
                text={
                  "Monthly Bills"
                }
                width="100%"
                height="4rem"
                startIcon={<Print />}
                className={classes.newButton}
                onClick={() => getMonthlyReceiptForAll()}
              />
            </Grid> */}
            {/* <IconButton
                        
                        className={classes.newButtonMobile}
                        onClick={history.push("/customersprint")}
                    ><Box borderRadius="50%" {...defaultProps}><FileCopy /></Box></IconButton>
                    <Controls.Button
                        text="Excel"
                        width="100%"
                        height="4rem"
                        startIcon={<FileCopy/>}
                        className={classes.newButton}
                        onClick={()=>misc.exportTableToExcel('table')}
                    /> */}
          </Grid>
        </Grid>
      </PageHeader>
      <Paper
        elevation={7}
        className={classes.pageContent}
      >
        <div
          className={`table-container ${localStorage.getItem("dodaily_urdu") == "true"
            ? "UrduFontTableHead"
            : ""
            }`}
          onScroll={handleScroll}
        >
          {/* <TblContainer> */}

          {/* </TblContainer> */}
          <div>
            <table className="table table-bordered" id="table-main">
              {localStorage.getItem("dodaily_urdu") == "true"
                ? <TblHead align="right" />
                :
                <TblHeadforcustomer align="left" />
              }
              {
                props.Customers_List !== null ? (
                  recordsAfterPagingAndSorting().map((Customers_List_Customer, i2) =>
                    localStorage.getItem("dodaily_urdu") == "false" ? (
                      <tbody>
                        <tr
                          key={i2 + 2}
                          style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <td scope="row" style={{ ...styles, textAlign: "center" }}>
                            {(Customers_List_Customer.Customer_Sort_Number).toString().length < 2 ? "00" + (Customers_List_Customer.Customer_Sort_Number) : (Customers_List_Customer.Customer_Sort_Number).toString().length < 3 ? "0" + (Customers_List_Customer.Customer_Sort_Number) : (Customers_List_Customer.Customer_Sort_Number)}
                          </td>
                          <td style={styles}>
                            {Customers_List_Customer.Customer_Name} - {Customers_List_Customer.Customer_Address}
                          </td>
                          <td style={styles}>
                            {Customers_List_Customer.Customer_Mobile}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Morning_Milk_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Morning_Yog_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Morning_Ghee_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Evening_Milk_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Evening_Yog_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Evening_Ghee_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {
                              // misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Milk_Rate
                              // )
                            }
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {
                              // misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Yog_Rate
                              // )
                            }
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {
                              // misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Ghee_Rate
                              // )
                            }
                          </td>
                          <td style={{ ...styles, textAlign: "center" }}>
                            {Customers_List_Customer.Customer_Active === 0
                              ? "No"
                              : "Yes"}
                          </td>
                          <td style={{ ...styles, maxWidth: 180 }}>
                            <>
                              <Controls.ActionButton
                                colorPrimary="colorPrimary"
                                onClick={() => {
                                  openInPopupForm(Customers_List_Customer);
                                }}
                              >
                                <EditOutlinedIcon fontSize="small" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                colorPrimary="colorSecondary"
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: "Are you sure to delete ?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      onDeleteCustomer(
                                        null,
                                        Customers_List_Customer.Customer_ID
                                      );
                                    },
                                  });
                                }}
                              >
                                <Delete fontSize="small" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                colorPrimary="colorSecondary"
                                onClick={() =>
                                  openInPopupReceipt(Customers_List_Customer)
                                }
                              >
                                <Print fontSize="small" />
                              </Controls.ActionButton>
                            </>
                          </td>
                        </tr>
                      </tbody>
                    )
                      :
                      <tbody>
                        <tr
                          key={i2 + 1}
                          style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <td scope="row" style={{ verticalAlign: "middle", width: 180 }}>
                            <>
                              <Controls.ActionButton
                                colorPrimary="colorPrimary"
                                onClick={() => {
                                  openInPopupForm(Customers_List_Customer);
                                }}
                              >
                                <EditOutlinedIcon fontSize="small" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                colorPrimary="colorSecondary"
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: "Are you sure to delete ?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      onDeleteCustomer(
                                        null,
                                        Customers_List_Customer.Customer_ID
                                      );
                                    },
                                  });
                                }}
                              >
                                <Delete fontSize="small" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                colorPrimary="colorSecondary"
                                onClick={() =>
                                  openInPopupReceipt(Customers_List_Customer)
                                }
                              >
                                <Print fontSize="small" />
                              </Controls.ActionButton>
                            </>
                          </td>
                          <td style={{ ...styles, textAlign: "center" }}>
                            {Customers_List_Customer.Customer_Active === 0
                              ? "No"
                              : "Yes"}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {
                              // misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Ghee_Rate
                              // )
                            }
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {
                              // misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Yog_Rate
                              // )
                            }
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {
                              // misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Milk_Rate
                              // )
                            }
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {
                              misc.currencyOnlyDecimal(
                                Customers_List_Customer.Customer_Evening_Ghee_QTY
                              )
                            }
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Evening_Yog_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Evening_Milk_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Morning_Ghee_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Morning_Yog_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            {misc.currencyOnlyDecimal(
                              Customers_List_Customer.Customer_Morning_Milk_QTY
                            )}
                          </td>
                          <td style={{ ...styles, textAlign: "center" }}>
                            {Customers_List_Customer.Customer_Mobile}
                          </td>
                          <td style={{ ...styles, textAlign: "right" }}>
                            <p>{Customers_List_Customer.Customer_Urdu_Name} - {Customers_List_Customer.Customer_Address}</p>
                          </td>
                        </tr>
                      </tbody>
                  )
                )
                  :
                  (
                    <Controls.Loader />
                  )
              }
            </table>

            <table
              className="table"
              id="table-main-small">

              {
                props.Customers_List !== null ? (
                  recordsAfterPagingAndSorting().map((Customers_List_Customer, i2) =>
                    localStorage.getItem("dodaily_urdu") == "false" ? (
                      <>
                        <tbody key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Sort No.</td>
                            <td scope="col" id="mobile-style">
                              {
                                (Customers_List_Customer.Customer_Sort_Number).toString().length < 2 ? "00" + (Customers_List_Customer.Customer_Sort_Number) : (Customers_List_Customer.Customer_Sort_Number).toString().length < 3 ? "0" + (Customers_List_Customer.Customer_Sort_Number) : (Customers_List_Customer.Customer_Sort_Number)
                              }
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Customer</td>
                            <td scope="col" id="mobile-style">
                              {Customers_List_Customer.Customer_Name} - {Customers_List_Customer.Customer_Address}
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Mobile</td>
                            <td scope="col" id="mobile-style">
                              {Customers_List_Customer.Customer_Mobile}
                            </td>
                          </tr>
                          {Customers_List_Customer.Customer_Morning_Milk_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Morning Milk</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Morning_Milk_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Morning_Yog_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Morning Yogurt</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Morning_Yog_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Morning_Ghee_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Morning Others</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Morning_Ghee_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Evening_Milk_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Evening Milk</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Evening_Milk_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Evening_Yog_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Evening Yogurt</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Evening_Yog_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Evening_Ghee_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Evening Others</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Evening_Ghee_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Milk_Rate ||
                            Customers_List_Customer.Customer_Evening_Milk_QTY ||
                            Customers_List_Customer.Customer_Morning_Milk_QTY
                            !== 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Milk Rate</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Milk_Rate
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Yog_Rate ||
                            Customers_List_Customer.Customer_Morning_Yog_QTY ||
                            Customers_List_Customer.Customer_Evening_Yog_QTY
                            > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Yogurt Rate</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Yog_Rate
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Ghee_Rate ||
                            Customers_List_Customer.Customer_Morning_Ghee_QTY ||
                            Customers_List_Customer.Customer_Evening_Ghee_QTY
                            > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Others Rate</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Ghee_Rate
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Active</td>
                            <td scope="col" id="mobile-style">
                              {Customers_List_Customer.Customer_Active === 0
                                ? "No"
                                : "Yes"}
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Action</td>
                            <td scope="col" id="mobile-style">
                              <>
                                <Controls.ActionButton
                                  colorPrimary="colorPrimary"
                                  onClick={() => {
                                    openInPopupForm(Customers_List_Customer);
                                  }}
                                >
                                  <EditOutlinedIcon fontSize="small" />
                                </Controls.ActionButton>
                                <Controls.ActionButton
                                  colorPrimary="colorSecondary"
                                  onClick={() => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title: "Are you sure you want to delete?",
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        onDeleteCustomer(
                                          null,
                                          Customers_List_Customer.Customer_ID
                                        );
                                      },
                                    });
                                  }}
                                >
                                  <Delete fontSize="small" />
                                </Controls.ActionButton>
                                <Controls.ActionButton
                                  colorPrimary="colorSecondary"
                                  onClick={() =>
                                    openInPopupReceipt(Customers_List_Customer)
                                  }
                                >
                                  <Print fontSize="small" />
                                </Controls.ActionButton>
                              </>
                            </td>
                          </tr>
                        </tbody>
                        <hr
                          style={{ margin: 5, padding: 0 }}
                        />
                      </>
                    )
                      :
                      <>
                        <tbody key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Sort No.</td>
                            <td scope="col" id="mobile-style">
                              {
                                (Customers_List_Customer.Customer_Sort_Number).toString().length < 2 ? "00" + (Customers_List_Customer.Customer_Sort_Number) : (Customers_List_Customer.Customer_Sort_Number).toString().length < 3 ? "0" + (Customers_List_Customer.Customer_Sort_Number) : (Customers_List_Customer.Customer_Sort_Number)
                              }
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Customer</td>
                            <td scope="col" id="mobile-style">
                              {Customers_List_Customer.Customer_Name} - {Customers_List_Customer.Customer_Address}
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Mobile</td>
                            <td scope="col" id="mobile-style">
                              {Customers_List_Customer.Customer_Mobile}
                            </td>
                          </tr>
                          {Customers_List_Customer.Customer_Morning_Milk_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Morning Milk</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Morning_Milk_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Morning_Yog_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Morning Yogurt</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Morning_Yog_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Morning_Ghee_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Morning Others</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Morning_Ghee_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Evening_Milk_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Evening Milk</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Evening_Milk_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Evening_Yog_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Evening Yogurt</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Evening_Yog_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Evening_Ghee_QTY > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Evening Others</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Evening_Ghee_QTY
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Milk_Rate ||
                            Customers_List_Customer.Customer_Evening_Milk_QTY ||
                            Customers_List_Customer.Customer_Morning_Milk_QTY
                            !== 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Milk Rate</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Milk_Rate
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Yog_Rate ||
                            Customers_List_Customer.Customer_Morning_Yog_QTY ||
                            Customers_List_Customer.Customer_Evening_Yog_QTY
                            > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Yogurt Rate</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Yog_Rate
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          {Customers_List_Customer.Customer_Ghee_Rate ||
                            Customers_List_Customer.Customer_Morning_Ghee_QTY ||
                            Customers_List_Customer.Customer_Evening_Ghee_QTY
                            > 0
                            ?
                            <tr key={i2 + 1}>
                              <td scope="col" id="mobile-style" >Others Rate</td>
                              <td scope="col" id="mobile-style">
                                {misc.currencyOnlyDecimal(
                                  Customers_List_Customer.Customer_Ghee_Rate
                                )}
                              </td>
                            </tr>
                            :
                            ""
                          }
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Active</td>
                            <td scope="col" id="mobile-style">
                              {Customers_List_Customer.Customer_Active === 0
                                ? "No"
                                : "Yes"}
                            </td>
                          </tr>
                          <tr key={i2 + 1}>
                            <td scope="col" id="mobile-style" >Action</td>
                            <td scope="col" id="mobile-style">
                              <>
                                <Controls.ActionButton
                                  colorPrimary="colorPrimary"
                                  onClick={() => {
                                    openInPopupForm(Customers_List_Customer);
                                  }}
                                >
                                  <EditOutlinedIcon fontSize="small" />
                                </Controls.ActionButton>
                                <Controls.ActionButton
                                  colorPrimary="colorSecondary"
                                  onClick={() => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title: "Are you sure you want to delete?",
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        onDeleteCustomer(
                                          null,
                                          Customers_List_Customer.Customer_ID
                                        );
                                      },
                                    });
                                  }}
                                >
                                  <Delete fontSize="small" />
                                </Controls.ActionButton>
                                <Controls.ActionButton
                                  colorPrimary="colorSecondary"
                                  onClick={() =>
                                    openInPopupReceipt(Customers_List_Customer)
                                  }
                                >
                                  <Print fontSize="small" />
                                </Controls.ActionButton>
                              </>
                            </td>
                          </tr>
                        </tbody>
                        <hr
                          style={{ margin: 5, padding: 0 }}
                        />
                      </>
                  )
                )
                  :
                  (
                    <Controls.Loader />
                  )
              }
            </table>

          </div>

          {!loading && props.Customers_List.length === 0 ? (
            <Typography
              variant="h4"
              style={{ margin: "auto", display: "block", textAlign: "center" }}
            >
              No Record
            </Typography>
          ) : null}

        </div>
        <table
          id="table"
          style={{
            width: " 100%",
            borderCollapse: "collapse",
            border: "1px solid black",
            display: "none",
          }}
        >
          <tr>
            {headCells.map((Customers_List_Customer, index) =>
              Customers_List_Customer.label !== "Actions" ? (
                <th key={index} style={{ border: "1px solid black" }}>
                  {Customers_List_Customer.label}
                </th>
              ) : null
            )}
          </tr>
          {props.Customers_List
            ?
            // Customers_List.map(
            recordsAfterPagingAndSorting().map(
              (Customers_List_Customer, index) =>
                localStorage.getItem("dodaily_urdu") === false ? (
                  <tr key={index}>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Customer Name"
                    >
                      {Customers_List_Customer.Customer_Name}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Mobile"
                    >
                      {Customers_List_Customer.Customer_Mobile}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Morning Milk QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Morning_Milk_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Morning Yog QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Morning_Yog_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Morning Others QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Morning_Ghee_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Evening Milk QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Evening_Milk_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Evening Yog QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Evening_Yog_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Evening Others QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Evening_Ghee_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Milk Rate"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Milk_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Yog Rate"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Yog_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="amount"
                      data-heading="Others Rate"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Ghee_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      data-heading="Active"
                    >
                      {Customers_List_Customer.Customer_Active === 0
                        ? "No"
                        : "Yes"}
                    </td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="rate"
                      data-heading="دوسروں کی قیمت"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Ghee_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="rate"
                      data-heading="Yog Rate"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Yog_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="rate"
                      data-heading="Milk Rate"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Milk_Rate
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="qty"
                      data-heading="Evening Others QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Evening_Ghee_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="qty"
                      data-heading="Evening Yog QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Evening_Yog_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="qty"
                      data-heading="Evening Milk QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Evening_Milk_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="qty"
                      data-heading="Morning Others QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Morning_Ghee_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="qty"
                      data-heading="Morning Yog QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Morning_Yog_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="qty"
                      data-heading="Morning Milk QTY"
                    >
                      {misc.currencyOnlyDecimal(
                        Customers_List_Customer.Customer_Morning_Milk_QTY
                      )}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="mobile"
                      data-heading="Mobile"
                    >
                      {Customers_List_Customer.Customer_Mobile}
                    </td>
                    <td
                      style={{ border: "1px solid black", color: "black" }}
                      name="name"
                      data-heading="Customer Name"
                    >
                      <p>{Customers_List_Customer.Customer_Urdu_Name}-</p>
                      <p>{Customers_List_Customer.Customer_Address}</p>
                    </td>
                  </tr>
                )
            )
            : null}
        </table>
      </Paper >
      {loading && <Controls.FullScreenLoader />} 

      <Popup
        title={props.customerLanguage.Customer.Customer_Header.Title}
        openPopup={openPopupForm}
        setOpenPopup={setopenPopupForm}
      >
        <CustomerForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          formLanguage={props.customerLanguage.Customer.Customer_Form}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        // title={props.customerLanguage.Customer.Customer_Bill_Form.title}
        title="Select Bill Month"
        openPopup={Open_Popup_Receipt_Date}
        setOpenPopup={Set_Open_Popup_Receipt_Date}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item lg={4} md={6} sm={6} xs={8} justifyContent="center">
            <Controls.DateImputMaterialUi
              views={["month", "year"]}
              format="MM/yyyy"
              value={Receipt_Date}
              onChange={(Receipt_Date) => {
                Set_Receipt_Date(Receipt_Date)
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={8}>
            <Controls.Button
              onClick={() => getMonthlyReceipt(Billing_Customer)}
              text={
                props.customerLanguage.Customer.Customer_Bill_Form.Print_Button
              }
              width="100%"
              height="4rem"
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={8}>
            <Controls.Button
              onClick={() => downloadMonthlyReceipt(Billing_Customer)}
              text="Download"
              width="100%"
              height="4rem"
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Controls.Button
              style={{ display: "none" }}
            />
          </Grid>
          
        </Grid> */}
      </Popup>

      <Popup
        title={"Monthly Bills"}
        openPopup={open_Popup_All_Bills}
        setOpenPopup={Set_Open_Popup_All_Bills}
      >
        {/* <Grid container spacing={4} justifyContent="center"> */}
        <table
          // className="table"
          id="list-table"
          style={{ fontSize: 12 }}
        >
          <thead>
            <tr>
              <th>Sort No.</th>
              <th>Customer</th>
              <th>Event</th>
              <th>Month</th>
              <th>Milk</th>
              <th>Yogurt</th>
              <th>Others</th>
              <th>Amount</th>
              <th>Print</th>
            </tr>
          </thead>
          {
            allCustomersList !== null ? (
              allCustomersList.map((Customers_List_Customer, i2) =>
                <>
                  <tbody>
                    <tr>
                      <td>{Customers_List_Customer.Customer_Sort_Number}</td>
                      <td>{Customers_List_Customer.Customer_Name}</td>
                      <td>{Customers_List_Customer.Event == 1 ? "Morning" : "Evening"}</td>
                      <td>{Customers_List_Customer.Month}</td>
                      <td>{Customers_List_Customer.Milk_Total}</td>
                      <td>{Customers_List_Customer.Yog_Total}</td>
                      <td>{Customers_List_Customer.Ghee_Total}</td>
                      <td>{Customers_List_Customer.Amount_Total}</td>
                      <td><input type="checkbox" className="checkbox" id={Customers_List_Customer.Trans_Customer_ID} onChange={(e) => updateCheckedArrayOnChange(e)} /></td>
                    </tr>
                  </tbody>
                </>
              )
            )
              :
              ""
          }
        </table>
        <button style={{ float: "right" }} onClick={() => checkAll()}>Check All</button>
        <button style={{ float: "right" }} onClick={() => printAll()}>Print</button>
        {/* </Grid> */}
      </Popup >

      {/* Print html Start */}
      < div >
        <div style={styles1.page}>
          <div
            id="invoice-POS"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              // border: "1px solid black",
              display: "none",
              margin: 0,
              padding: 0,
              // marginLeft: 25,
              // height: "300mm",
              // pageBreakBefore: 'always'
            }}
          >
            {/* <div id="pagebreak" className="pagebreak" style={{ margin: 0, padding: 0 }}></div> */}
            <p style={{ textAlign: "center", margin: 0, padding: 0, paddingBottom: 5, fontSize: 22, fontWeight: "bold" }}>
              {sessionStorage.getItem("usershop")}
            </p>
            <div
              style={{
                marginBottom: "4px",
                height: "0px",
                backgroundColor: "black",
                color: "black",
                width: "100%",
                outline: "none",
                border: "1px solid black",
                display: "block",
              }}
            ></div>
            <div
              style={{ width: "100%", display: "inline-block", float: "center", paddingBottom: "2pt", fontWeight: "bold" }}
              id="info"
            >
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  // marginBottom: "4pt",
                  textAlign: "center",
                }}
              >{"Billing Month: "}
                {`${monthNames[Receipt_Date.getMonth()]
                  } ${Receipt_Date.getFullYear()}`}
              </p>
            </div>
            <div
              style={{ width: "50%", display: "inline-block", float: "left" }}
              id="info"
            >
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  // marginBottom: "4pt",
                  textAlign: "left",
                }}
              >
                Cust: {receiptCustomer.Customer_Name}
              </p>
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  // marginBottom: "4pt",
                  textAlign: "left",
                }}
              >
                Mob: {receiptCustomer.Customer_Mobile}
              </p>

            </div>
            <div
              style={{ width: "50%", display: "inline-block", float: "left", paddingBottom: "2pt" }}
              id="info"
            >
              {/* <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  // marginBottom: "4pt",
                  textAlign: "right",
                }}
              >
                Milk Rate: {receiptCustomer.Customer_Milk_Rate}
              </p>
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  // marginBottom: "4pt",
                  textAlign: "right",
                }}
              >
                Yogurt Rate: {receiptCustomer.Customer_Yog_Rate}
              </p> */}
              {/* <p
            style={{
              fontSize: "10pt",
              margin: "0",
              // marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left", width: "50%" }}>Bal :</span>
            <span style={{ float: "left", width: "50%" }}>
              {Billing_Info != null
                ? misc.currencyOnlyDecimal(Billing_Info[1].Balance)
                : 0}
            </span>
          </p> */}
              {/* <p
            style={{
              fontSize: "10pt",
              margin: "0",
              // marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left", width: "50%" }}>Rcpt :</span>
            <span style={{ float: "left", width: "50%" }}>
              {Billing_Info != null
                ? misc.currencyOnlyDecimal(Billing_Info[1].Receipts)
                : 0}
            </span>
          </p> */}
              {/* <p
            style={{
              fontSize: "10pt",
              margin: "0",
              // marginBottom: "4pt",
              textAlign: "right",
            }}
          >
            <span>Balance: {" "} </span>
            <span>
              {Billing_Info != null
                ? misc.currencyOnlyDecimal(
                  Billing_Info[1].Balance - Billing_Info[1].Receipts
                )
                : 0}
            </span>
          </p> */}
            </div>

            <div id="table">
              <table className="table-container__print_table">
                <tr>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50 }}>Date</th>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50 }}>Milk</th>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50 }}>Yogurt</th>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50 }}>Other</th>
                  {/* <th style={{ fontSize: "10pt", textAlign: "center", width: 50 }}>Amount</th> */}
                </tr>
                {arrayItems}
                <td style={{ textAlign: "center", fontWeight: "bold", fontSize: "10pt" }}>Total</td>
                <td style={{ textAlign: "right", fontSize: "10pt" }}>
                  {Billing_Info != null
                    ?
                    misc.currencyOnlyDecimal(
                      Billing_Info[1].T_Milk
                    )
                    : null}
                </td>
                <td style={{ textAlign: "right", fontSize: "10pt" }}>
                  {Billing_Info != null
                    ?
                    misc.currencyOnlyDecimal(
                      Billing_Info[1].T_Yog
                    )
                    : null}
                </td>
                <td style={{ textAlign: "right", fontSize: "10pt" }}>
                  {Billing_Info != null
                    ?
                    misc.currencyOnlyDecimal(
                      Billing_Info[1].T_Others
                    )
                    : null}
                </td>
                {/* <td style={{ textAlign: "right", fontSize: "10pt" }}>
                  {Billing_Info != null
                    ? misc.currencyOnlyDecimal(Billing_Info[1].T_Amount)
                    : null}
                </td> */}
              </table>
            </div>
            <div
              id="info"
              style={{ margin: "1pt 0 1pt 0", padding: 0 }}>
              <p style={{ textAlign: "left", fontSize: "10pt", margin: 0, padding: 0 }}>
                Current Amount: {" "}
                {Billing_Info != null
                  ? misc.currencyOnlyDecimal(Billing_Info[1].T_Amount)
                  : 0}
                <br></br>
                Previous Amount: {" "}
                {Billing_Info != null
                  ? misc.currencyOnlyDecimal(
                    (Billing_Info[1].Balance - Billing_Info[1].Receipts)
                  )
                  : 0
                }<br></br>
                Total Amount: {" "}
                {Billing_Info != null
                  ? misc.currencyOnlyDecimal(
                    Billing_Info[1].T_Amount + (Billing_Info[1].Balance - Billing_Info[1].Receipts)
                  )
                  : 0}
                <br></br>
                {/* Receivings: {" "}
                {Billing_Info != null
                  ? misc.currencyOnlyDecimal(
                    Billing_Info[1].Receipts
                  )
                  : 0}
                <br></br>
                Current Amount: {" "}
                {Billing_Info != null
                  ? misc.currencyOnlyDecimal(
                    Billing_Info[1].T_Amount +
                    (Billing_Info[1].Balance - Billing_Info[1].Receipts)
                  )
                  : 0}
                <br></br> */}
              </p>
            </div>
            <div
              style={{
                height: "0px",
                backgroundColor: "black",
                color: "black",
                width: "100%",
                outline: "none",
                border: "1px solid black",
                display: "block",
              }}
            ></div>
            <div style={{
              margin: "1pt 0 1pt 0",
              padding: 0
            }}>
              <p
                style={{
                  fontSize: "11pt",
                  textAlign: "center",
                  margin: 0,
                  padding: 0
                }}
              >
                {sessionStorage.getItem("usercontactperson") ? sessionStorage.getItem("usercontactperson") + " - " + sessionStorage.getItem("usermobile") : "Contact: " + sessionStorage.getItem("usermobile")}
                <br />
                {sessionStorage.getItem("usercontactperson1") != "null" ? sessionStorage.getItem("usercontactperson1") + " - " + sessionStorage.getItem("usermobile1") : ""}
              </p>
            </div>

            <div
              style={{
                height: "0px",
                backgroundColor: "black",
                color: "black",
                width: "100%",
                outline: "none",
                border: "1px solid black",
                display: "block",
              }}
            ></div>
            <div>
              <p
                style={{
                  fontSize: "12pt",
                  margin: "0",
                  margin: "1pt 0 1pt 0",
                  textAlign: "center",
                  fontFamily: 'Jameel Noori Nastaleeq, sans-serif',

                }}
              >
                براہ کرم اپنا بل 10 تاریخ تک لازمی جمع کروائیں۔ شکریہ
              </p>
            </div>
            <div
              style={{
                height: "0px",
                backgroundColor: "black",
                color: "black",
                width: "100%",
                outline: "none",
                border: "1px solid black",
                display: "block",
              }}
            ></div>
            <div
            // style={{ pageBreakAfter: 'always' }}
            >
              <p
                style={{
                  fontSize: "11pt",
                  margin: 0,
                  margin: "1pt 0 1pt 0",
                  textAlign: "center",
                }}
              >
                Software by www.simplesolutions.pk
                Contact: 0337-7467534
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ pageBreakAfter: 'always' }}></div> */}
      {/* Print html end */}

      {/* download bill start  */}

      <div id="testId">
        <div style={styles1.page}>
          <div
            style={{
              width: "80mm",
              borderCollapse: "collapse",
              display: "none",
              margin: 0,
              padding: 0,
              marginLeft: 5,
              marginTop: 5
              // height: "320mm"
            }}
          >
            <div id="test" style={{ display: "none" }}>
              <div>
                <div>

                </div>
              </div>
            </div>
            <p style={{ textAlign: "center", margin: 0, padding: 0, paddingBottom: 5, fontSize: 22, fontWeight: "bold", color: "black", }}>
              {sessionStorage.getItem("usershop")}
            </p>
            <div
              style={{
                marginBottom: "4px",
                height: "0px",
                backgroundColor: "black",
                color: "black",
                width: "100%",
                outline: "none",
                border: "1px solid black",
                display: "block",
              }}
            ></div>
            <div
              style={{ width: "100%", display: "inline-block", float: "center", paddingBottom: "2pt", fontWeight: "bold", color: "black", }}
              id="info"
            >
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  textAlign: "center",
                }}
              >{"Billing Month: "}
                {`${monthNames[Receipt_Date.getMonth()]
                  } ${Receipt_Date.getFullYear()}`}
              </p>
            </div>
            <div
              style={{ width: "50%", display: "inline-block", float: "left", color: "black", fontWeight: 500, color: "#000000" }}
              id="info"
            >
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  textAlign: "left",
                }}
              >
                Cust: {receiptCustomer.Customer_Name}
              </p>
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  textAlign: "left",
                }}
              >
                Mob: {receiptCustomer.Customer_Mobile}
              </p>

            </div>
            <div
              style={{ width: "50%", display: "inline-block", float: "left", paddingBottom: "2pt", color: "black", fontWeight: 500, color: "#000000" }}
              id="info"
            >
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  textAlign: "right",
                }}
              >
                Milk Rate: {receiptCustomer.Customer_Milk_Rate}
              </p>
              <p
                style={{
                  fontSize: "10pt",
                  margin: "0",
                  textAlign: "right",
                }}
              >
                Yogurt Rate: {receiptCustomer.Customer_Yog_Rate}
              </p>
            </div>

            <div>
              <table id="downloadable">
                <tr>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50, fontWeight: 500 }}>Date</th>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50, fontWeight: 500 }}>Milk</th>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50, fontWeight: 500 }}>Yogurt</th>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50, fontWeight: 500 }}>Other</th>
                  <th style={{ fontSize: "10pt", textAlign: "center", width: 50, fontWeight: 500 }}>Amount</th>
                </tr>
                {arrayItems}
                <td style={{ textAlign: "center", fontWeight: "bold", fontSize: "10pt", fontWeight: 500 }}>Total</td>
                <td style={{ textAlign: "right", fontSize: "10pt", fontWeight: 500 }}>
                  {Billing_Info != null
                    ?
                    misc.currencyOnlyDecimal(
                      Billing_Info[1].T_Milk
                    )
                    : null}
                </td>
                <td style={{ textAlign: "right", fontSize: "10pt", fontWeight: 500 }}>
                  {Billing_Info != null
                    ?
                    misc.currencyOnlyDecimal(
                      Billing_Info[1].T_Yog
                    )
                    : null}
                </td>
                <td style={{ textAlign: "right", fontSize: "10pt", fontWeight: 500 }}>
                  {Billing_Info != null
                    ?
                    misc.currencyOnlyDecimal(
                      Billing_Info[1].T_Others
                    )
                    : null}
                </td>
                <td style={{ textAlign: "right", fontSize: "10pt", fontWeight: 500 }}>
                  {Billing_Info != null
                    ? misc.currencyOnlyDecimal(Billing_Info[1].T_Amount)
                    : null}
                </td>
              </table>
            </div>
            <div
              id="info"
              style={{ margin: "1pt 0 1pt 0", padding: 0 }}>
              <p style={{ textAlign: "left", fontSize: "10pt", margin: 0, padding: 0, fontWeight: 500, color: "#000000" }}>
                Total: {" "}
                {Billing_Info != null
                  ? misc.currencyOnlyDecimal(Billing_Info[1].T_Amount)
                  : 0}
                <br></br>
                Previous Balance: {" "}
                {Billing_Info != null
                  ? misc.currencyOnlyDecimal(
                    Billing_Info[1].Balance - Billing_Info[1].Receipts
                  )
                  : 0
                }<br></br>
                Current Balance: {" "}
                {Billing_Info != null
                  ? misc.currencyOnlyDecimal(
                    Billing_Info[1].T_Amount +
                    (Billing_Info[1].Balance - Billing_Info[1].Receipts)
                  )
                  : 0}
                <br></br>
              </p>
            </div>
            <div
              style={{
                height: "0px",
                backgroundColor: "black",
                color: "black",
                width: "100%",
                outline: "none",
                border: "1px solid black",
                display: "block",
              }}
            ></div>
            <div style={{
              margin: "1pt 0 1pt 0",
              padding: 0
            }}>
              <p
                style={{
                  fontSize: "11pt",
                  textAlign: "center",
                  margin: 0,
                  padding: 0,
                  fontWeight: 500,
                  color: "#000000"
                }}
              >
                {sessionStorage.getItem("usercontactperson") ? sessionStorage.getItem("usercontactperson") + " - " + sessionStorage.getItem("usermobile") : "Contact: " + sessionStorage.getItem("usermobile")}
                <br />
                {sessionStorage.getItem("usercontactperson1") != "null" ? sessionStorage.getItem("usercontactperson1") + " - " + sessionStorage.getItem("usermobile1") : ""}
              </p>
            </div>

            <div
              style={{
                height: "0px",
                backgroundColor: "black",
                color: "black",
                width: "100%",
                outline: "none",
                border: "1px solid black",
                display: "block",
              }}
            ></div>
            <div>
              <p
                style={{
                  fontSize: "13pt",
                  margin: "0",
                  margin: "1pt 0 1pt 0",
                  textAlign: "center",
                  fontFamily: 'Jameel Noori Nastaleeq, sans-serif'
                  , fontWeight: 500,
                  color: "#000000"

                }}
              >
                براہ کرم اپنا بل 10 تاریخ تک لازمی جمع کروائیں۔ شکریہ
              </p>
            </div>
            <div
              style={{
                height: "0px",
                backgroundColor: "black",
                color: "black",
                width: "100%",
                outline: "none",
                border: "1px solid black",
                display: "block",
              }}
            ></div>
            <div
            >
              <p
                style={{
                  fontSize: "11pt",
                  margin: 0,
                  margin: "1pt 0 1pt 0",
                  textAlign: "center"
                  , fontWeight: 500,
                  color: "#000000"
                }}
              >
                Software by www.simplesolutions.pk
                Contact: 0337-7467534
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* download bill end  */}
    </div >
  );

};
const mapStateToProps = (state) => ({
  customerLanguage: state.language.language,
  Customers_List: state.CustomerList.Customers_List,
  totalPages: state.CustomerList.totalPages,
  currentPage: state.CustomerList.currentPage,
  totalCustomers: state.CustomerList.totalCustomers,
});
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => {
    dispatch(changeLanguage(language));
  },
  SetCustomers: (data, setLoading) => {
    dispatch(SetCustomers(data, setLoading));
  },
  getAllCustomers: (data, setLoading) => {
    dispatch(GetCustomers(data, setLoading));
  },
  EmptyCustomer: () => {
    dispatch(EmptyCustomer());
  },
  DeleteCustomer: (Customer_ID, totalPages, totalCustomers) => {
    dispatch(DeleteCustomer(Customer_ID, totalPages, totalCustomers));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Customers);