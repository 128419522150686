import React from "react";
import { makeStyles } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import $ from "jquery"
const useStyles = makeStyles(theme => ({
  dateInputWrapper: {
    width: "100%",
    position: 'relative',

  },
  label: {
    position: "absolute",
    left: "1rem",
    top: "-.7rem",
    width: "fit-content",
    textAlign: "center",
    backgroundColor: "#ffffff",
    color: "#7d7b7b",

  },
  input: {
    padding: "0",
    // paddingLeft:".5rem",
    width: "100%",
    height: "4rem ",
    borderRadius: ".4rem",
    "& .MuiOutlinedInput-root": {
      height: "4rem"
    },
    "&  .MuiInputBase-input ": {
      padding: "0",
      paddingLeft: ".5rem",
      // paddingRight:"1rem",
      height: "4rem ",
      [theme.breakpoints.down('lg')]: {
        fontSize: "1.4rem !important",

      },
      [theme.breakpoints.down('md')]: {
        fontSize: "1.4rem !important",

      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.3rem !important",

      },
      //   "& .PrivateNotchedOutline-legendNotched-38":{
      //     maxWidth:"fit-content"
      // }
    },
    "& .MuiFormLabel-root": {

      textAlign: "center",
      backgroundColor: "#ffffff",
      fontSize: "1.3rem ",
      [theme.breakpoints.down('md')]: {
        fontSize: "1rem ",

      },

    },
    "&:focus": {
      border: `2px solid ${theme.palette.primary.main} !important`,
      outline: `2px solid transparent !important`,
      zIndex: 99999
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: "1.6rem !important",

    },
    [theme.breakpoints.down('md')]: {
      fontSize: "1.4rem !important",

    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.3rem !important",

    },
  },

}
))
function BasicDatePicker(props) {
  const classes = useStyles();
  const { name, label, disabled = false, value, error = null, nextid, onChange, tabindex, id, type = 'text', width, variant = "dialog", views = ["year", "month", "date"], format = "dd-MM-yyyy" } = props;
  function handleKeyUp(event) {

    $(`#${nextid}`).focus()
  }
  return (
    // <div className={`${classes.dateInputWrapper} dateInput`}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disableFuture
        disabled={disabled}
        className={`${classes.input} ${localStorage.getItem('dodaily_urdu') == "true" ? "UrduFontInputLabel" : ""}`}
        label={label}
        value={value}
        name={name}
        id={id}
        type={type}
        onChange={onChange}
        variant={variant}
        format={format}
        views={views}
        // disableFuture={false}
        inputVariant="outlined"
      ></DatePicker>
    </MuiPickersUtilsProvider>
    //   </div>
  );
}

export default BasicDatePicker;