import {GETRECEIVEDS,UPDATERECEIVED,DELETERECEIVED,EMPTYRECEIVEDS} from './types'
import axios from 'axios'

export const GetReceiveds =(data,setLoading)=>dispatch=>{
    const headers={
        "x-access-token": sessionStorage.getItem("accountingauthkey")
    }
    axios.post('/api/getreceipts',data,{headers:headers}).then(res=>{ 
    dispatch({type:GETRECEIVEDS,payload:res.data})
    setLoading(false)
  })
}

export const EmptyReceiveds =()=>dispatch=>{
  dispatch({type:EMPTYRECEIVEDS})
}
export const UpdateReceived=(receipt)=>dispatch=>{
  const headers={
      "x-access-token": sessionStorage.getItem("accountingauthkey")
  }
  axios('/api/updatereceipt',receipt,headers).then(res=>dispatch({type:UPDATERECEIVED,payload:res.message}))
}

export const DeleteReceived =(receivedID,totalPages,totalReceiveds)=>(
  {type:DELETERECEIVED,
  payload:{receivedID,totalPages,totalReceiveds}}
)