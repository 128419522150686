import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        // padding: theme.spacing(2),
        // position: 'absolute',
        // top: theme.spacing(3),
        display: 'flex',
        alignSelf: "center",
        justifySelf: "center",
        width: "100%"


    },
    dialogTitle: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        "& .MuiTypography-root": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            fontSize: "1.6rem"
        }
    }
}))

export default function Popup(props) {

    const { title, children, openPopup, setOpenPopup } = props;
    const classes = useStyles();

    return (
        <Dialog open={openPopup} classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex', width: "100%" }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                        color="secondary"
                        onClick={() => { setOpenPopup(false) }}>
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent style={{ paddingLeft: "3rem", paddingRight: "3rem" }} dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}
