export const AUTHENTICATEUSER = 'AUTHENTICATEUSER'
export const LOGUOTUSER = 'LOGUOTUSER'
export const GETUSERS = 'GETUSERS'
export const DELETEUSER = 'DELETEUSER'
export const ADDUSER = 'ADDUSER'
export const UPDATEUSER = 'UPDATEUSER'
export const EMPTYUSERS = 'EMPTYUSERS'
export const DELETEORDER='DELETEORDER'
export const GETORDERS = 'GETORDERS'
export const GETITEMS="GETITEMS"
export const UPDATEITEM="UPDATEITEM"
export const DELETEITEM="DELETEITEM"
export const EMPTYITEMS="EMPTYITEMS"
export const GETCUSTOMERS="GETCUSTOMERS"
export const UPDATECUSTOMER="UPDATECUSTOMER"
export const DELETECUSTOMER="DELETECUSTOMER"
export const EMPTYCUSTOMERS="EMPTYCUSTOMERS"
export const SETCUSTOMERS="SETCUSTOMERS"
export const GETAREAS="GETAREAS"
export const UPDATEAREA="UPDATEAREA"
export const DELETEAREA="DELETEAREA"
export const EMPTYAREAS="EMPTYAREAS"
export const GETUNITS="GETUNITS"
export const UPDATEUNIT="UPDATEUNIT"
export const DELETEUNIT="DELETEUNIT"
export const EMPTYUNITS="EMPTYUNITS"
export const GETRECEIVEDS="GETRECEIVEDS"
export const UPDATERECEIVED="UPDATERECEIVED"
export const DELETERECEIVED="DELETERECEIVED"
export const EMPTYRECEIVEDS="EMPTYRECEIVEDS"
export const GETACCOUNTSBALANCES="GETACCOUNTSBALANCES"
export const UPDATEACCOUNTSBALANCE="UPDATEACCOUNTSBALANCE"
export const DELETEACCOUNTSBALANCE="DELETEACCOUNTSBALANCE"
export const EMPTYACCOUNTSBALANCES="EMPTYACCOUNTSBALANCES"

