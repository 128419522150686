import {deleteItem} from '../utils/ItemUtls'
const initilastate ={
    Items_List:[],
    totalPages:"",
    currentPage:"",
    totalItems:"",
    }

    const ItemsList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "GETITEMS":
                // console.log(Action.payload)
                

                return{
                    ...state,
                    Items_List:[...state.Items_List,...Action.payload.Items],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalItems:Action.payload.totalItems,
                    }
            break;
            case "DELETEITEM":
                return{
                    ...state,
                    Items_List:deleteItem(state.Items_List,Action.payload.itemID),
                    totalItems:Action.payload.totalItems,
                    totalPages:Action.payload.totalPages,
                    }
            break;
            case "EMPTYITEMS":
                return{
                    ...state,
                    Items_List: [],
                    totalPages:"",
                    currentPage:"",
                    totalItems:"",
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default ItemsList
    
    

