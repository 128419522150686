export const addToCart = (items,search_item)=>{
    // console.log(items)
    // console.log(search_item)
    const itemExsists = items.find(item=>item.Item_ID === search_item.Item_ID)
    if (itemExsists) {
        return items.map(item=>item.Item_ID === search_item.Item_ID
            ? {...item,Order_Item_Qty: parseInt(search_item.Order_Item_Qty),Unit_ID:parseInt(search_item.Unit_ID),Unit_Name:search_item.Unit_Name,Order_Item_Price:parseInt(search_item.Order_Item_Price),Order_Item_Amount:parseInt(search_item.Order_Item_Amount)}:item)
    }else{
    return [...items,{...search_item}]
    }
 
}
export const deleteItem =(items,delete_itemID)=>{ return items.filter(
        (item) => items.indexOf(item) !== delete_itemID
        

    )}

export const IncItem = (items,search_item)=>{

            return items.map(item=>item.Item_ID === search_item.Item_ID
                ? {...item,Order_Item_Qty: item.Order_Item_Qty+1}:item)
    
}
export const DecItem = (items,search_item)=>{

    return items.map(item=>item.Item_ID === search_item.Item_ID
        ? {...item,Order_Item_Qty: item.Order_Item_Qty-1}:item)

}


