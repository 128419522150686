import {deleteItem} from '../utils/ItemUtls'
const initilastate ={
    Balance_List:[],
    totalPages:"",
    currentPage:"",
    totalbalances:"",
    }

    const ItemsList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "SETBALANCES":
                // console.log(Action.payload)
                

                return{
                    ...state,
                    Balance_List:[...state.Balance_List,...Action.payload.balances],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalbalances:Action.payload.totalbalances,
                    }
            break;

            case "EMPTYBALANCELIST":
                return{
                    ...state,
                    Balance_List: [],
                    totalPages:"",
                    currentPage:"",
                    totalbalances:"",
                    }
            break;
            default:
                return state
                break;
        }
    
    }
    
    export default ItemsList
    
    

