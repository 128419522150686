import React from 'react'
import { makeStyles } from '@material-ui/core';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import $ from 'jquery'
const useStyles = makeStyles(theme => ({
    Input: {
        "& .MuiInputBase-root , .MuiInputBase-input":{
            padding: "0",
            paddingRight:"1rem",
            height:"4rem ",
            [theme.breakpoints.down('lg')]: {
                fontSize:"1.6rem !important"
               },
            [theme.breakpoints.down('md')]: {
               fontSize:"1.4rem !important"
              },
              [theme.breakpoints.down('sm')]: {
                fontSize:"1.3rem !important" , 
                paddingRight:".2rem",
              },
            //   "& .PrivateNotchedOutline-legendNotched-38":{
            //     maxWidth:"fit-content"
            // }
        },
        "& .MuiInputLabel-formControl":{
            backgroundColor: "white",
            width:"fit-content",
            fontSize:"1.3rem " ,
            [theme.breakpoints.down('md')]: {
                fontSize:"1rem " ,
            
              },
            //   [theme.breakpoints.down('sm')]: {
            //     fontSize:"1.3rem !important" ,
            //     paddingBottom:".2rem", 
            //   }
        },
        
       
    },
    "qty": {
        "& .MuiInputBase-root  .MuiInputBase-input":{
            textAlign:"right !important"
        },
          
    },
    "amount": {
        "& .MuiInputBase-root  .MuiInputBase-input":{
            textAlign:"right !important"
        },
          
    }
}))
export default function Input(props) {
    const classes = useStyles();
    const { name, label, value,error=null, onChange,nextid,saveKeyId,listItemsClassName,placeholder,type='number',currencySymbol,varient,width,id,tabindex ,...other } = props;
    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            $(`#${nextid}`).focus()
        }
        else if (event.keyCode === 13 && event.keyCode === 18) {
            $(`#${saveKeyId}`).focus()
        }
      }
    return (
        <CurrencyTextField
        currencySymbol={currencySymbol}
        // placeholder={placeholder}
        // decimalCharacter="."
		// digitGroupSeparator=","
        // decimalPlacesShownOnBlur={2}
        // decimalPlacesShownOnFocus={0}
        // decimalPlaces={2}
        className={[classes.Input, classes[varient] , localStorage.getItem('dodaily_urdu') == "true"?"UrduFontInputLabel":""]}
        id={id}
        type="tel"
        onKeyDown={handleKeyUp}
        nextid={nextid}
        variant="outlined"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...other}
            {...(error && {error:true,helperText:error})}
        />
    )
}