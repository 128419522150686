// import {deleteOrder} from '../utils/orderListUtls'
const initilastate ={
    Daliy_Order_List:[],
    totalPages:"",
    currentPage:"",
    totalOrders:"",
    }

    const DailyOrderList =(state = initilastate,Action)=>{
        switch (Action.type) {
            case "SETDAILYORDERS":
                  
                return{
                    ...state,
                    Daliy_Order_List: [...state.Daliy_Order_List,...Action.payload.DailyOrders],
                    totalPages:Action.payload.totalPages,
                    currentPage:Action.payload.currentPage,
                    totalOrders:Action.payload.totalOrders,
                    }
            break;
            case 'EMPTYDAILYORDERLIST':
                    return {
                        ...state,
                        Daliy_Order_List: [],
                        totalPages:"",
                        currentPage:"",
                        totalOrders:"",
                    }
                    break
            default:
                return state
                break;
        }
    
    }
    
    export default DailyOrderList
    
    

